// Lib
import { useEffect } from 'react';

// Types
import { Editor } from '@tiptap/react';
import { TiptapContent, TiptapEditorContent } from '../tiptapTypes';
import { ImMap } from '../../utils/immutableHelper';

/**
 * When changes to the persisted content occur, this will reflect those
 * changes in the editor, provided the editor isn't currently being edited.
 *
 * When it's being edited, the internal state of the editor will be used instead.
 */
const useTiptapEditorPersistedContentSync = (
    editor: Editor,
    persistedContent: ImMap<TiptapContent> | TiptapContent | string | null | undefined,
    persistedContentObj: TiptapEditorContent,
    isEditing: boolean,
) => {
    useEffect(() => {
        // Don't update the editor if it's currently being edited.
        if (isEditing) return;

        editor.commands.setContent(persistedContentObj);

        // Only use the persistedContent as a dependency, because the other
        //  properties will only be checked when the persistedContent changes.
    }, [persistedContent]);
};

export default useTiptapEditorPersistedContentSync;
