// Components
import registerSketch from '../../../../common/drawings/sketches/index';
import presentationalComponent from './Sketch';
import containerComponent from './SketchContainer';
import modalComponent from './modal/LoadableSketchModal';

import platformSingleton from '../../../platform/platformSingleton';
import { isPlatformLegacyMobileApp } from '../../../platform/utils/platformDetailsUtils';

// Constants
import { TOOLBAR_TOOLS, TOOL_SETS } from '../../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerSketch({
        presentationalComponent,
        containerComponent,
        modalComponent,
        tools: {
            selected: [
                { ...TOOLBAR_TOOLS.COLOR, name: 'Background' },
                TOOLBAR_TOOLS.EDIT_DRAWING,
                TOOLBAR_TOOLS.CAPTION,
                TOOLBAR_TOOLS.REACTION,
                ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT,
            ],
            editing_CAPTION: isPlatformLegacyMobileApp(platformSingleton) ? [] : TOOL_SETS.TEXT_EDITING_CAPTION,
        },
    });
