import { Extension } from '@tiptap/core';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        debouncedSave: {
            cancelDebouncedUpdate: () => ReturnType;
            flushDebouncedUpdate: () => ReturnType;
        };
    }
}

/**
 * This extension debounces saves to the editor content to avoid excessive
 * Redux store updates.
 */
export const DebouncedSaveCommands = Extension.create({
    name: 'debouncedSave',

    addCommands() {
        return {
            cancelDebouncedUpdate:
                () =>
                ({ editor }) => {
                    // @ts-ignore Custom Milanote event
                    editor.emit('cancelDebouncedUpdate');
                    return true;
                },
            flushDebouncedUpdate:
                () =>
                ({ editor }) => {
                    // @ts-ignore Custom Milanote event
                    editor.emit('flushDebouncedUpdate');
                    return true;
                },
        };
    },
});
