import registerWorkspace from '../../../common/workspace/index';
import presentationalComponent from '../board/Board';
import containerComponent from '../board/BoardContainer';

export default () =>
    registerWorkspace({
        containerComponent,
        presentationalComponent,
        tools: {
            selected: [],
        },
    });
