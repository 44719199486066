// Lib
import DefaultHighlight, { inputRegex, pasteRegex } from '@tiptap/extension-highlight';
import { markInputRule, markPasteRule } from '@tiptap/core';

export const TextHighlight = DefaultHighlight.extend({
    // FIXME-TIPTAP - WEB-11472 - Enabling this causes multiple text colour marks to
    //  get applied to the same text, rather than toggling them
    // excludes: 'textStyle',

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            'Mod-j': () => this.editor.commands.toggleHighlight({ color: 'green' }),
            'Mod-Shift-h': () => this.editor.commands.toggleHighlight({ color: 'green' }),
            /* eslint-enable @typescript-eslint/naming-convention */
        };
    },

    addInputRules() {
        return [
            markInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes: { color: 'green' },
            }),
        ];
    },

    addPasteRules() {
        return [
            markPasteRule({
                find: pasteRegex,
                type: this.type,
                getAttributes: { color: 'green' },
            }),
        ];
    },
}).configure({
    multicolor: true,
});
