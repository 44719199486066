// Lib
import * as Immutable from 'immutable';
import { isEmpty } from 'lodash/fp';

// Utils
import { isBlocked, isOwner } from '../../../../../../../common/permissions/permissionUtil';
import {
    getDisplayName,
    getEmail,
    getFamilyName,
    getGivenName,
    isRealUserId,
} from '../../../../../../../common/users/userHelper';
import { isOnline } from '../../../../../../user/userUtil';
import { getUserId } from '../../../../../../../common/users/utils/userPropertyUtils';
import { getMilanoteUserId } from '../../../../../../user/contacts/userContactsUtils';
import { getAsyncResourceEntityState } from '../../../../../../utils/services/http/asyncResource/asyncResourceSelector';
import {
    isAsyncEntityFetched,
    isAsyncEntityFetching,
} from '../../../../../../utils/services/http/asyncResource/asyncResourceUtils';

// Selectors
import { createDeepSelector, createShallowSelector } from '../../../../../../utils/milanoteReselect/milanoteReselect';
import {
    currentBoardHasPublicEditPermissionsSelector,
    currentBoardPermissionsSelector,
    currentBoardSharedUsersSelector,
} from '../../../../../../utils/permissions/elementPermissionsSelector';
import { getCurrentBoardIdFromState } from '../../../../../../reducers/currentBoardId/currentBoardIdSelector';
import { getCurrentUserId } from '../../../../../../user/currentUserSelector';
import { usersCurrentlyViewingSelector } from '../../../../../sharing/boardSharingUtilSelector';
import { getCurrentBoardAncestorsAndSelfSelector } from '../../../../../../element/selectors/currentBoardSelector';
import { getUsers, getUsersByEmail } from '../../../../../../user/usersSelector';
import {
    getUserContactsByEmail,
    getUserContactsByMilanoteId,
} from '../../../../../../user/contacts/userContactsSelector';

// Constants
import { PERMISSION_VALUES } from '../../../../../../../common/permissions/permissionConstants';
import { ResourceTypes } from '../../../../../../utils/services/http/asyncResource/asyncResourceConstants';

/**
 * Popup reducer state selectors.
 */
export const getBoardEditorsPopupState = (state) => state.getIn(['local', 'boardEditors']);
export const getUserIdsUpdating = (state) => getBoardEditorsPopupState(state).get('permissionsUpdatingUsers');
export const getCurrentBoardEditorTimestamps = (state) =>
    getBoardEditorsPopupState(state).getIn(['editorTimestamps', getCurrentBoardIdFromState(state)]);

/**
 * Board editor resource state selectors
 */
const getCurrentBoardBoardEditorsResourceState = (state) =>
    getAsyncResourceEntityState(state, ResourceTypes.boardEditors, getCurrentBoardIdFromState(state));
export const getBoardEditorTimesFetching = (state) =>
    isAsyncEntityFetching(getCurrentBoardBoardEditorsResourceState(state));
export const getBoardEditorTimesFetched = (state) =>
    isAsyncEntityFetched(getCurrentBoardBoardEditorsResourceState(state));

// DATA SELECTORS

/**
 * Sorts user access entries so owners are at the top,
 * the current user is at the bottom (if not owner),
 * and the rest is sorted via email address.
 */
const editorSortingFn = (userAccessA, userAccessB) => {
    // Send blocked users to the bottom
    if (isBlocked(userAccessA.permission)) return 1;
    if (isBlocked(userAccessB.permission)) return -1;

    // Owner to the top
    if (isOwner(userAccessA.permission)) return -1;
    if (isOwner(userAccessB.permission)) return 1;

    // Current user to bottom
    if (userAccessA.isCurrentUser) return 1;
    if (userAccessB.isCurrentUser) return -1;

    // Sorted finally by name
    return (userAccessA.email && userAccessA.email.localeCompare(userAccessB.email)) || 0;
};

const createEmptyUser = (email) => Immutable.Map({ email });

const EMPTY_ARRAY = [];
const currentBoardEditorTimestampsMapUserIds = createShallowSelector(
    getCurrentBoardEditorTimestamps,
    (editorTimestamps) => editorTimestamps?.keySeq().toArray() || EMPTY_ARRAY,
);

/**
 * Gets the users to show in the editors list.
 */
export const currentBoardEditorsSelector = createDeepSelector(
    currentBoardHasPublicEditPermissionsSelector,
    currentBoardPermissionsSelector,
    getCurrentBoardIdFromState,
    currentBoardSharedUsersSelector,
    getCurrentUserId,
    usersCurrentlyViewingSelector,
    getCurrentBoardAncestorsAndSelfSelector,
    getUserIdsUpdating,
    getUsers,
    getUsersByEmail,
    getUserContactsByEmail,
    getUserContactsByMilanoteId,
    currentBoardEditorTimestampsMapUserIds,
    (
        hasPublicEditPermissions,
        boardPermissions,
        currentBoardId,
        sharedUsers,
        currentUserId,
        usersCurrentlyViewing,
        // TODO - Potentially remove this property, it's only used to add the "sharedBoard" property
        //  to the user data. Though it's not causing too many recalculations as it's only when ancestors change.
        elements,
        updatingUserIds,
        users,
        usersByEmail,
        contactsByEmail,
        contactsByUserId,
        editorTimestampMapUserIds,
    ) => {
        // If the public edit permissions aren't enabled then we only use the users shared on the ACLs
        const existingEditorIds =
            (!hasPublicEditPermissions && sharedUsers.map(getUserId)) ||
            // Otherwise use all the editors on the board
            editorTimestampMapUserIds ||
            [];

        const existingEditorIdsMap = {};
        const existingEmailsMap = {};

        const result = existingEditorIds.reduce((userArrayAcc, userId) => {
            const user = users.get(userId);

            if (!userId) return userArrayAcc;

            const userBoardPermissionSummary = boardPermissions[userId];

            const isSpecificallyShared = !!userBoardPermissionSummary;
            const permission = isSpecificallyShared
                ? userBoardPermissionSummary.permission
                : PERMISSION_VALUES.FULL_ACCESS;

            if (isBlocked(permission)) return userArrayAcc;

            const sharedBoardId = isSpecificallyShared ? userBoardPermissionSummary.elementId : currentBoardId;

            const updatingState = updatingUserIds.get(userId);

            const email = getEmail(user);
            existingEditorIdsMap[userId] = true;
            existingEmailsMap[email] = true;

            const name = getDisplayName(user);

            let userObj = user;

            // If there's no name, see if we have an entry in the google contacts that we can use
            if (isEmpty(name)) {
                const contact = contactsByEmail[email];

                if (contact) {
                    const givenName = getGivenName(contact);
                    const familyName = getFamilyName(contact);
                    const nameObj = Immutable.Map({
                        givenName,
                        familyName,
                        displayName: `${givenName} ${familyName}`,
                    });

                    userObj = user.set('name', nameObj);
                }
            }

            userArrayAcc.push({
                entryId: userId,
                userId,
                email,
                user: userObj,
                name: getDisplayName(userObj),
                isCurrentUser: userId === currentUserId,
                permission,
                currentlyViewing: usersCurrentlyViewing.has(userId),
                online: isOnline(userObj),
                updatingState,
                currentlyShared: true,
                isSpecificallyShared,
                sharedBoardId,
                sharedBoard: elements.get(sharedBoardId),
                editable: sharedBoardId === currentBoardId,
            });

            return userArrayAcc;
        }, []);

        updatingUserIds
            .keySeq()
            .toArray()
            .forEach((updatingUserId) => {
                // Already handled these editors in the above loop
                if (existingEditorIdsMap[updatingUserId]) return;

                // It might be a user ID or an email
                if (isRealUserId(updatingUserId)) {
                    let user = users.get(updatingUserId);

                    if (!user) {
                        user = contactsByUserId[updatingUserId];
                        if (!user) return;
                    }

                    return result.push({
                        entryId: updatingUserId,
                        userId: updatingUserId,
                        email: getEmail(user),
                        user,
                        name: getDisplayName(user),
                        isCurrentUser: false,
                        permission: PERMISSION_VALUES.FULL_ACCESS,
                        currentlyViewing: false,
                        online: false,
                        updatingState: updatingUserIds.get(updatingUserId),
                        currentlyShared: false,
                        isSpecificallyShared: true,
                        sharedBoardId: currentBoardId,
                        sharedBoard: elements.get(currentBoardId),
                        editable: true,
                    });
                }

                const email = updatingUserId;

                // If we've already added this user, ignore the entry
                if (existingEmailsMap[email]) return;

                let user = usersByEmail[email];
                const hasMilanoteUserObject = !!user;

                user = hasMilanoteUserObject ? user : contactsByEmail[email] || createEmptyUser(email);

                const userId = hasMilanoteUserObject ? getUserId(user) : getMilanoteUserId(user) || null;

                return result.push({
                    entryId: email,
                    userId,
                    email,
                    user,
                    name: getDisplayName(user),
                    isCurrentUser: false,
                    permission: PERMISSION_VALUES.FULL_ACCESS,
                    currentlyViewing: false,
                    online: false,
                    updatingState: updatingUserIds.get(updatingUserId),
                    currentlyShared: false,
                    isSpecificallyShared: true,
                    sharedBoardId: currentBoardId,
                    sharedBoard: elements.get(currentBoardId),
                    editable: true,
                });
            });

        return result.sort(editorSortingFn);
    },
);

export const currentBoardEditorAvatarsSelector = createShallowSelector(currentBoardEditorsSelector, (editors) =>
    editors.filter((editor) => !isBlocked(editor.permission)).map((editor) => editor?.user),
);
