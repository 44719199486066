import { MapBlockToNodeFn } from './draftToTiptapConverterTypes';

/**
 * This is a close copy to the `mapListToNode` function in the `draft-js-to-tiptap` package..
 * However it deals with a different type of list, a checklist.
 */
export const convertChecklistBlockToTiptapNode: MapBlockToNodeFn = ({
    doc,
    getCurrentBlock,
    entityMap,
    peek,
    next,
    converter,
}) => {
    const outerListNode = converter.createNode('taskList');

    // NOTE: Just writing it this way to more closely match the original function
    // eslint-disable-next-line no-constant-condition
    while (true) {
        let listNode = outerListNode;

        const currentBlock = getCurrentBlock();
        let depth = 0;
        while (depth < currentBlock.depth) {
            if (!listNode.content?.length) {
                listNode.content = [];
            }
            // There are list items in-between, find the most recent one
            let mostRecentListItem = listNode.content[listNode.content.length - 1];
            if (!mostRecentListItem) {
                mostRecentListItem = converter.createNode('taskItem', {
                    attrs: {
                        checked: false,
                    },
                });
                converter.addChild(listNode, mostRecentListItem);
            }

            let nextMostRecentList = mostRecentListItem.content?.[mostRecentListItem.content.length - 1];

            if (nextMostRecentList?.type === 'checklist') {
                // We found a list, move to the next one
                listNode = nextMostRecentList;

                depth++;
            } else {
                // We didn't find a list, in the last position, create a new one
                nextMostRecentList = converter.createNode('taskList');

                converter.addChild(mostRecentListItem, nextMostRecentList);

                listNode = nextMostRecentList;
                // Tiptap doesn't support nesting lists, so we break here
                break;
            }
        }

        // We found the correct list, add the new list item
        converter.addChild(
            listNode,
            converter.createNode('taskItem', {
                attrs: {
                    checked: !!currentBlock.data?.checked,
                },
                content: [
                    converter.createNode('paragraph', {
                        content: converter.splitTextByEntityRangesAndInlineStyleRanges({
                            doc,
                            block: getCurrentBlock(),
                            entityMap,
                        }),
                    }),
                ],
            }),
        );

        const nextBlock = peek();
        if (!(nextBlock && nextBlock.type === 'checklist')) {
            break;
        }

        if (nextBlock && nextBlock.type !== currentBlock.type) {
            // We are switching between ordered and unordered lists
            break;
        }
        next();
    }

    return outerListNode;
};

export default convertChecklistBlockToTiptapNode;
