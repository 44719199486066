import React from 'react';
import DefaultMention, { MentionOptions } from '@tiptap/extension-mention';
import { mergeAttributes, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';

import { Node as ProseMirrorNode } from 'prosemirror-model';
import { NodeViewProps } from '@tiptap/core';

// TODO-TIPTAP-MENTIONS: Should this match draftjs exactly?
// Roughly matches from draftjs mention data, but:
//    - givenName, familyName, displayName are not nested
//    - name has been renamed to label to avoid conflicting with the other name fields
export type MentionAttrs = {
    id: string;
    label: string;

    givenName: string;
    familyName: string;
    displayName: string;
    email: string;

    disabled: boolean;
    sharingCurrentBoard: boolean;
    shareCount: number;
};

// This should be kept 1:1 with the fields of MentionAttrs
const ATTRIBUTES = {
    id: { default: '' },
    label: { default: '' },

    givenName: { default: '' },
    familyName: { default: '' },
    displayName: { default: '' },
    email: { default: '' },

    disabled: { default: false },
    sharingCurrentBoard: { default: false },
    shareCount: { default: 0 },
};

const getNameFromNode = (node: ProseMirrorNode): string => node.attrs?.label || node.attrs?.id || 'Unknown';

export const MentionDisplay = ({ node }: NodeViewProps) => {
    const name = getNameFromNode(node);

    return (
        <NodeViewWrapper as="span" className="MentionEntity">
            <span>@</span>
            <span>{name}</span>
        </NodeViewWrapper>
    );
};

export const Mention = DefaultMention.extend<MentionOptions<any, MentionAttrs>>({
    addNodeView() {
        return ReactNodeViewRenderer(MentionDisplay);
    },
    addAttributes() {
        return ATTRIBUTES;
    },
}).configure({
    // Inside the app, the MentionDisplay component will be used, but we
    // still need these functions for copypasting, exports, etc
    renderHTML({ node, options }) {
        const name = getNameFromNode(node);
        return ['span', mergeAttributes({ class: 'MentionEntity' }, options.HTMLAttributes), '@' + name];
    },
    renderText({ node }) {
        const name = getNameFromNode(node);
        return `@${name}`;
    },
});
