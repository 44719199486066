import { isObject, keys, values } from 'lodash';

import elementExportStringFallback from './elementExportStringFallback';

import { ACCESS_BITS } from '../permissions/permissionConstants';

/* FIXME This is using a singleton object, this makes it difficult to test and is the reason for the 'clearRegistry'
         method below.  Find out a way to avoid this sort of object. */
let elementTypeObjects = {};

/**
 * Retrieves the element type from the elementTypes map, or throws an exception if it doesn't exist.
 *
 * @param elementType
 * @returns {*}
 */
const getElementTypeObject = (elementType) => {
    const elementTypeObj = elementTypeObjects[elementType];
    if (!elementTypeObj) console.error(`Unknown element type: ${elementType}`);
    return elementTypeObj;
};

export const isElementTypeValid = (elementType) => isObject(elementTypeObjects[elementType]);

export const registerElementType = ({
    elementType,
    displayName,
    displayNamePlural,
    objectConstructorFn,
    containerComponent,
    presentationalComponent,
    modalComponent,
    toStringFn,
    tools,
    features,
    editPermission = ACCESS_BITS.WRITE,
    savePermission = ACCESS_BITS.SAVE,
    showTool = true,
    ...otherProps
}) => {
    const elementTypeObj = elementTypeObjects[elementType] || {};

    elementTypeObjects[elementType] = {
        ...elementTypeObj,
        elementType,
        displayName,
        displayNamePlural,
        objectConstructorFn,
        containerComponent,
        presentationalComponent,
        modalComponent,
        toStringFn,
        tools,
        features,
        showTool,
        editPermission,
        savePermission,
        ...otherProps,
    };
};

export const createElementObject = (action) => {
    const elementType = getElementTypeObject(action.elementType);
    return elementType && elementType.objectConstructorFn(action);
};

/**
 * Retrieves the tools that are available for the element type while in selected state
 */
export const getElementSelectedTools = (elementType) => getElementTypeObject(elementType)?.tools?.selected || [];

/**
 * Retrieves the tools that are available for the element type while in editing state
 */
export const getElementEditingTools = (elementType, editorKey = '') => {
    const elementTypeObj = getElementTypeObject(elementType);
    if (!elementTypeObj) return [];

    return elementTypeObj.tools?.[`editing_${editorKey}`] || elementTypeObj.tools?.editing || [];
};

/**
 * Retrieves the tools that are available for the element type while editing a text range
 */
export const getElementEditingRangeTools = (elementType, editorKey = '') => {
    const elementTypeObj = getElementTypeObject(elementType);
    if (!elementTypeObj) return [];

    return elementTypeObj.tools?.[`editingRange_${editorKey}`] || elementTypeObj.tools?.editingRange || [];
};

export const getElementFeatures = (elementType) =>
    getElementTypeObject(elementType) && getElementTypeObject(elementType).features;

export const getElementContainerComponent = (elementType) =>
    getElementTypeObject(elementType) && getElementTypeObject(elementType).containerComponent;

export const getElementPresentationalComponent = (elementType) =>
    getElementTypeObject(elementType) && getElementTypeObject(elementType).presentationalComponent;

export const getElementModalComponent = (elementType) =>
    getElementTypeObject(elementType) && getElementTypeObject(elementType).modalComponent;

export const getElementTooltip = (elementType) =>
    (getElementTypeObject(elementType) && getElementTypeObject(elementType).tooltip) || '';

export const getElementToStringFn = (elementType) =>
    (getElementTypeObject(elementType) && getElementTypeObject(elementType).toStringFn) || elementExportStringFallback;

export const getElementEditPermission = (elementType) =>
    getElementTypeObject(elementType) && getElementTypeObject(elementType).editPermission;

export const getElementSavePermission = (elementType) =>
    getElementTypeObject(elementType) && getElementTypeObject(elementType).savePermission;

export const getElementTypes = () => keys(elementTypeObjects);
export const getElementTypeArray = () => values(elementTypeObjects);
export const getElementTypeObjects = () => elementTypeObjects;

export const clearRegistry = () => {
    elementTypeObjects = {};
};

export const getElementDisplayName = (elementType) =>
    getElementTypeObject(elementType) && getElementTypeObject(elementType).displayName;

export const getElementDisplayNamePlural = (elementType) =>
    getElementTypeObject(elementType) && getElementTypeObject(elementType).displayNamePlural;
