/**
 * These functions are copied from the prosemirror-schema-list package as they weren't exposed.
 * There are minor modifications related to Milanote's code-style, as well as minor marked modifications.
 */
import { NodeType, NodeRange, Slice, Fragment, Attrs } from '@tiptap/pm/model';
import { EditorState, Transaction } from '@tiptap/pm/state';
import { canJoin, canSplit, liftTarget, ReplaceAroundStep } from '@tiptap/pm/transform';

/**
 * Lifts the range to the surrounding list.
 */
export const liftToOuterList = (
    state: EditorState,
    dispatch: (tr: Transaction) => void,
    itemType: NodeType,
    range: NodeRange,
) => {
    const tr = state.tr;
    const end = range.end;
    const endOfList = range.$to.end(range.depth);

    if (end < endOfList) {
        // There are siblings after the lifted items, which must become
        // children of the last item
        tr.step(
            new ReplaceAroundStep(
                end - 1,
                endOfList,
                end,
                endOfList,
                new Slice(Fragment.from(itemType.create(null, range.parent.copy())), 1, 0),
                1,
                true,
            ),
        );
        range = new NodeRange(tr.doc.resolve(range.$from.pos), tr.doc.resolve(endOfList), range.depth);
    }

    const target = liftTarget(range);

    if (target == null) return false;

    tr.lift(range, target);
    const after = tr.mapping.map(end, -1) - 1;

    if (canJoin(tr.doc, after)) tr.join(after);

    dispatch(tr.scrollIntoView());

    return true;
};

/**
 * Lifts the range out of the current list.
 */
export const liftOutOfList = (state: EditorState, dispatch: (tr: Transaction) => void, range: NodeRange) => {
    const tr = state.tr;
    const list = range.parent;

    // Merge the list items into a single big item
    for (let pos = range.end, i = range.endIndex - 1, e = range.startIndex; i > e; i--) {
        pos -= list.child(i).nodeSize;
        tr.delete(pos - 1, pos + 1);
    }

    const $start = tr.doc.resolve(range.start);
    const item = $start.nodeAfter!;

    // Milanote modification
    //  Not 100% sure why this is necessary, but if this isn't commented out, the list won't
    //  correctly be lifted when selecting across multiple list items & outside of lists
    // if (tr.mapping.map(range.end) != range.start + $start.nodeAfter!.nodeSize) return false;

    const atStart = range.startIndex == 0;
    const atEnd = range.endIndex == list.childCount;

    const parent = $start.node(-1);
    const indexBefore = $start.index(-1);

    if (
        !parent.canReplace(
            indexBefore + (atStart ? 0 : 1),
            indexBefore + 1,
            item.content.append(atEnd ? Fragment.empty : Fragment.from(list)),
        )
    )
        return false;

    const start = $start.pos;
    const end = start + item.nodeSize;

    // Strip off the surrounding list. At the sides where we're not at
    // the end of the list, the existing list is closed. At sides where
    // this is the end, it is overwritten to its end.
    tr.step(
        new ReplaceAroundStep(
            start - (atStart ? 1 : 0),
            end + (atEnd ? 1 : 0),
            start + 1,
            end - 1,
            new Slice(
                (atStart ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))).append(
                    atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty)),
                ),
                atStart ? 0 : 1,
                atEnd ? 0 : 1,
            ),
            atStart ? 0 : 1,
        ),
    );

    dispatch(tr.scrollIntoView());

    return true;
};

/**
 * Wraps the specified range in a list.
 */
export const doWrapInList = (
    tr: Transaction,
    range: NodeRange,
    wrappers: { type: NodeType; attrs?: Attrs | null }[],
    joinBefore: boolean,
    listType: NodeType,
) => {
    let content = Fragment.empty;
    for (let i = wrappers.length - 1; i >= 0; i--)
        content = Fragment.from(wrappers[i].type.create(wrappers[i].attrs, content));

    tr.step(
        new ReplaceAroundStep(
            range.start - (joinBefore ? 2 : 0),
            range.end,
            range.start,
            range.end,
            new Slice(content, 0, 0),
            wrappers.length,
            true,
        ),
    );

    let found = 0;
    for (let i = 0; i < wrappers.length; i++) if (wrappers[i].type == listType) found = i + 1;
    const splitDepth = wrappers.length - found;

    let splitPos = range.start + wrappers.length - (joinBefore ? 2 : 0);
    const parent = range.parent;
    for (let i = range.startIndex, e = range.endIndex, first = true; i < e; i++, first = false) {
        if (!first && canSplit(tr.doc, splitPos, splitDepth)) {
            tr.split(splitPos, splitDepth);
            splitPos += 2 * splitDepth;
        }
        splitPos += parent.child(i).nodeSize;
    }
    return tr;
};
