// Components
import registerAnnotation from '../../../../common/drawings/annotations/index';
import presentationalComponent from './Annotation';
import containerComponent from './AnnotationContainer';

// Constants
import { TOOLBAR_TOOLS, TOOL_SETS } from '../../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerAnnotation({
        presentationalComponent,
        containerComponent,
        tools: {
            selected: [TOOLBAR_TOOLS.EDIT_DRAWING, ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT],
        },
    });
