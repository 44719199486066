// Lib
import DefaultTextStyle from '@tiptap/extension-text-style';

/**
 * Ensures that highlights won't be applied to coloured text.
 */
export const TextStyle = DefaultTextStyle.extend({
    // FIXME-TIPTAP - WEB-11472 - Enabling this causes multiple text colour marks to
    //  get applied to the same text, rather than toggling them
    // excludes: 'highlight',
});
