import { NodeType } from '@tiptap/pm/model';
import { EditorState } from '@tiptap/pm/state';

/**
 * Gets the NodeType based on a node type string and the editor's schema.
 */
export const asNodeType = (typeOrName: string | NodeType, state: EditorState): NodeType | null => {
    const nodeType = typeof typeOrName === 'string' ? state.schema.nodes[typeOrName] : typeOrName;
    return nodeType || null;
};
