// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import TextStyleEntry from '../../editorTools/textStylePopup/TextStyleEntry';

const DraftTextStyleEntry = (props) => {
    const { blockType, entryBlockType, name, shortcut, onTextStyleClick, otherBlockTypes } = props;

    const active = blockType === entryBlockType || (otherBlockTypes && otherBlockTypes.includes(blockType));

    const onTextStyleSelect = () => onTextStyleClick(entryBlockType);

    return (
        <TextStyleEntry
            blockClassname={entryBlockType}
            active={active}
            onTextStyleSelect={onTextStyleSelect}
            shortcut={shortcut}
        >
            {name}
        </TextStyleEntry>
    );
};

DraftTextStyleEntry.propTypes = {
    blockType: PropTypes.string,
    entryBlockType: PropTypes.string,
    name: PropTypes.string,
    shortcut: PropTypes.array,
    onTextStyleClick: PropTypes.func,
    otherBlockTypes: PropTypes.array,
};
export default DraftTextStyleEntry;
