// Lib
import { ContentState, convertToRaw, RawDraftContentState } from 'draft-js';

// Utils
import globalLogger from '../../logger';
import { getNewTransactionId } from '../../utils/undoRedo/undoRedoTransactionManager';
import { getListChildNewLocation } from '../../../common/elements/utils/elementLocationUtils';
import { getCurrentlyEditingId } from '../selection/currentlyEditingSelector';

// Actions
import { createAndEditElement } from '../actions/elementActions';
import { setLinkElementUrl } from '../link/linkActions';
import { setElementTypeAndUpdateElement } from '../../../common/elements/elementActions';
import { setSelectedElements } from '../selection/selectionActions';
import { setElementLocalData } from '../local/elementLocalDataActions';

// Constants
import { ElementType } from '../../../common/elements/elementTypes';
import {
    COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
    COLOR_SWATCH_DEFAULT_WIDTH_GRID,
} from '../../../common/colorSwatches/colorSwatchesConstants';
import { GRID } from '../../utils/grid/gridConstants';
import { TiptapContentNode } from '../../../common/tiptap/tiptapTypes';
import { ColorObject } from '../../../common/colors/colorObjectUtil';
import { ThunkAction } from 'redux-thunk';
import { UnknownAction } from 'redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UpdateActionsResponse = (UnknownAction | ThunkAction<UnknownAction, any, any, any> | Promise<any>)[];

const logger = globalLogger.createChannel('universalCardActions');

type LinkConfig = {
    id: string;
    elementType: ElementType;
    data: {
        url: string;
    };
};

const getUpdateTypeToLinkActions = ({ id, elementType, data }: LinkConfig): UpdateActionsResponse => {
    const transactionId = getNewTransactionId();

    const { url } = data;

    const contentState = ContentState.createFromText(url);
    const rawState = convertToRaw(contentState);

    const undoChanges = { textContent: rawState, caption: null, showCaption: false, ignoreAutoType: true };

    return [
        setElementTypeAndUpdateElement({ id, elementType, undoChanges, transactionId, changes: undefined }),
        setElementLocalData({ id, data: { url } }),
        // @ts-ignore Types don't correctly include promise thunk functions
        setLinkElementUrl({ id, url, transactionId }),
    ];
};

type TaskConfig = {
    id: string;
    elementType: ElementType;
    data: {
        content: {
            isComplete: boolean;
        };
    };
};

const getUpdateTypeToTaskActions = ({ id, data }: TaskConfig): UpdateActionsResponse => {
    const transactionId = getNewTransactionId();

    return [
        setElementTypeAndUpdateElement({
            id,
            elementType: ElementType.TASK_LIST_TYPE,
            transactionId,
            changes: undefined,
            undoChanges: undefined,
        }),
        createAndEditElement({
            elementType: ElementType.TASK_TYPE,
            location: getListChildNewLocation({ listId: id }),
            content: data.content,
            transactionId,
            select: false,
        }),
        setSelectedElements({ ids: [id], rangeAnchors: null, transactionId }),
    ];
};

type SwatchConfig = {
    id: string;
    elementType: ElementType;
    data: {
        color: ColorObject;
        // TODO-TIPTAP don't allow the string
        caption: TiptapContentNode | RawDraftContentState | string;
        width?: number;
        media?: {
            height: number;
            width: number;
        };
        showColorValue?: boolean;
        showCaption?: boolean;
    };
};

const getUpdateTypeToSwatchActions = ({ id, elementType, data }: SwatchConfig): UpdateActionsResponse => [
    setElementTypeAndUpdateElement({
        id,
        elementType,
        changes: {
            width: COLOR_SWATCH_DEFAULT_WIDTH_GRID,
            media: {
                height: GRID.LARGE.size * COLOR_SWATCH_DEFAULT_HEIGHT_GRID,
                width: GRID.LARGE.size * COLOR_SWATCH_DEFAULT_WIDTH_GRID,
            },
            showColorValue: true,
            showCaption: true,
            ...data,
        },
        undoChanges: undefined,
        transactionId: undefined,
    }),
];

const getElementTypeUpdateActions = ({ id, elementType, data }: UpdateElementTypeConfig): UpdateActionsResponse => {
    switch (elementType) {
        case ElementType.LINK_TYPE:
            return getUpdateTypeToLinkActions({ id, elementType, data: data as LinkConfig['data'] });
        case ElementType.TASK_TYPE:
            return getUpdateTypeToTaskActions({ id, elementType, data: data as TaskConfig['data'] });
        case ElementType.COLOR_SWATCH_TYPE:
            return getUpdateTypeToSwatchActions({ id, elementType, data: data as SwatchConfig['data'] });
        default:
            return [
                setElementTypeAndUpdateElement({
                    id,
                    elementType,
                    changes: data,
                    undoChanges: undefined,
                    transactionId: undefined,
                }),
            ];
    }
};

type UpdateElementTypeConfig = SwatchConfig | TaskConfig | LinkConfig;

export const updateElementType = (elementConfig: UpdateElementTypeConfig) => (dispatch: Function) => {
    const actions = getElementTypeUpdateActions(elementConfig);
    actions.map((action) => dispatch(action));
};

/**
 * Performs an elementType change on the element that's currently being edited.
 */
export const updateCurrentlyEditingElementType =
    (config: Omit<UpdateElementTypeConfig, 'id'>) => (dispatch: Function, getState: Function) => {
        const state = getState();
        const currentlyEditingId = getCurrentlyEditingId(state) as string;

        if (!currentlyEditingId) {
            logger.warn('No currently editing element found');
            return;
        }

        // @ts-ignore Errors relating to config types not matching...
        return dispatch(updateElementType({ id: currentlyEditingId, ...config }));
    };
