// Lib
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from '../../../../../../../node_module_clones/recompose';

// Utils
import editorStoreConnect from '../../../../../../components/editor/store/components/editorStoreConnect';
import getFirstEntityInSelection from '../../../../../../components/editor/store/reducers/getFirstEntityInSelection';

// Selectors
import { getEditorState } from '../../../../../../components/editor/store/reducers/editorStoreSelector';
import { getCurrentlyEditingId } from '../../../../../../element/selection/currentlyEditingSelector';

// Actions
import { editorEntityChange, editorEntityRemove } from '../../../../../../components/editor/store/editorActions';
import { closePopup } from '../../../../../../components/popupPanel/popupActions';
import { setElementLocalData } from '../../../../../../element/local/elementLocalDataActions';

// Constants
import { DRAFT_ENTITY_MUTABILITY } from '../../../../../../components/editor/draftjsConstants';
import { ENTITIES } from '../../../../../../components/editor/richText/richTextConstants';
import { PopupIds } from '../../../../../../components/popupPanel/popupConstants';

// Components
import HyperlinkPopup from '../../editorTools/hyperlinkPopup/HyperlinkPopup';

const mapEditorStateToProps = (state) => {
    const editorState = getEditorState(state);
    const firstEntityKey = getFirstEntityInSelection(editorState);

    if (!firstEntityKey) return { url: '' };

    const contentState = editorState.getCurrentContent();
    const firstEntity = contentState.getEntity(firstEntityKey);

    if (!firstEntity || firstEntity.get('type') !== ENTITIES.LINK) return { url: '' };

    return { url: firstEntity.get('data').url || '' };
};

const mapEditorDispatchToProps = (dispatch) => ({
    onSetHyperlink: (url) => {
        dispatch(
            editorEntityChange({
                entityType: ENTITIES.LINK,
                mutability: DRAFT_ENTITY_MUTABILITY.MUTABLE,
                data: { url },
            }),
        );
    },
    onClearHyperlink: () => dispatch(editorEntityRemove()),
});

const mapStateToProps = createStructuredSelector({
    currentlyEditingId: getCurrentlyEditingId,
});

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(closePopup(PopupIds.HYPERLINK, false)),
    onUnmount: ({ id }) => dispatch(setElementLocalData({ id, data: { editorState: null } })),
});

const enhancer = compose(
    editorStoreConnect(mapEditorStateToProps, mapEditorDispatchToProps),
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhancer(HyperlinkPopup);
