// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Components
import TextColorButton from '../../editorTools/textStylePopup/TextColorButton';

// Constants
import {
    BACKGROUND_COLOR_PREFIX,
    TEXT_COLOR_PREFIX,
} from '../../../../../../components/editor/plugins/textColor/textColorConstants';

const DraftTextColorButton = (props) => {
    const { currentStyle, preset, onTextColorClick, customTextColor, customBackgroundColor } = props;

    const active = preset
        ? currentStyle.has(preset)
        : !currentStyle.some(
              (style) => style.startsWith(TEXT_COLOR_PREFIX) || style.startsWith(BACKGROUND_COLOR_PREFIX),
          );

    const colorPresetClassname = preset ? preset.toLowerCase() : 'text-standard';

    return (
        <TextColorButton
            active={active}
            color={preset}
            className={colorPresetClassname}
            onSelectTextColor={onTextColorClick}
            customTextColor={customTextColor}
            customBackgroundColor={customBackgroundColor}
        />
    );
};

DraftTextColorButton.propTypes = {
    currentStyle: PropTypes.object,
    preset: PropTypes.string,
    className: PropTypes.string,
    onTextColorClick: PropTypes.func,
    customTextColor: PropTypes.string,
    customBackgroundColor: PropTypes.string,
};

export default DraftTextColorButton;
