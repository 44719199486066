// Lib
import { hot } from 'react-hot-loader/root';
import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router } from '../../node_module_clones/react-router';
import DocumentTitle from 'react-document-title';
import { get } from 'lodash/fp';

import routes from './routes';

// Utils
import { removeInitialPageLoader } from '../components/loaders/InitialPageLoader';

// Element connections
import { MeasurementsProvider } from '../components/measurementsStore/MeasurementsProvider';
import { EditorStoreProvider } from '../components/editor/store/components/EditorStoreProvider';
import { TiptapEditorStoreProvider } from '../components/tiptapEditor/store/TiptapEditorStoreProvider';
import { LiveCollaborationStoreProvider } from '../remoteActivity/liveCollaboration/store/LiveCollaborationStoreProvider';
import workspaceInitialisationMonitoringSingleton from '../workspace/initialisation/workspaceInitialisationMonitoringSingleton';

const createRouteElement = (Component, props) => {
    // Determines if the component being mounted is a second level route.
    // Second level routes will determine whether the loader overlay should be automatically removed,
    // or whether the route will manually handle removing the overlay
    const currentRoutes = get('routes', props) || [];
    const manuallyRemoveLoader = currentRoutes.reduce((acc, entry) => {
        if (acc) return acc;
        return !!get('manuallyRemoveLoader', entry);
    }, false);

    if (!manuallyRemoveLoader) {
        removeInitialPageLoader();
        // If we're not on the workspace, cancel the page initialisation timers
        workspaceInitialisationMonitoringSingleton.cancelTimer();
    }

    return <Component {...props} />;
};

/**
 * The root component of the application.
 *  - Sets up the Redux Provider (which allows container components to access the store).
 *  - Configures the Router (from React Router).
 *
 * This component requires:
 *  - store (redux store)
 *  - history (the react-router-redux wrapped browserHistory instance)
 */
@hot
class Root extends React.Component {
    render() {
        const { store, history, measurementsStore, editorStore, tiptapEditorStore, liveCollaborationStore } =
            this.props;

        return (
            <LiveCollaborationStoreProvider store={liveCollaborationStore}>
                <EditorStoreProvider store={editorStore}>
                    <TiptapEditorStoreProvider store={tiptapEditorStore}>
                        <MeasurementsProvider store={measurementsStore}>
                            <Provider store={store}>
                                <DocumentTitle title="Milanote">
                                    <Router
                                        history={history}
                                        createElement={createRouteElement}
                                        routes={routes}
                                        store={store}
                                    />
                                </DocumentTitle>
                            </Provider>
                        </MeasurementsProvider>
                    </TiptapEditorStoreProvider>
                </EditorStoreProvider>
            </LiveCollaborationStoreProvider>
        );
    }
}

Root.propTypes = {
    editorStore: PropTypes.object.isRequired,
    tiptapEditorStore: PropTypes.object.isRequired,
    measurementsStore: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    liveCollaborationStore: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default Root;
