// Utils
import { getRelativePosition } from '../domUtil';

/**
 * Returns the page position as an { x, y } object.
 */
export const getMouseEventPagePosition = (event) => ({
    x: event.pageX,
    y: event.pageY,
});

/**
 * Creator function for finding the relative position of an event.
 */
export const createGetRelativePositionFromEventFn = (pagePositionGetterFn) => (event, containerDomNode) => {
    const pagePosition = pagePositionGetterFn(event);
    return getRelativePosition(pagePosition, containerDomNode);
};

/**
 * Gets the relative position in a specific DOM node for a mouse event.
 */
export const getRelativePositionFromDomEvent = createGetRelativePositionFromEventFn(getMouseEventPagePosition);

/**
 * Run callback after the next transition is complete on the provided dom element
 * @param element
 * @param callback
 */
export const runAfterNextTransitionEnds = (element, callback) => {
    element?.addEventListener('transitionend', callback, { once: true });
};
