import { CommandProps, Extension, Extensions } from '@tiptap/core';
import {
    findNodeRangesToChangeToList,
    findSelectedListItemContentNodeRanges,
    isListTypeClosestActive,
} from './tiptapListUtils';
import {
    convertListItemParentListType,
    liftListRangeToDoc,
    mergeAdjacentLists,
    wrapInList,
} from './tiptapListOperations';
import { EditorState } from '@tiptap/pm/state';
import { NodeRange, NodeType } from '@tiptap/pm/model';
import { asNodeType } from '../../utils/asNodeType';
import { clearNodesInRange } from '../../utils/tiptapOperations';
import { DEFAULT_TIPTAP_EXTENSION_PRIORITY, TiptapDispatch } from '../../tiptapTypes';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        listCommands: {
            // Needs to match the OOTB list commands type
            toggleList: (
                listTypeOrName: string | NodeType,
                itemTypeOrName: string | NodeType,
                keepMarks?: boolean | undefined,
                attributes?: Record<string, any> | undefined,
            ) => ReturnType;
        };
    }
}

/**
 * Toggles lists off for any list items within the current selection.
 */
const removeFromList = (state: EditorState, dispatch?: TiptapDispatch): boolean => {
    // Select all content nodes within the user's selection
    const selectedListItemContentNodeRanges = findSelectedListItemContentNodeRanges(state);

    if (!selectedListItemContentNodeRanges.length) return false;

    for (const nodeRange of selectedListItemContentNodeRanges) {
        const result = liftListRangeToDoc(state, dispatch, nodeRange);
        if (!result) return false;
    }

    if (!dispatch) return true;

    dispatch(state.tr);

    return true;
};

/**
 * Enables the list type for all nodes within the selection that can
 * be that list type.
 */
const changeToList = (
    state: EditorState,
    dispatch: TiptapDispatch,
    extensions: Extensions,
    listType: NodeType,
): boolean => {
    if (!listType) return false;

    // First get all the nodes in the selection that aren't the same list type
    const nodeRanges = findNodeRangesToChangeToList(state, extensions, listType.name);

    // Then change the type of these nodes to the new list type
    for (const nodeRange of nodeRanges) {
        const { $from, $to } = nodeRange;

        let $mappedFrom = state.tr.doc.resolve(state.tr.mapping.map($from.pos));
        let $mappedTo = state.tr.doc.resolve(state.tr.mapping.map($to.pos));
        let mappedNodeRange = new NodeRange($mappedFrom, $mappedTo, $from.depth);

        const shouldClearNodes = $mappedFrom.nodeAfter?.type !== state.schema.nodes.listItem;

        shouldClearNodes
            ? // Change the nodes back to paragraphs
              clearNodesInRange(state, dispatch, mappedNodeRange)
            : // Change the parent list type to be the same as the new list type
              convertListItemParentListType(listType)(state, dispatch, mappedNodeRange);

        $mappedFrom = state.tr.doc.resolve(state.tr.mapping.map($from.pos));
        $mappedTo = state.tr.doc.resolve(state.tr.mapping.map($to.pos));
        mappedNodeRange = new NodeRange($mappedFrom, $mappedTo, $from.depth);

        // Wrap them in lists
        wrapInList(listType)(state, dispatch, mappedNodeRange);
    }

    // Then join the nodes together if the nodes before or after are the same list type
    mergeAdjacentLists(listType)(state, dispatch);

    if (!dispatch) return true;

    dispatch(state.tr);

    return true;
};

/**
 * Determines whether the list type is currently active, and disables it if it is,
 * or enables it if it is not.
 */
const toggleList =
    (
        listTypeOrName: string | NodeType,
        // NOTE: The following parameters aren't used at the moment, they're just
        //  here to match the OOTB list command signature
        itemTypeOrName: string | NodeType,
        keepMarks?: boolean | undefined,
        attributes?: Record<string, any> | undefined,
    ) =>
    ({ editor, commands, state, dispatch }: CommandProps) => {
        const listType = asNodeType(listTypeOrName, state);

        if (!listType) return false;

        const isCurrentlyActive = isListTypeClosestActive(listType.name)(editor);

        return isCurrentlyActive
            ? removeFromList(state, dispatch as TiptapDispatch)
            : changeToList(state, dispatch as TiptapDispatch, editor.extensionManager.extensions, listType);
    };

/**
 * This extension provides commands
 */
export const ListCommands = Extension.create({
    name: 'listCommands',

    // Strangely _reducing_ the priority here allows this extension to override the toggleList
    //  command, otherwise the OOTB command is used instead.
    // This seems to be the opposite to what the docs suggest.
    priority: DEFAULT_TIPTAP_EXTENSION_PRIORITY - 1,

    addCommands() {
        return {
            toggleList,
        };
    },
});
