import registerLink from '../../../common/links/index';
import presentationalComponent from './Link';
import containerComponent from './LinkContainer';

import platformSingleton from '../../platform/platformSingleton';
import { isPlatformLegacyMobileApp } from '../../platform/utils/platformDetailsUtils';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

const LINK_SELECTED_TOOLS = [
    TOOLBAR_TOOLS.COLOR,
    TOOLBAR_TOOLS.MEDIA,
    { ...TOOLBAR_TOOLS.CAPTION, name: 'Description' },
    TOOLBAR_TOOLS.REACTION,
    ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT,
];

export default () =>
    registerLink({
        containerComponent,
        presentationalComponent,
        tools: {
            selected: LINK_SELECTED_TOOLS,
            editing_TITLE: LINK_SELECTED_TOOLS, // use regular selection tools when editing title
            editing_CAPTION: isPlatformLegacyMobileApp(platformSingleton) ? [] : TOOL_SETS.TEXT_EDITING_CAPTION,
        },
    });
