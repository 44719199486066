// Lib
import React, { ReactElement } from 'react';

// Hooks
import {
    ActiveTiptapEditorSelector,
    useActiveTiptapEditorCallback,
    useTiptapActiveEditorSelector,
} from '../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Components
import RichTextTool from '../richContentTools/RichTextTool';

// Types
import { ToolbarToolProps } from '../../../config/toolDefinitions/toolbarToolTypes';

const isMarkActiveSelector =
    (mark: string): ActiveTiptapEditorSelector =>
    (editor) =>
        editor?.isActive(mark);

type StandardTiptapMarkToolProps = ToolbarToolProps & {
    mark: string;
};

const TiptapToggleMarkTool = (props: StandardTiptapMarkToolProps): ReactElement => {
    const { mark } = props;

    const isActive = useTiptapActiveEditorSelector(isMarkActiveSelector(mark));

    const toggleMark = useActiveTiptapEditorCallback(
        (activeEditor, event: PointerEvent) => {
            if (!activeEditor) return;

            event.preventDefault();

            // Force focus back into the editor then toggle the mark
            activeEditor.chain().focus().toggleMark(mark).run();
        },
        [mark],
    );

    return (
        <RichTextTool
            isActive={isActive}
            onPointerDown={toggleMark}
            iconName={props.iconName || 'toolbar-bold'}
            {...props}
        />
    );
};

export default TiptapToggleMarkTool;
