// Lib
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, Middleware } from 'redux';

// Config
import getClientConfig from '../../../utils/getClientConfig';

// Utils
import { createLogger } from '../../../../node_module_clones/redux-logger/src';
import { isLoggerDebugEnabled, LoggerComponents } from '../../../logger';
import { isGlobalDebugEnabled } from '../../../debug/debugUtil';

// Store
import addStateToWindowMiddleware from '../../../store/addStateToWindowMiddleware';
import rootTiptapEditorStoreReducer from './reducers/rootTiptapEditorStoreReducer';

// Types
import { TiptapEditorStoreActions } from './tiptapEditorStoreTypes';
import { DEBUG_LEVEL } from '../../../../../config/configConstants';

// Actions to filter from the logger
export const LOG_FILTER_ACTIONS: Partial<Record<TiptapEditorStoreActions['type'], number>> = {
    SET_ACTIVE_TIPTAP_EDITOR: DEBUG_LEVEL.FINE,
};

/**
 * The initial entry point to the Tiptap editor store.
 */
export default function configureTiptapEditorStore(initialState = undefined) {
    const middlewareList: Middleware[] = [thunk];
    const config = getClientConfig();

    if (isLoggerDebugEnabled(LoggerComponents.REDUX_LOG_EDITOR)) {
        const filteredActions = Object.entries(LOG_FILTER_ACTIONS)
            .filter(([logName, logLevel]) => logLevel >= (config.debugLevel as number))
            .map(([logName]) => logName);

        middlewareList.push(
            createLogger({
                // @ts-ignore TS can't infer the types correctly
                predicate: (_, action) => filteredActions.indexOf(action.type) === -1,
                collapsed: true,
                duration: true,
                timestamp: false,
                colors: {
                    title: () => 'goldenrod',
                },
            }) as Middleware,
        );
    }

    if (isGlobalDebugEnabled()) {
        middlewareList.push(addStateToWindowMiddleware('tipap_editor_'));
    }

    const middleware = applyMiddleware(...middlewareList);

    return createStore(rootTiptapEditorStoreReducer, initialState, middleware);
}
