import { markInputRule } from '@tiptap/core';

import DefaultBold from '@tiptap/extension-bold';

/**
 * Matches a bold to a *bold* on input.
 */
export const singleStarInputRegex = /(?:^|\s)(\*(?!\s+\*)((?:[^*]+))\*(?!\s+\*))$/;

/**
 * Matches bold text via `**` as input.
 */
export const doubleStarInputRegex = /(?:^|\s)(\*\*(?!\s+\*\*)((?:[^*]+))\*\*(?!\s+\*\*))$/;

/**
 * Matches bold text via `__` as input.
 */
export const doubleUnderscoreInputRegex = /(?:^|\s)(__(?!\s+__)((?:[^_]+))__(?!\s+__))$/;

export const Bold = DefaultBold.extend({
    addInputRules() {
        return [
            markInputRule({
                find: singleStarInputRegex,
                type: this.type,
            }),
            markInputRule({
                find: doubleStarInputRegex,
                type: this.type,
            }),
            markInputRule({
                find: doubleUnderscoreInputRegex,
                type: this.type,
            }),
        ];
    },
});
