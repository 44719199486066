// Lib
import React from 'react';
import PropTypes from 'prop-types';

export default (DecoratedComponent) => {
    class focusEditorDecorator extends React.Component {
        cardEditorRef = (c) => {
            this.editorElement = c;
            this.props.cardEditorRef?.(c);
        };

        focusOnEditor = () => {
            // TODO-TIPTAP: This is draft-specific and will need to be implemented for tiptap
            this.editorElement?.focusOnEditor?.();
        };

        render() {
            return (
                <DecoratedComponent
                    {...this.props}
                    focusOnEditor={this.focusOnEditor}
                    cardEditorRef={this.cardEditorRef}
                />
            );
        }
    }

    focusEditorDecorator.propTypes = {
        cardEditorRef: PropTypes.func,
    };

    return focusEditorDecorator;
};
