// Lib
import React from 'react';
import { isEmpty } from 'lodash/fp';
import PropTypes from 'prop-types';

// Utils
import { selectAllText } from '../../../../../../utils/domUtil';
import { hasCommandModifier } from '../../../../../../utils/keyboard/keyboardUtility';
import { hasChanged } from '../../../../../../utils/react/propsComparisons';

// Components
import SearchClearButton from '../../../../../../components/form/SearchClearButton';

// Constants
import { KEY_CODES } from '../../../../../../utils/keyboard/keyConstants';

// Styles
import './HyperlinkPopup.scss';

const urlHasChanged = hasChanged('url');

export default class HyperlinkPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            url: this.props.url || '',
        };
    }

    componentDidMount() {
        requestAnimationFrame(() => {
            this.inputComponent.focus();
            selectAllText(this.inputComponent);
        });

        document.addEventListener('pointerdown', this.handlePointerDown, { capture: true });
        document.addEventListener('keydown', this.handleKeyDown, { capture: true });
    }

    componentWillReceiveProps(nextProps) {
        if (urlHasChanged(this.props, nextProps)) {
            this.setState({ url: nextProps.url });
        }
    }

    componentWillUnmount() {
        const { onUnmount, currentlyEditingId } = this.props;
        onUnmount?.({ id: currentlyEditingId });

        document.removeEventListener('pointerdown', this.handlePointerDown, { capture: true });
        document.removeEventListener('keydown', this.handleKeyDown, { capture: true });
    }

    // Handle stripping of spaces or new lines here
    onChange = (event) => {
        const text = event.target.value;

        const url = text.replace(/[\s,\n]/g, '');

        this.setState({ url });
    };

    onKeyPress = (event) => {
        switch (event.which) {
            case KEY_CODES.ENTER:
                return this.onSubmit(event);
            default:
        }
    };

    onSubmit = (event) => {
        event.preventDefault();

        const { onSetHyperlink, onClearHyperlink, onClose } = this.props;
        const { url } = this.state;

        onClose();

        isEmpty(url) ? onClearHyperlink() : onSetHyperlink(url);
    };

    handlePointerDown = (event) => {
        if (this.component.contains(event.target)) return;

        event.preventDefault();
        event.stopPropagation();
        this.saveOrCancelChanges();
    };

    handleKeyDown = (event) => {
        if (hasCommandModifier(event) && event.which === KEY_CODES.K) {
            return this.cancelChanges(event);
        }

        switch (event.which) {
            case KEY_CODES.ESC:
                return this.cancelChanges(event);
            case KEY_CODES.TAB: {
                event.preventDefault();
                event.stopPropagation();
                return this.saveOrCancelChanges(event);
            }
            case KEY_CODES.SPACEBAR: {
                event.preventDefault();
                event.stopPropagation();
                return null;
            }
            default:
                return null;
        }
    };

    saveOrCancelChanges = () => {
        // Closing without a URL should remove the 'empty link entity' that was created when opening the popup
        if (isEmpty(this.state.url)) return this.removeHyperlink();

        this.props.onSetHyperlink(this.state.url);
        this.props.onClose();
    };

    cancelChanges = (event) => {
        const { onSetHyperlink, onClose } = this.props;

        event.preventDefault();

        // Cancelling without a saved URL should remove the 'empty link entity' that was created when
        // opening the popup
        if (isEmpty(this.props.url)) return this.removeHyperlink();

        // Otherwise remove the highlight data
        onSetHyperlink(this.props.url);
        onClose();
    };

    removeHyperlink = () => {
        const { onClearHyperlink, onClose } = this.props;
        onClearHyperlink();
        onClose();
    };

    render() {
        const { url } = this.state;

        return (
            <div
                className="HyperlinkPopup"
                ref={(c) => {
                    this.component = c;
                }}
            >
                <input
                    className="hyperlink-input"
                    tabIndex={-1}
                    ref={(c) => {
                        this.inputComponent = c;
                    }}
                    type="text"
                    placeholder="Enter a URL..."
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPress}
                    value={url}
                />
                <SearchClearButton show={!!url} canClear={!!url} onPointerDown={this.removeHyperlink} />
            </div>
        );
    }
}

HyperlinkPopup.propTypes = {
    onSetHyperlink: PropTypes.func.isRequired,
    onClearHyperlink: PropTypes.func.isRequired,
    onUnmount: PropTypes.func,

    onClose: PropTypes.func.isRequired,
    currentlyEditingId: PropTypes.string,
    url: PropTypes.string,
};
