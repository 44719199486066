// Lib
import React, { ReactElement, ReactNode, useContext } from 'react';
import { createPortal } from 'react-dom';

// Styles
import './MobileModalPortalMountPoint.scss';

type MobileModalPortalContextArgs = {
    mountPointRef: React.RefObject<HTMLDivElement>;
};

const MobileModalPortalContext = React.createContext<MobileModalPortalContextArgs>({
    mountPointRef: React.createRef<HTMLDivElement>(),
});

const useMobileModalPortalContext = () => useContext(MobileModalPortalContext);

/**
 * This is the component that should be used by most other components to render their content
 * within the toolbar portal.
 */
interface MobileModalPortalProps {
    children: ReactNode;
}

/**
 * This allows the content to be rendered within the portal.
 */
const MobileModalPortal = (props: MobileModalPortalProps): ReactElement | null => {
    const { children } = props;

    const { mountPointRef } = useMobileModalPortalContext();

    if (!mountPointRef.current) return null;

    return createPortal(children, mountPointRef.current);
};

export default MobileModalPortal;

/**
 * This creates the div that will be used to mount the portal content.
 */
export const MobileModalPortalMountPoint = () => {
    const { mountPointRef } = useMobileModalPortalContext();

    return <div ref={mountPointRef} className="MobileModalPortalMountPoint" />;
};
