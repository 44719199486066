import { useState } from 'react';

const useModalSheet = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeSnapPoint, setActiveSnapPoint] = useState(0);
    return {
        isOpen,
        setIsOpen,
        activeSnapPoint,
        setActiveSnapPoint,
    };
};

export default useModalSheet;
