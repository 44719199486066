export const PUSH_NOTIFICATION_SUBSCRIPTION_STATUSES = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
    REMOVED: 'REMOVED',
};

export const PUSH_NOTIFICATION_MESSAGE_TYPES = {
    NAVIGATE_TO: 'NAVIGATE_TO',
};

export const PUSH_NOTIFICATION_TYPES = {
    MENTION: 'MENTION',
    ASSIGNMENT: 'ASSIGNMENT',
    REPLY: 'REPLY',
    SHARE: 'SHARE',
    REMINDER: 'REMINDER',
};

export const PUSH_NOTIFICATION_SUBSCRIPTION_TYPES = {
    WEB: 'WEB',
    APNS: 'APNS',
    FCM: 'FCM',
};
