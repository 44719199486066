// Lib
import { useMemo } from 'react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import TextColor from '@tiptap/extension-color';

// Custom extensions
import { TextAlign } from '../../../../common/tiptap/extensions/TextAlign';
import { EmptyDelete } from '../../../../common/tiptap/extensions/EmptyDelete';
import { Strike } from '../../../../common/tiptap/extensions/Strike';
import { Link } from '../../../../common/tiptap/extensions/Link';
import { Bold } from '../../../../common/tiptap/extensions/Bold';
import { Italic } from '../../../../common/tiptap/extensions/Italic';
import { InlineStyleClearer } from '../../../../common/tiptap/extensions/InlineStyleClearer';
import { DebouncedSaveCommands } from '../../../../common/tiptap/extensions/DebouncedSave';
import { TextStyle } from '../../../../common/tiptap/extensions/TextStyle';
import { TextHighlight } from '../../../../common/tiptap/extensions/TextHighlight';
import { Mention } from '../../../../common/tiptap/extensions/mention/Mention';
import { MentionsSuggestionsManager } from '../../../../common/tiptap/extensions/mention/suggestionsManager';

// Types
import { Editor } from '@tiptap/react';
import { Extensions } from '@tiptap/core';

const SIMPLE_EXTENSIONS = [
    // The standard extensions for Tiptap
    //  Supports: Lists, quotes, code, inline styles, headings, history
    //  See: https://tiptap.dev/docs/editor/extensions/functionality/starterkit
    StarterKit.configure({
        strike: false, // overridden by a later plugin

        // Forbid certain styles from captions (NOTE: This design decision is being revisited)
        heading: false,
        codeBlock: false,
        blockquote: false,
        bulletList: false,
        orderedList: false,
        bold: false,
        italic: false,
    }),
    Strike,
    Bold,
    Italic,
    Underline,
    // Allows text to be centred
    //  See: https://tiptap.dev/docs/editor/extensions/functionality/textalign
    TextAlign,
    // This extension is required for the TextColor extension to work
    TextStyle,
    TextColor,
    TextHighlight,
    // Clears inline styles when the user presses Enter
    InlineStyleClearer,
    DebouncedSaveCommands,
];

const useCaptionTiptapEditorExtensions = (
    placeholder: string | undefined,
    onEmptyBackspace: (() => void) | undefined,
    saveCaptionContent: (content: object) => void,
    suggestion: MentionsSuggestionsManager,
    isEditable?: boolean,
    dispatch?: Function,
): Extensions =>
    useMemo(
        () => [
            ...SIMPLE_EXTENSIONS,
            EmptyDelete.configure({
                onEmptyDelete: (editor: Editor) => {
                    // Save the empty content first, before deleting the element, so that the
                    //  undo stack is correct
                    const emptyContent = editor.getJSON();
                    saveCaptionContent(emptyContent);

                    onEmptyBackspace?.();

                    return !!onEmptyBackspace;
                },
            }),
            suggestion && Mention.configure({ suggestion }),
            // Show the placeholder text when the editor is empty
            Placeholder.configure({
                placeholder,
                showOnlyWhenEditable: false,
            }),
            // Allows hyperlinks
            //  See: https://tiptap.dev/docs/editor/extensions/marks/link#page-title
            Link.configure({
                dispatch,
                isEditable,
            }),
        ],
        [placeholder, onEmptyBackspace, saveCaptionContent, suggestion, isEditable, dispatch],
    );

export default useCaptionTiptapEditorExtensions;
