import registerImage from '../../../common/images/index';
import presentationalComponent from './Image';
import containerComponent from './ImageContainer';
import modalComponent from './modal/ImageModal';

// Utils
import platformSingleton from '../../platform/platformSingleton';
import { isPlatformApple, isPlatformLegacyMobileApp } from '../../platform/utils/platformDetailsUtils';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerImage({
        presentationalComponent,
        containerComponent,
        modalComponent,
        tooltip: isPlatformApple(platformSingleton) ? '⌃⌘I' : 'Ctrl+Alt+I',
        tools: {
            selected: [
                TOOLBAR_TOOLS.COLOR,
                TOOLBAR_TOOLS.CAPTION,
                TOOLBAR_TOOLS.SHOW_BACKGROUND,
                TOOLBAR_TOOLS.IMAGE_EDIT,
                TOOLBAR_TOOLS.EDIT_DRAWING,
                TOOLBAR_TOOLS.REACTION,
                ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT,
            ],
            editing_CAPTION: isPlatformLegacyMobileApp(platformSingleton) ? [] : TOOL_SETS.TEXT_EDITING_CAPTION,
        },
    });
