// Lib
import { withHandlers, compose } from '../../node_module_clones/recompose';

// Utils
import { getListChildNewLocation } from '../../common/elements/utils/elementLocationUtils';
import allowDoubleClickCreateShortcutDecorator from '../components/events/allowDoubleClickCreateShortcutDecorator';
import getRangeIndex from './getRangeIndex';
import { analyticsEvent } from '../analytics';

// Constants
import { ELEMENT_CREATION_SOURCES } from '../../common/elements/elementConstants';

const shortcutElementHandlers = {
    onDoubleClick:
        ({ createShortcutCard, getRangeArray, listId, currentBoardId, isEditable, getContextZoomScale }) =>
        (event) => {
            if (!isEditable || !createShortcutCard) return;

            event.preventDefault();
            event.stopPropagation();

            const zoomScale = getContextZoomScale();

            // In the same way as ListContainer.getOffsetTop we need to figure out how much padding is at the top
            // of the ListContainer because this padding is not included in the "rangeArray" measurements
            const paddingTop =
                window.getComputedStyle(event.currentTarget) && window.getComputedStyle(event.currentTarget).paddingTop;
            const offsetTop = paddingTop ? parseInt(paddingTop, 10) * zoomScale : 0;

            const clickPosFromListTop = event.clientY - event.currentTarget.getBoundingClientRect().top - offsetTop;
            const rangeArray = getRangeArray();
            const index = getRangeIndex(clickPosFromListTop, rangeArray, zoomScale);

            const location = getListChildNewLocation({ listId, index });

            createShortcutCard({
                location,
                currentBoardId: listId,
                creationSource: ELEMENT_CREATION_SOURCES.DOUBLE_CLICK,
            });

            if (listId === currentBoardId) analyticsEvent('moved-element-to-unsorted-note');
        },
};

const shortcutElementDecorator = compose(
    withHandlers(shortcutElementHandlers),
    allowDoubleClickCreateShortcutDecorator,
);

export default shortcutElementDecorator;
