// Handlers
import handleBeforeInput from './handleBeforeInput';
import handleKeyCommand from './handleKeyCommand';
import handleReturn from './handleReturn';
import keyBindingFn from './keyBindingFn';

// Styles
import './listStyles.scss';

/**
 * TODO Should this instead be called styledText or something similar maybe?
 */
export default ({ allowNewlinePredicate } = {}) => ({
    handleBeforeInput,
    // Allows cmd+b etc to be used to style text
    handleKeyCommand,
    // Provides editing help such as removing certain block styles on enter, or adding soft new lines.
    handleReturn: handleReturn({ allowNewlinePredicate }),
    // Allows jumping immutable entities and deep lists
    keyBindingFn,
});
