import { Editor } from '@tiptap/react';
import React from 'react';
import { identity } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { createUsableUrl } from '../../../../../../common/tiptap/extensions/Link';
import { closePopup, togglePopup } from '../../../../../components/popupPanel/popupActions';
import { PopupIds } from '../../../../../components/popupPanel/popupConstants';
import popupOpenSelector from '../../../../../components/popupPanel/popupOpenSelector';
import { getCurrentlyEditingId } from '../../../../../element/selection/currentlyEditingSelector';
import ToolbarPopup from '../../toolbarPopup/ToolbarPopup';
import RichTextTool from '../richContentTools/RichTextTool';
import HyperlinkPopup from './hyperlinkPopup/HyperlinkPopup';
import {
    ActiveTiptapEditorSelector,
    useActiveTiptapEditorCallback,
    useTiptapActiveEditorSelector,
} from '../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

const getCurrentUrl: ActiveTiptapEditorSelector = (editor) => editor?.getAttributes('link').href || '';

const TiptapHyperlinkTool = () => {
    const isPopupOpen = useSelector(popupOpenSelector(PopupIds.HYPERLINK));
    const currentlyEditingId = useSelector(getCurrentlyEditingId);

    const dispatch = useDispatch();
    const closeHyperlinkPopup = () => dispatch(closePopup(PopupIds.HYPERLINK));
    const openHyperlinkPopup = () => dispatch(togglePopup(PopupIds.HYPERLINK));

    const currentUrl = useTiptapActiveEditorSelector(getCurrentUrl);
    // FIXME-TIPTAP - links - This logic is not correct
    const isDisabled = false;

    const onSetHyperlink = useActiveTiptapEditorCallback(
        (activeEditor, url: string) => {
            if (!activeEditor) return;

            const href = createUsableUrl(url);

            // Check if there's a selection or just a caret
            const currentSelection = activeEditor.view.state.selection;
            const [firstRange] = currentSelection.ranges;
            const from = firstRange.$from.pos;
            const to = firstRange.$to.pos;

            if (from === to) {
                // This represents no selection, just a caret in amongst some text.
                // So we expand the selection to cover the link that the caret is on, edit that link,
                // and then reset the selection to where it was.
                activeEditor
                    .chain()
                    .focus()
                    .extendMarkRange('link', { href: currentUrl })
                    .setLink({ href })
                    .setTextSelection(from)
                    .run();
                return;
            }

            // Here we have some text specifically selected -- just update the mark for exactly that selection
            activeEditor.chain().focus().setLink({ href }).run();
        },
        [currentUrl],
    );

    const onClearHyperlink = useActiveTiptapEditorCallback((activeEditor) => {
        if (!activeEditor) return;
        activeEditor.chain().focus().unsetLink().run();
    }, []);

    return (
        <div className="HyperLinkRichTextToolContainer">
            <RichTextTool
                onPointerDown={openHyperlinkPopup}
                iconName="toolbar-hyperlink"
                tooltipEnabled={!isPopupOpen}
                isOpen={isPopupOpen}
                styleCommand="link"
                disabled={isDisabled}
            >
                <ToolbarPopup
                    popupId={PopupIds.HYPERLINK}
                    className="hyperlink-popup"
                    buttonSelector=".RichTextTool.link .icon"
                    closePopup={closeHyperlinkPopup}
                >
                    <HyperlinkPopup
                        onSetHyperlink={onSetHyperlink}
                        onClearHyperlink={onClearHyperlink}
                        onClose={closeHyperlinkPopup}
                        currentlyEditingId={currentlyEditingId}
                        url={currentUrl}
                        isActive={!!currentUrl}
                    />
                </ToolbarPopup>
            </RichTextTool>
        </div>
    );
};

export default TiptapHyperlinkTool;
