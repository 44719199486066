export const DEBUG_TOOL_UPDATE = 'DEBUG_TOOL_UPDATE';

export const DEBUG_TOOLS = {
    DRAWING: 'drawing',
    LIVE_CURSOR: 'liveCursor',
    ZOOM: 'zoom',
    DND: 'dnd',
    MOBILE_KEYBOARD_ANIMATION: 'mobileKeyboardAnimation',
    TIPTAP_EDITOR: 'tiptapEditor',
};
