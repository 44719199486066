// Lib
import React, { ReactElement } from 'react';
import { SelectionState } from 'draft-js';

// Store
import { useEditorDispatch } from '../../../components/editor/store/components/EditorStoreProvider';

// Actions
import {
    getEditorIdThunk,
    getEditorStateThunk,
    saveEditorSelection,
} from '../../../components/editor/store/components/editorStoreThunks';

// Utils
import { focusFakeInput } from '../../../utils/ipad/ipadUtils';

// Hooks
import useModalSheet from '../../structural/modalSheet/hooks/useModalSheet';

// Components
import MobileToolbarSheet from '../../../workspace/toolbar/components/mobileToolbarSheet/MobileToolbarSheet';
import TextStyleRichTextTool from '../../../workspace/toolbar/components/selectionTools/richContentTools/textStylesTool/TextStyleRichTextTool';
import TextStyleRichTextToolPopupContent from '../../../workspace/toolbar/components/selectionTools/richContentTools/textStylesTool/TextStyleRichTextToolPopup';

// Types
import { ImMNUser, MNUser } from '../../../../common/users/userModelTypes';
import { ImMNElement } from '../../../../common/elements/elementModelTypes';

type MobileTextStyleRichTextToolProps = {
    currentUser: ImMNUser | MNUser;
    currentUserId: string;
    currentBoardId: string;
    gridSize: number;
    gridSizeName: string;
    permissions: number;
    selectedElementIds: Immutable.List<string>;
    selectedElements: Immutable.List<ImMNElement>;
    editorKey: string;
    hasEditorSelection: boolean | undefined;
};

// FIXME-MOBILE - Add a style to the current editor to show the current selection
//  NOTE: Investigate whether it might be easier to do this using vanilla JS, so that we don't
//        accidentally save the selection state to the DB
const MobileTextStyleRichTextTool = (props: MobileTextStyleRichTextToolProps): ReactElement => {
    const editorDispatch = useEditorDispatch();
    // @ts-ignore Can't deal with thunks
    const dispatchGetEditorState = () => editorDispatch(getEditorStateThunk());
    // @ts-ignore Can't deal with thunks
    const dispatchGetEditorId = () => editorDispatch(getEditorIdThunk());
    const dispatchForceEditorSelection = (editorId: string, selection: SelectionState) =>
        editorDispatch(saveEditorSelection(editorId, selection));

    const { isOpen, setIsOpen, activeSnapPoint, setActiveSnapPoint } = useModalSheet();

    /**
     * Open the text style tool popup on click of the tool button.
     */
    const onClickToolButton = () => {
        setIsOpen(true);
    };

    /**
     * Focus fake input as read only, so that we can switch focus to the element editor later
     */
    const onSheetClose = () => {
        const mobileWorkspaceElement = document.querySelector('.ModernMobileWorkspace');
        focusFakeInput({ x: 0, y: 0 }, mobileWorkspaceElement, true);
    };

    /**
     * Force focus of the editor.
     */
    const onCloseTransitionEnd = () => {
        const editorState = dispatchGetEditorState();
        const currentSelection = editorState.getSelection();

        const editorId = dispatchGetEditorId() as unknown as string;
        dispatchForceEditorSelection(editorId, currentSelection);
    };

    return (
        <div className="MobileTextStyleRichTextTool">
            <div onClick={onClickToolButton}>
                <TextStyleRichTextTool isOpen={isOpen} name="Text style" />
            </div>
            <MobileToolbarSheet
                onSheetClose={onSheetClose}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                activeSnapPoint={activeSnapPoint}
                setActiveSnapPoint={setActiveSnapPoint}
                onCloseTransitionEnd={onCloseTransitionEnd}
            >
                <TextStyleRichTextToolPopupContent {...props} showBlockStyleTools={true} />
            </MobileToolbarSheet>
        </div>
    );
};

export default MobileTextStyleRichTextTool;
