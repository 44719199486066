// Components
import CenterTextTool from '../../components/selectionTools/editorTools/CenterTextTool';
import HyperlinkTool from '../../components/selectionTools/editorTools/HyperlinkTool';
import TextStyleTool from '../../components/selectionTools/editorTools/TextStyleTool';
import AssignUserTool from '../../components/selectionTools/assignmentTools/AssignUserTool';
import IndentTaskToolContainer from '../../components/selectionTools/taskTools/IndentTaskToolContainer';
import UnindentTaskToolContainer from '../../components/selectionTools/taskTools/UnindentTaskToolContainer';
import DueDateTool from '../../components/selectionTools/assignmentTools/DueDateTool';
import TableCellAlignmentTool from '../../components/selectionTools/tableTools/TableCellAlignmentTool';
import TableCellFormulaTool from '../../components/selectionTools/tableTools/TableCellFormulaTool';
import AddColumnTool from '../../components/selectionTools/tableTools/AddColumnTool';
import AddRowTool from '../../components/selectionTools/tableTools/AddRowTool';
import CellTypeTool from '../../components/selectionTools/tableTools/CellTypeTool';
import TableCellColorTool from '../../components/selectionTools/tableTools/TableCellColorTool';
import MobileTextStyleRichTextTool from '../../../../mobile/toolbar/tools/MobileTextStyleRichTextTool';
import BoldTool from '../../components/selectionTools/editorTools/BoldTool';
import ItalicTool from '../../components/selectionTools/editorTools/ItalicTool';
import StrikeTool from '../../components/selectionTools/editorTools/StrikeTool';
import OrderedListTool from '../../components/selectionTools/editorTools/OrderedListTool';
import BulletedListTool from '../../components/selectionTools/editorTools/BulletedListTool';

// Constants
import { STYLE_COMMANDS } from '../../../../components/editor/richText/richTextConstants';
import { COLLAPSE_ORDER, TOOL_HEIGHT } from '../toolbarToolConfigConstants';

// Types
import { ToolbarItemConfig } from '../../toolbarTypes';

const TEXT_STYLES: ToolbarItemConfig = {
    id: 'TEXT_STYLE',
    name: 'Text style',
    height: TOOL_HEIGHT.SQUARE,
    component: TextStyleTool,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    toolSettings: {
        showBlockStyleTools: false,
    },
    mobile: {
        available: true,
        component: MobileTextStyleRichTextTool,
    },
};

const TEXT_BLOCK_STYLES: ToolbarItemConfig = {
    id: 'TEXT_BLOCK_STYLES',
    name: 'Block styles',
    height: TOOL_HEIGHT.SQUARE,
    component: TextStyleTool,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    toolSettings: {
        showBlockStyleTools: true,
    },
    mobile: {
        available: true,
        component: MobileTextStyleRichTextTool,
    },
};

const TEXT_BOLD: ToolbarItemConfig = {
    id: 'TEXT_BOLD',
    name: 'Bold',
    tooltipText: 'Bold',
    shortcut: ['command', 'b'],
    iconName: 'toolbar-bold',
    height: TOOL_HEIGHT.SQUARE,
    component: BoldTool,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    toolSettings: {
        styleCommand: STYLE_COMMANDS.BOLD,
    },
    mobile: {
        available: true,
    },
};

const TEXT_ITALIC: ToolbarItemConfig = {
    id: 'TEXT_ITALIC',
    name: 'Italic',
    tooltipText: 'Italic',
    // Adding a space to visually shift the 1 across slightly
    shortcut: ['command', ' ', ' ', 'i'],
    iconName: 'toolbar-italic',
    component: ItalicTool,
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    toolSettings: {
        styleCommand: STYLE_COMMANDS.ITALIC,
    },
    mobile: {
        available: true,
    },
};

const TEXT_STRIKETHROUGH: ToolbarItemConfig = {
    id: 'TEXT_STRIKETHROUGH',
    name: 'Strikethrough',
    tooltipText: 'Strikethrough',
    shortcut: ['command', 'shift', 'X'],
    iconName: 'toolbar-strikethrough',
    component: StrikeTool,
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    toolSettings: {
        styleCommand: STYLE_COMMANDS.STRIKETHROUGH,
    },
    mobile: {
        available: true,
    },
};

const TEXT_BULLET_LIST: ToolbarItemConfig = {
    id: 'TEXT_BULLET_LIST',
    name: 'Bullet',
    tooltipText: 'Bullet',
    shortcut: ['command', 'shift', 'u'],
    iconName: 'toolbar-list',
    component: BulletedListTool,
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    toolSettings: {
        styleCommand: STYLE_COMMANDS.LIST,
    },
    mobile: {
        available: true,
    },
};

const TEXT_ORDERED_LIST: ToolbarItemConfig = {
    id: 'TEXT_ORDERED_LIST',
    name: 'Numbered list',
    tooltipText: 'Numbered list',
    shortcut: ['command', 'shift', 'o'],
    iconName: 'toolbar-numlist',
    component: OrderedListTool,
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    toolSettings: {
        styleCommand: STYLE_COMMANDS.ORDERED_LIST,
    },
    mobile: {
        available: true,
    },
};

const TEXT_CENTERED: ToolbarItemConfig = {
    id: 'TEXT_CENTERED',
    name: 'Align center',
    tooltipText: 'Align center',
    // Adding a space to visually shift the 1 across slightly
    shortcut: ['command', ' ', ' ', '\\'],
    iconName: 'toolbar-center-text',
    component: CenterTextTool,
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    mobile: {
        available: true,
    },
};

const TEXT_HYPERLINK: ToolbarItemConfig = {
    id: 'TEXT_HYPERLINK',
    name: 'Hyperlink',
    tooltipText: 'Hyperlink',
    shortcut: ['command', 'k'],
    iconName: 'toolbar-hyperlink',
    component: HyperlinkTool,
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    toolSettings: {
        styleCommand: STYLE_COMMANDS.HYPERLINK,
    },
};

const TABLE_CELL_ALIGNMENT: ToolbarItemConfig = {
    id: 'TABLE_CELL_ALIGNMENT',
    name: 'Alignment',
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    component: TableCellAlignmentTool,
};

const TABLE_CELL_FORMULA: ToolbarItemConfig = {
    id: 'TABLE_CELL_FORMULA',
    name: 'Formula',
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    component: TableCellFormulaTool,
};

const TABLE_CELL_TYPE: ToolbarItemConfig = {
    id: 'TABLE_CELL_TYPE',
    name: 'Cell type',
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.NEVER,
    component: CellTypeTool,
};

const TABLE_CELL_COLOR: ToolbarItemConfig = {
    id: 'TABLE_CELL_COLOR',
    name: 'Cell Color',
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.NEVER,
    component: TableCellColorTool,
};

const TABLE_ADD_COLUMN: ToolbarItemConfig = {
    id: 'TABLE_ADD_COLUMN',
    name: 'Add column',
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    component: AddColumnTool,
};

const TABLE_ADD_ROW: ToolbarItemConfig = {
    id: 'TABLE_ADD_ROW',
    name: 'Add row',
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    component: AddRowTool,
};

const ASSIGNMENT: ToolbarItemConfig = {
    id: 'ASSIGNMENT',
    name: 'Assign',
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    component: AssignUserTool,
    tooltipText: 'Assign to user',
    shortcut: ['shift', '@'],
};

const INDENT_INCREASE: ToolbarItemConfig = {
    id: 'INDENT_INCREASE',
    name: 'Indent',
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    tooltipText: 'Indent',
    shortcut: ['\u0009'],
    iconName: 'toolbar-indent',
    component: IndentTaskToolContainer,
};

const INDENT_DECREASE: ToolbarItemConfig = {
    id: 'INDENT_DECREASE',
    name: 'Outdent',
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    tooltipText: 'Unindent',
    shortcut: ['shift', '\u0009'],
    iconName: 'toolbar-unindent',
    component: UnindentTaskToolContainer,
};

const DUE_DATE: ToolbarItemConfig = {
    id: 'DUE_DATE',
    name: 'Due date',
    height: TOOL_HEIGHT.SQUARE,
    collapseOrder: COLLAPSE_ORDER.DEFAULT,
    tooltipText: 'Unindent',
    shortcut: ['command', 'shift', '@'],
    component: DueDateTool,
};

export default {
    TEXT_STYLES,
    TEXT_BLOCK_STYLES,
    TEXT_BOLD,
    TEXT_ITALIC,
    TEXT_STRIKETHROUGH,
    TEXT_BULLET_LIST,
    TEXT_ORDERED_LIST,
    TEXT_CENTERED,
    TEXT_HYPERLINK,

    TABLE_CELL_ALIGNMENT,
    TABLE_CELL_FORMULA,
    TABLE_CELL_TYPE,
    TABLE_CELL_COLOR,
    TABLE_ADD_COLUMN,
    TABLE_ADD_ROW,

    ASSIGNMENT,
    INDENT_INCREASE,
    INDENT_DECREASE,
    DUE_DATE,
} as const;
