// Lib
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Components
import { Icon } from '../../../dependencies';

// Styles
import './Checkbox.scss';

const Checkbox = (props) => {
    const { error, checked, ...otherProps } = props;

    const [animate, setAnimate] = React.useState(false);
    const [initiallyChecked] = React.useState(props.checked);

    React.useEffect(() => {
        if (props.checked !== initiallyChecked) setAnimate(true);
    }, [props.checked]);

    return (
        <div contentEditable={false} className={classNames('Checkbox', { error, checked, animate })} {...otherProps}>
            <Icon name="checkbox-animated" />
        </div>
    );
};

Checkbox.propTypes = {
    onMouseDown: PropTypes.func,
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    error: PropTypes.bool,
};

export default Checkbox;
