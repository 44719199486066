// Lib
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { EditorContent } from '@tiptap/react';

// Utils
import { getCaption } from '../../../../common/elements/utils/elementPropertyUtils';
import { getCaptionEditorId, getCaptionEditorKey } from '../../../element/utils/elementEditorUtils';

// Hooks
import useClientElementTiptapEditor from '../../tiptapEditor/useClientElementTiptapEditor';
import { useSuggestionsManager } from '../../../../common/tiptap/extensions/mention/suggestionsManager';

// Extensions
import useCaptionTiptapEditorExtensions from './useCaptionTiptapEditorExtensions';

// Components
import { DraftJsConversionIndicator } from '../../../../common/tiptap/conversion/previewComponents/DraftJsConversionIndicator';

// Types
import { CaptionProps } from '../captionComponentTypes';
import { TiptapContent } from '../../../../common/tiptap/tiptapTypes';

// Styles
import './CaptionTiptapEditor.scss';

type CaptionTiptapEditorProps = Omit<CaptionProps, 'textContent'> & {
    textContent: TiptapContent | null;
};

const CaptionTiptapEditor = (props: CaptionTiptapEditorProps): ReactElement => {
    const {
        element,
        textContent,
        currentEditorKey,
        isEditable,
        isEditing,
        isSingleSelected,
        startEditing,
        saveCaptionContent,
        onEmptyBackspace,
        placeholder,
    } = props;

    const dispatch = useDispatch();

    const editorId = getCaptionEditorId(props);
    const editorKey = getCaptionEditorKey(props);

    const [suggestionsManager, mentionsPopup] = useSuggestionsManager();
    const extensions = useCaptionTiptapEditorExtensions(
        placeholder,
        onEmptyBackspace,
        saveCaptionContent,
        suggestionsManager,
        isEditable,
        dispatch,
    );

    const { editor, onMouseDown, onClick } = useClientElementTiptapEditor({
        persistedContent: textContent,
        extensions,

        editorId,
        editorKey,
        currentEditorKey,

        isEditable: !!isEditable,
        isEditing: !!isEditing,
        isSingleSelected: !!isSingleSelected,

        startEditing,
        saveContent: saveCaptionContent,
    });

    return (
        <div className="CaptionTiptapEditorWrapper">
            {mentionsPopup}
            <DraftJsConversionIndicator element={element} textContent={getCaption(element)} />
            <EditorContent
                className="CaptionTiptapEditor"
                editor={editor}
                onMouseDown={onMouseDown}
                onClick={onClick}
            />
        </div>
    );
};

export default CaptionTiptapEditor;
