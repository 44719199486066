// Utils
import { isCard } from '../../../../../../../common/elements/utils/elementTypeUtils';
import { getColorCssValue, getCssPropertyForTextOnColor } from '../../../../../../element/utils/elementColorStyleUtils';
import { getBackgroundColor, getSecondaryColor } from '../../../../../../../common/elements/utils/elementPropertyUtils';

// Types
import { ImMNElement } from '../../../../../../../common/elements/elementModelTypes';

/**
 * Get default text and background colors for an element, so that we can show
 * the text colour on top of the actual background colour that it will be on.
 */
export const getElementDefaultColors = (element: ImMNElement) => {
    // Currently the only elements that have both background colours and text tools are cards
    // (and table cells, but that can be added at a later date).
    // sketch captions also have text tools, but the background colour isn't applied to the caption itself.
    if (!isCard(element)) return null;

    // If card has no background color, return null and use default values
    const backgroundColor = getBackgroundColor(element);
    if (!backgroundColor) return null;

    const backgroundColorCss = getColorCssValue(backgroundColor);
    const secondaryColor = getSecondaryColor(element);

    if (secondaryColor) {
        const secondaryColorCss = getColorCssValue(secondaryColor);
        return { defaultTextColor: secondaryColorCss, defaultBackgroundColor: backgroundColorCss };
    }

    // If card has no secondary color, use default text color based on background color
    const defaultTextColor = getCssPropertyForTextOnColor(backgroundColor, true);
    return { defaultTextColor, defaultBackgroundColor: backgroundColorCss };
};
