// Selectors
import { getEditorId, getEditorState } from '../reducers/editorStoreSelector';

// Actions
import { editorSelectionSave } from '../editorActions';

// Types
import { SelectionState } from 'draft-js';

export const getEditorStateThunk = () => (_: Function, getState: Function) => getEditorState(getState());
export const getEditorIdThunk = () => (_: Function, getState: Function) => getEditorId(getState());
export const saveEditorSelection = (editorId: string, selection: SelectionState) =>
    editorSelectionSave({ editorId, selection });
