// Lib
import { useRef } from 'react';

// Hooks
import useModalSheetInitialisation from './useModalSheetInitialisation';
import useModalSheetHandlers, { ModalSheetHandlers } from './useModalSheetHandlers';
import useModalSheetSnapPointState from './useModalSheetSnapPointState';

// Utils
import { runAfterNextTransitionEnds } from '../../../../utils/dom/domEventUtil';

// Types
import { MobileModalSheetProps } from '../MobileModalSheet';

export const MAX_SHEET_HEIGHT = 0.95;
export const MAX_SUFFIX = 'max';

export interface ModalSheetDragState extends ModalSheetHandlers {
    sheetContentRef: React.RefObject<HTMLDivElement>;
    sheetRef: React.RefObject<HTMLDivElement>;
    sheetOverlayRef: React.RefObject<HTMLDivElement>;
    snapPointsState: number[];
    isOpen: boolean;
    closeSheet: () => void;
}

const useModalSheetDragState = (props: MobileModalSheetProps): ModalSheetDragState => {
    const {
        snapPoints = [],
        defaultSnapPoint,
        isOpen,
        setIsOpen,
        activeSnapPoint,
        setActiveSnapPoint,
        onSheetClose,
        dismissible = true,
        onCloseTransitionEnd,
    } = props;

    const sheetOverlayRef = useRef<HTMLDivElement>(null);
    const sheetRef = useRef<HTMLDivElement>(null);
    const sheetContentRef = useRef<HTMLDivElement>(null);

    const { snapPointsState, goToSnapPoint, addSnapPoint, updateActiveSnapPoint } = useModalSheetSnapPointState(
        snapPoints,
        sheetRef,
        activeSnapPoint,
        setActiveSnapPoint,
        dismissible,
    );

    const closeSheet = () => {
        if (!dismissible) return;

        onSheetClose?.();

        // Transition sheet and overlay to closed state
        sheetOverlayRef.current?.classList.remove('overlay-visible');

        if (!sheetRef.current) return;

        updateActiveSnapPoint(0, true);

        // Remove the sheet components after the transitions have finished
        runAfterNextTransitionEnds(sheetRef.current, () => {
            onCloseTransitionEnd?.();
            setIsOpen(false);
        });
    };

    const sheetInitialised = useModalSheetInitialisation(
        isOpen,
        sheetRef,
        sheetOverlayRef,
        defaultSnapPoint,
        addSnapPoint,
        updateActiveSnapPoint,
        closeSheet,
    );

    const handlers = useModalSheetHandlers(
        activeSnapPoint,
        sheetRef,
        sheetContentRef,
        sheetInitialised,
        snapPointsState,
        goToSnapPoint,
        updateActiveSnapPoint,
        closeSheet,
    );

    return {
        ...handlers,
        sheetContentRef,
        sheetRef,
        snapPointsState,
        isOpen,
        closeSheet,
        sheetOverlayRef,
    };
};

export default useModalSheetDragState;
