import React from 'react';
import MobileModalSheet, {
    CloseButton,
    Content,
    Handle,
    Header,
    Heading,
    Overlay,
    Sheet,
} from '../../../../mobile/structural/modalSheet/MobileModalSheet';

type MobileToolbarSheetProps = {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    activeSnapPoint: number;
    setActiveSnapPoint: (activeSnapPoint: number) => void;
    onSheetClose: () => void;
    children: React.ReactNode;
    onCloseTransitionEnd: () => void;
};

const MobileToolbarSheet = (props: MobileToolbarSheetProps) => {
    const { children, ...rest } = props;

    return (
        <MobileModalSheet defaultSnapPoint={'0.5max'} snapPoints={[0.9]} {...rest}>
            <Overlay />
            <Sheet>
                <Header>
                    {/*<Handle />*/}
                    <Heading>Text style</Heading>
                    <CloseButton />
                </Header>
                <Content>{children}</Content>
            </Sheet>
        </MobileModalSheet>
    );
};

export default MobileToolbarSheet;
