import { RawDraftEntity } from 'draft-js';
import { TiptapContentMark } from '../../tiptapTypes';
import { draftMentionToTiptapMention } from '../../extensions/mention/tiptapDraftConversion';

type EntityToMarkConverter = (entity: RawDraftEntity) => TiptapContentMark;

const entityToMarkConverters: Record<string, EntityToMarkConverter> = {
    link: (entity) => ({
        type: 'link',
        attrs: {
            href: entity.data?.url,
            target: '_blank',
            rel: 'noopener noreferrer nofollow',
            class: null,
        },
    }),
    mention: (entity) => ({
        type: 'mention',
        attrs: draftMentionToTiptapMention(entity.data.mention),
    }),
    default: (entity) => ({
        type: entity.type,
        attrs: entity.data,
    }),
};

export const convertEntityToMark = (entity: RawDraftEntity): TiptapContentMark => {
    const type = entity.type?.toLowerCase();
    const entityConverter = entityToMarkConverters[type] || entityToMarkConverters.default;
    return entityConverter(entity);
};
