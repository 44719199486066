// Lib
import { mergeAttributes, Node } from '@tiptap/react';

// Constants
import { DEFAULT_TIPTAP_PARSE_HTML_PRIORITY } from '../tiptapTypes';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        smallText: {
            /**
             * Sets a node to be small text
             * @example editor.commands.setSmallText()
             */
            setSmallText: () => ReturnType;
            /**
             * Toggle a small text node
             * @example editor.commands.toggleSmallText()
             */
            toggleSmallText: () => ReturnType;
        };
    }
}

/**
 * Defines a node for small text.
 */
export const SmallText = Node.create({
    name: 'smallText',

    content: 'text*',

    group: 'block',

    code: false,

    // When content is pasted over the entire node, it will retain the small text node
    defining: true,

    parseHTML() {
        return [
            {
                tag: 'p',
                // If a P tag has a class of small-text, it will be considered small text
                getAttrs: (node) => {
                    if (node.style.fontSize === 'small') return null;

                    if (node.classList.contains('small-text')) return null;

                    return false;
                },
                preserveWhitespace: 'full',
                // Ensure this extension is run before the starter kit's parseHTML
                priority: DEFAULT_TIPTAP_PARSE_HTML_PRIORITY + 1,
            },
        ];
    },

    /**
     * Wraps the content in a paragraph and small tag.
     * @example <p><small>This is small text</small></p>
     */
    renderHTML({ HTMLAttributes }) {
        return ['p', mergeAttributes({ class: 'small-text' }, HTMLAttributes), ['small', {}, 0]];
    },

    addCommands() {
        return {
            setSmallText:
                () =>
                ({ commands }) => {
                    return commands.setNode(this.name);
                },
            toggleSmallText:
                () =>
                ({ commands }) => {
                    return commands.toggleNode(this.name, 'paragraph');
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            'Mod-Shift-9': () => this.editor.commands.toggleSmallText(),
            /* eslint-enable @typescript-eslint/naming-convention */
        };
    },
});
