import DefaultStrike from '@tiptap/extension-strike';
import { markInputRule } from '@tiptap/core';

// use a ~single tilde~ as a markdown shortcut (aka input rule)
// (by default tiptap wants ~~two tildes~~ to trigger strikethrough)
const inputRegex = /(?:^|\s)((?:~)((?:[^~]+))(?:~))$/;

export const Strike = DefaultStrike.extend({
    addInputRules() {
        return [
            markInputRule({
                find: inputRegex,
                type: this.type,
            }),
        ];
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            'Mod-Shift-x': () => this.editor.commands.toggleStrike(),
        };
    },
});
