// Lib
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Utils
import { getHasClones, getTextContent } from '../../../common/elements/utils/elementPropertyUtils';
import { getIsFeatureEnabledForCurrentUser } from '../feature/elementFeatureSelector';
import { useElementTiptapContent } from '../../../common/tiptap/conversion/useElementTiptapContent';
import { ExperimentId } from '../../../common/experiments/experimentsConstants';

// Components
import CardEditor from './CardEditor';
import CloneCardEditor from './CloneCardEditor';
import CardTiptapEditor from './tiptap/CardTiptapEditor';

const BasicCardEditor = (props) => <CardEditor textContent={getTextContent(props.element)} {...props} />;

const CardEditorSwitch = (props) => {
    const {
        element,
        isClone,
        currentEditorKey,
        isEditable,
        isEditing,
        isSingleSelected,
        isFocusedForegroundElement,
        saveContent,
        startEditing,
        editorRef,
        moveElementsToTrash,
        createNewCard,
    } = props;

    const allowPreview = useSelector(getIsFeatureEnabledForCurrentUser(ExperimentId.tiptapConversion));
    const textContent = getTextContent(element);
    const tiptapContent = useElementTiptapContent(element, textContent, allowPreview);

    if (tiptapContent) {
        return (
            <CardTiptapEditor
                element={element}
                textContent={tiptapContent}
                currentEditorKey={currentEditorKey}
                isEditable={isEditable}
                isEditing={isEditing}
                isSingleSelected={isSingleSelected}
                isFocusedForegroundElement={isFocusedForegroundElement}
                saveContent={saveContent}
                startEditing={startEditing}
                editorRef={editorRef}
                moveElementsToTrash={moveElementsToTrash}
                createNewCard={createNewCard}
            />
        );
    }

    if (getHasClones(element) || isClone) return <CloneCardEditor {...props} />;

    return <BasicCardEditor {...props} />;
};

CloneCardEditor.propTypes =
    BasicCardEditor.propTypes =
    CardEditorSwitch.propTypes =
        {
            element: PropTypes.object,
            isClone: PropTypes.bool,
            isEditing: PropTypes.bool,
            isEditable: PropTypes.bool,
            isSingleSelected: PropTypes.bool,
            isFocusedForegroundElement: PropTypes.bool,
            currentEditorKey: PropTypes.string,
            editorRef: PropTypes.func,
            saveContent: PropTypes.func,
            startEditing: PropTypes.func,
            moveElementsToTrash: PropTypes.func,
            createNewCard: PropTypes.func,
        };

export default CardEditorSwitch;
