// Lib
import { isString } from 'lodash';

// Utils
import { asObject, ImMap } from '../../utils/immutableHelper';

// Types
import { TiptapContent, TiptapEditorContent } from '../tiptapTypes';

/**
 * Converts content stored on elements to content that can be used by the Tiptap editor.
 */
const convertPersistedTextContentToTiptapContent = (
    textContent?: ImMap<TiptapContent> | TiptapContent | string | null,
): TiptapEditorContent | null => {
    if (!textContent) return null;

    if (isString(textContent)) return textContent;

    return asObject(textContent) as TiptapEditorContent;
};

export default convertPersistedTextContentToTiptapContent;
