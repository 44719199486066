import DefaultOrderedList from '@tiptap/extension-ordered-list';

export const OrderedList = DefaultOrderedList.extend({
    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            // Keeping the original Tiptap shortcut, in case it's standard
            'Mod-Shift-7': () => this.editor.commands.toggleOrderedList(),
            'Mod-Shift-O': () => this.editor.commands.toggleOrderedList(),
        };
    },
});
