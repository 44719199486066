// Lib
import { EditorState, ContentState, Modifier, convertToRaw } from 'draft-js';
import getContentStateFragment from 'draft-js/lib/getContentStateFragment';

// Utils
import { hasAltModifier, hasCommandModifier, hasShiftKey } from '../../../../utils/keyboard/keyboardUtility';
import getFocusEndSelection from '../../customRichUtils/selection/getFocusEndSelection';
import getFocusStartSelection from '../../customRichUtils/selection/getFocusStartSelection';

// Other plugins
import handleReturn from '../editingHelper/handleReturn';

// Constants
import { DraftRemovalDirection, EditorChangeType, HANDLER_RETURN_VALUES } from '../../draftjsConstants';

export default (event, editorState, draftProps) => {
    const { getProps, setEditorState } = draftProps;
    const { saveCurrentContent, onEnter } = getProps();

    // The title will be created by the shortcut handler
    if (hasAltModifier(event)) return HANDLER_RETURN_VALUES.handled;

    // The new task list will be created by the shortcut handler
    if (hasCommandModifier(event)) return HANDLER_RETURN_VALUES.handled;

    // Handle soft-newline
    if (hasShiftKey(event) || !onEnter) return handleReturn()(event, editorState, draftProps);

    const currentContent = editorState.getCurrentContent();
    const hasText = currentContent.hasText();

    const selection = editorState.getSelection();
    const endSelection = getFocusEndSelection(currentContent);

    const caretIsAtEnd = selection.equals(endSelection);
    const caretIsAtBeginning = selection.isCollapsed() && selection.getFocusOffset() === 0;

    // Save the current content before splitting it so that undoing will return to this state.
    // Otherwise - if the task has been created and then split prior to any update actions
    //  undoing will return it to an empty state (because the last action would be going from
    //  nothing to the split string).
    // FIXME: This might cause clobbered updates on the server due to multiple synchronous
    //  ELEMENT_UPDATE actions in quick succession. We should probably prevent this by debouncing
    //  and combining the ELEMENT_UPDATE socket actions, rather than manually trying to avoid it
    //  in code.
    saveCurrentContent();

    // If the caret is at the end or at the beginning, simply create a new task before or after
    // the current task
    if (!hasText || caretIsAtEnd || caretIsAtBeginning) {
        onEnter({
            hasText,
            insertBefore: hasText && caretIsAtBeginning,
            mergeTransactions: false,
        });
        return HANDLER_RETURN_VALUES.handled;
    }

    // Otherwise we need to split the line at the current focus point and move it to the new task
    const endOfSelectionToEndOfLine = endSelection.merge({ anchorOffset: selection.getEndOffset() });
    const startOfSelectionToEndOfLine = endSelection.merge({ anchorOffset: selection.getStartOffset() });

    let updatedContentState = Modifier.removeRange(
        currentContent,
        startOfSelectionToEndOfLine,
        DraftRemovalDirection.forward,
    );

    const selectionAfter = selection.merge({
        anchorOffset: selection.getStartOffset(),
        focusOffset: selection.getStartOffset(),
        isBackward: true,
    });

    updatedContentState = updatedContentState.set('selectionAfter', selectionAfter);

    let updatedEditorState = EditorState.push(editorState, updatedContentState, EditorChangeType.REMOVE_RANGE);
    updatedEditorState = EditorState.acceptSelection(updatedEditorState, selectionAfter);

    // Save the current task editor without the characters to the end of the line
    setEditorState(updatedEditorState);

    // Create new content with the text that was removed from the previous task
    const newTaskContentFragment = getContentStateFragment(currentContent, endOfSelectionToEndOfLine);
    const newTaskContentState = ContentState.createFromBlockArray(
        newTaskContentFragment.toArray(),
        currentContent.getEntityMap(),
    );

    const newTaskSelection = getFocusStartSelection(newTaskContentState);
    const newTaskTextContent = convertToRaw(newTaskContentState);

    // Providing the content here ensures that this update will occur immediately, rather than
    // waiting for the MilanoteEditor's state to get updated and then causing an update.
    // We need the update to occur immediately to ensure that the transaction ID of the new task
    // creation is the same as the update, so an undo operation will do both at once
    saveCurrentContent(updatedEditorState);
    onEnter({
        hasText,
        newTaskTextContent,
        newTaskSelection,
        mergeTransactions: true,
    });

    return HANDLER_RETURN_VALUES.handled;
};
