import registerTask from '../../../common/tasks/index';
import presentationalComponent from './TaskPresentationalWrapper';
import containerComponent from './TaskContainer';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerTask({
        containerComponent,
        presentationalComponent,
        tools: {
            editing: [
                TOOLBAR_TOOLS.COLOR,
                TOOLBAR_TOOLS.TITLE,
                TOOLBAR_TOOLS.DUE_DATE,
                TOOLBAR_TOOLS.ASSIGNMENT,
                TOOLBAR_TOOLS.REACTION,
                TOOLBAR_TOOLS.INDENT_INCREASE,
                TOOLBAR_TOOLS.INDENT_DECREASE,
            ],
            editingRange: TOOL_SETS.TEXT_EDITING_INLINE,
        },
    });
