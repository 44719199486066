// Lib
import React from 'react';
import { ConnectedComponent } from 'react-redux';

// Hooks
import { useTiptapActiveEditorSelector } from '../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Types
import { ToolbarToolProps } from '../../../config/toolDefinitions/toolbarToolTypes';

export const createEditorTypeToggledTool =
    (
        TiptapComponent:
            | React.ComponentClass<ToolbarToolProps, any>
            | React.FC<ToolbarToolProps>
            | ConnectedComponent<any, any>,
        DraftJSComponent:
            | React.ComponentClass<ToolbarToolProps, any>
            | React.FC<ToolbarToolProps>
            | ConnectedComponent<any, any>,
    ) =>
    (props: ToolbarToolProps) => {
        // Determines if there's an active Tiptap editor
        const activeTiptapEditorExists = useTiptapActiveEditorSelector((activeEditor) => !!activeEditor);

        const Component = activeTiptapEditorExists ? TiptapComponent : DraftJSComponent;

        return <Component {...props} />;
    };

export default createEditorTypeToggledTool;
