// Lib
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Dialog } from '@capacitor/dialog';

// Utils
import { navigateToUrl } from '../reducers/navigationActions';

// Check if the URL needs to be hit first and then use the resulting URL (i.e. for redirects)
const shouldResolveUrl = (url: string): boolean => {
    return (
        url.includes('.milanote.com/api/auth/email/verify') || // email verification
        url.startsWith('https://links.milanote.com') || // links in daily digest, notifications, etc
        url.includes('account.milanote.com/subscription-invite#code=') // claim pro account
    );
};

const isBoardUrl = (url: string): boolean => {
    const boardUrlRegex = /^https:\/\/(app|staging).milanote.com\/[a-zA-Z0-9]{14}(\/.*)?\/?$/;
    return boardUrlRegex.test(url);
};

// Check if the URL should just be used as is.
const shouldDirectlyNavigate = (url: string): boolean => {
    return (
        url.includes('/change-password?code=') || // password reset
        isBoardUrl(url)
    );
};

const showInvalidUrlAlert = async (url: string) => {
    await Dialog.alert({
        title: 'Sorry, this page could not be found',
        message: `URL: ${url}`,
    });
};

export default function () {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!Capacitor.isPluginAvailable('App')) return;

        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            const url = event.url;
            if (shouldResolveUrl(url)) {
                if (!Capacitor.isPluginAvailable('CapacitorHttp')) return;
                CapacitorHttp.get({ url }).then((response) => {
                    const resolvedUrl = response.url;
                    dispatch(navigateToUrl(resolvedUrl));
                });
            } else if (shouldDirectlyNavigate(url)) {
                dispatch(navigateToUrl(url));
            } else {
                showInvalidUrlAlert(url);
            }
        });
    }, []);
}
