import { markInputRule } from '@tiptap/core';

import DefaultItalic from '@tiptap/extension-italic';

/**
 * Matches an italic to a _italic_ on input.
 */
export const underscoreInputRegex = /(?:^|\s)(_(?!\s+_)((?:[^_]+))_(?!\s+_))$/;

export const Italic = DefaultItalic.extend({
    addInputRules() {
        return [
            markInputRule({
                find: underscoreInputRegex,
                type: this.type,
            }),
        ];
    },
});
