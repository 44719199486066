// Lib
import React from 'react';
import { Editor } from '@tiptap/react';

/**
 * When the editor starts editing, focus the editor at the end of the content.
 *
 * NOTE: This replicates the current Draft editor behaviour.
 *  This will attempt to be improved in WEB-11127
 */
const useTiptapEditorFocus = (editor: Editor | null, isEditing: boolean) => {
    React.useEffect(() => {
        if (!isEditing) return;

        editor?.commands.focus('end');
    }, [isEditing]);
};

export default useTiptapEditorFocus;
