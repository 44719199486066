// Lib
import { curry, flip, curryN, get, keys, isEmpty as isEmptyLd } from 'lodash/fp';
import * as Immutable from 'immutable';

// Generic immutable Map type — use to create type-safe immutable maps from existing JS type
export interface ImMap<JsObject extends object>
    extends Omit<Immutable.Map<keyof JsObject, JsObject>, 'set' | 'get' | 'delete'> {
    set: <AKeyOfThatJsObject extends keyof JsObject>(
        key: AKeyOfThatJsObject,
        value: Pick<JsObject, AKeyOfThatJsObject>,
    ) => ImMap<JsObject>;
    get: <AKeyOfThatJsObject extends keyof JsObject>(
        key: AKeyOfThatJsObject,
    ) => ImMap<Pick<JsObject, AKeyOfThatJsObject>>;
    delete: <AKeyOfThatJsObject extends keyof JsObject>(
        key: AKeyOfThatJsObject,
        value: Pick<JsObject, AKeyOfThatJsObject>,
    ) => ImMap<JsObject>;
}

type ImmutableObject<K = any, V = unknown> = Immutable.Collection<K, V>;
export type ImmutableMap = Immutable.Map<string, any>;

export type ImmutableOrPoJO = ImmutableObject | { [key: string]: any };
export type ImmutableOrArray<T = unknown> = ImmutableObject<number, T> | T[];

const isImmutableObject = (input: unknown): input is Immutable.Iterable<any, any> =>
    Immutable.Iterable.isIterable(input);

// Performance optimisation as this is very "hot" code. Lodash's implementation is much slower and unneeded for us.
const isFunction = (input: any) => typeof input === 'function';

/**
 * Retrieves a property from an object.  The object can be a POJO or an Immutable object.
 */
export const prop = curry((propName: string | number, object: unknown) => {
    if (!object) return object;

    if (isImmutableObject(object)) return object.get(propName);
    if (Array.isArray(object)) return object[propName as number];

    return (object as any)?.[propName as keyof typeof object];
});

export const propIn = curry((propArray: (string | number)[], object: unknown) => {
    if (!object) return object;

    if (isImmutableObject(object)) return object.getIn(propArray);

    return get(propArray, object);
});

/**
 * Same as prop but takes the arguments in the reverse order.
 */
export const propFrom = curryN(2, flip(prop));

export const getKeys = (object: ImmutableOrPoJO): string[] => {
    if (!object) return [];

    if (isFunction(object.keys)) return [...object.keys()];

    return keys(object);
};

export const getMany = (keysToGet: string[], map: ImmutableMap): ImmutableMap => {
    if (!isImmutableObject(map)) return map;

    return Immutable.Map().withMutations((mutableState) =>
        keysToGet.forEach((key) => {
            const entry = map.get(key);
            if (entry !== null) {
                mutableState.set(key, entry);
            }
        }),
    ) as ImmutableMap;
};

export const toIdArray = (immutableMap: ImmutableMap): string[] => immutableMap.keySeq().toArray();

export const length = (obj: ImmutableOrArray | undefined | null | unknown): number => {
    if (!obj) return 0;

    if (isImmutableObject(obj)) return obj.size;
    if (Array.isArray(obj)) return obj.length;
    return 0;
};
export const objectSize = (obj: ImmutableOrPoJO): number => {
    if (!obj) return 0;

    return obj.size !== undefined ? obj.size : Object.keys(obj).length;
};

export const isEmpty = (obj: ImmutableOrPoJO): boolean => !obj || (obj.isEmpty ? obj.isEmpty() : isEmptyLd(obj));

export const asObject = <T>(obj: ImmutableOrPoJO): T => {
    if (!obj) return obj;
    if (obj.toJS) return obj.toJS();
    return obj as T;
};

export const asShallowObject = (obj: ImmutableOrPoJO): object => {
    if (!obj) return obj;

    if (obj.toObject) return obj.toObject();

    return obj;
};

/**
 * NOTE: This only converts an immutable object to an array, if it's not an immutable object it does nothing.
 */
export const toArray = <T>(obj: ImmutableOrArray | undefined | null | unknown): T[] | undefined | null => {
    if (!obj) return obj as T[] | undefined | null;
    if (Array.isArray(obj)) return obj as T[];

    if (isImmutableObject(obj) && obj.toArray) return obj.toArray() as T[];

    return [];
};

// TODO Untested
export const safeForEach = (
    fn: (value: unknown, index?: number | string, array?: ImmutableOrArray) => void,
    obj: ImmutableOrArray,
): void => {
    if (Array.isArray(obj)) return obj.forEach(fn);

    Object.entries(obj).forEach(([key, value]) => {
        fn(value, key, obj);
    });
};

// TODO: Type or remove
export const safeReduce = (fn: any, initialVal: any, obj: any) => {
    if (obj.reduce) return obj.reduce(fn, initialVal);

    let acc = initialVal;

    for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            const val = obj[key];
            acc = fn(acc, val, key, obj);
        }
    }

    return acc;
};
