// Lib
import React, { ReactElement, useCallback } from 'react';
import classNames from 'classnames';

// Components
import Icon from '../../../../../../components/icons/Icon';
import ShortcutKeyGroup from '../../../../../shortcuts/ShortcutKeyGroup';

// Styles
import './TextStyleEntry.scss';

export type TextStyleEntryBlockClassnames = 'header-one' | 'header-two' | 'small-text' | 'code-block' | 'blockquote';

interface TextStyleEntryProps {
    active?: boolean;
    blockClassname?: TextStyleEntryBlockClassnames;
    shortcut: string[];
    onTextStyleSelect: () => void;
    children: string;
}

const TextStyleEntry = (props: TextStyleEntryProps): ReactElement => {
    const { active, blockClassname, shortcut, onTextStyleSelect, children } = props;

    const onPointerDown = useCallback((event: React.PointerEvent) => {
        event.preventDefault();
        onTextStyleSelect();
    }, []);

    return (
        <div className={classNames('TextStyleEntry', { active })} onPointerDown={!active ? onPointerDown : undefined}>
            <div className={`block-style-text ${blockClassname}`}>{children}</div>
            {active ? <Icon name="block-style-tick" /> : <ShortcutKeyGroup shortcut={shortcut} preferSymbol />}
        </div>
    );
};

export default TextStyleEntry;
