import DefaultTextAlign from '@tiptap/extension-text-align';
import { Editor } from '@tiptap/react';
import { objectIncludes, NodeRange, combineTransactionSteps } from '@tiptap/core';
import { Node, NodeSpec } from '@tiptap/pm/model';
import { PluginKey, Plugin, EditorState, Transaction } from '@tiptap/pm/state';
import { ReplaceAroundStep } from '@tiptap/pm/transform';
import memoizeOne from 'memoize-one';

import { ALIGNMENT_SUPPORTED_NODE_TYPES, TiptapAlignments } from '../tiptapTypes';

/**
 * Determines the node types that are allowed to have the textAllign attribute on them.
 */
const getSupportedNodeTypeNames = memoizeOne((editor: Editor): string[] => {
    if (!editor) return [];

    const { schema } = editor;

    const supportedTypes: string[] = [];

    Object.entries(schema.nodes).forEach(([name, nodeType]) => {
        if (!(nodeType as NodeSpec).attrs?.textAlign) return;
        supportedTypes.push(name);
    });

    return supportedTypes;
});

/**
 * Finds the selected nodes that can be center aligned.
 */
const getAlignableSelectedNodeRanges = (state: EditorState | undefined, supportedTypeNames: string[]): NodeRange[] => {
    if (!state) return [];

    const { from, to } = state.selection;

    const alignableSelectedNodes: NodeRange[] = [];

    state.doc.nodesBetween(from, to, (node, pos) => {
        if (node.isText) return false;

        if (!supportedTypeNames.includes(node.type.name)) return false;

        alignableSelectedNodes.push({
            node,
            from: pos,
            to: pos + node.nodeSize,
        });

        // Only want to check top level nodes, so return false to prevent recursion.
        return false;
    });

    return alignableSelectedNodes;
};

/**
 * Finds the selected nodes that can be center aligned.
 */
const getAlignableSelectedNodes = (editor: Editor): Node[] => {
    const supportedTypeNames = getSupportedNodeTypeNames(editor);
    return getAlignableSelectedNodeRanges(editor.state, supportedTypeNames).map((nodeRange) => nodeRange.node);
};

/**
 * We need to filter out nodes that shouldn't be centred, such as lists, when
 * determining if the text is centred.
 */
export const isCenteredTextAlignActive = (editor?: Editor | null) => {
    if (!editor) return false;

    const alignableSelectedNodes = getAlignableSelectedNodes(editor);

    return (
        !!alignableSelectedNodes.length &&
        alignableSelectedNodes.every((node) =>
            objectIncludes(node.attrs, { textAlign: TiptapAlignments.center }, { strict: false }),
        )
    );
};

/**
 * This function gets executed after any transaction is applied to the document.
 *
 * It is responsible for maintaining the text-align attribute when switching between block types, or
 * removing it when switching to a block type that doesn't support it.
 *
 * It does so by examining the steps in the transaction, and looking for ReplaceAroundSteps, then
 * adding a step to add or remove the text-align attribute as necessary.
 */
const appendTransaction =
    (supportedTypeNames: string[]) =>
    (transactions: readonly Transaction[], oldState: EditorState, newState: EditorState) => {
        const hasDocChanges =
            transactions.some((transaction) => transaction.docChanged) && !oldState.doc.eq(newState.doc);

        if (!hasDocChanges) return null;

        const isUndoRedo = transactions.some((transaction) => transaction.getMeta('history$'));

        if (isUndoRedo) return null;

        const { tr } = newState;
        let updatedTr: Transaction | null = null;

        const transform = combineTransactionSteps(oldState.doc, [...transactions]);

        const replaceAroundStepArray = transform.steps.filter(
            (step) => step instanceof ReplaceAroundStep,
        ) as ReplaceAroundStep[];

        if (replaceAroundStepArray.length === 0) return null;

        // Determine at the start if any steps are changing to a node that doesn't support text-align
        // If so, we can skip any intermediate steps that might switch between
        // This was due to a bug where the mapping to keep the text-align attribute was throwing
        //  and error after a blockquote was added
        const noLongerAllowsTextAlign = replaceAroundStepArray.some((replaceAroundStep) => {
            const newNode = replaceAroundStep.slice.content.firstChild;
            return newNode && !supportedTypeNames.includes(newNode.type.name);
        });

        for (const replaceAroundStep of replaceAroundStepArray) {
            if (replaceAroundStep.slice.content.childCount !== 1) continue;

            const map = replaceAroundStep.getMap();
            const invertedMap = map.invert();

            const newNode = replaceAroundStep.slice.content.firstChild;
            const oldNode = oldState.doc.nodeAt(invertedMap.map(replaceAroundStep.from));

            if (!newNode || !oldNode) continue;

            const newNodeSupportsTextAlign = supportedTypeNames.includes(newNode.type.name);
            const oldNodeSupportsTextAlign = supportedTypeNames.includes(oldNode.type.name);

            // If the old node doesn't support text align, then we don't need to do anything
            if (!oldNodeSupportsTextAlign) continue;

            // If the new node doesn't support text align, then we need to remove the text align
            if (!newNodeSupportsTextAlign) {
                updatedTr = updatedTr || tr;

                // We need to do a search in the _new_ document, thus we need to map the positions
                const mappedFrom = replaceAroundStep.getMap().map(replaceAroundStep.from);
                const mappedTo = replaceAroundStep.getMap().map(replaceAroundStep.to);

                updatedTr.doc.nodesBetween(mappedFrom, mappedTo, (node, pos) => {
                    if (node.isText) return false;

                    // Continue traversing until we find a node that supports text align
                    if (!supportedTypeNames.includes(node.type.name)) return true;

                    // Remove the text align attribute as it now exists within a node that doesn't support it
                    updatedTr = updatedTr!.setNodeMarkup(pos, undefined, {
                        ...node.attrs,
                        textAlign: undefined,
                    });

                    return false;
                });

                continue;
            }

            // Early exit to avoid an error when changing from small text to a blockquote
            if (noLongerAllowsTextAlign) continue;

            // Otherwise, we might need to copy the text align from the old node to the new node

            // If we have a "textAlignSentinel" on the new node - then the change wasn't as a result
            // of a block-type change (as it's undefined by default), so we can ignore it
            if (newNode.attrs.textAlignSentinel) continue;

            // If the text align matches what it used to be, then we don't need to sync anything
            if (oldNode.attrs.textAlign === newNode.attrs.textAlign) continue;

            updatedTr = updatedTr || tr;
            const mappedFrom = updatedTr.mapping.map(replaceAroundStep.from);

            updatedTr = updatedTr!.setNodeMarkup(mappedFrom, undefined, {
                ...newNode.attrs,
                textAlign: oldNode.attrs.textAlign,
            });
        }

        return updatedTr;
    };

export const TextAlign = DefaultTextAlign.extend({
    /**
     * To be able to accurately detect when a change is due to setting the text-align attribute,
     * or simply due to changing the block type - we use the "textAlignSentinel" attribute.
     * If it's not present, then we can assume that the change was due to a block type change.
     */
    addGlobalAttributes() {
        const parentGlobalAttributes = this.parent?.() || [];
        return [
            ...(this.parent?.() || []),
            {
                types: (parentGlobalAttributes[0]?.types || []) as string[],
                attributes: {
                    textAlignSentinel: {
                        default: undefined,
                    },
                },
            },
        ];
    },

    addCommands() {
        return {
            ...this.parent?.(),
            unsetTextAlign:
                () =>
                ({ commands }) => {
                    return this.options.types
                        .map((type) =>
                            commands.updateAttributes(type, {
                                textAlign: this.options.defaultAlignment,
                                // This is the only change from the original implementation
                                // We need to set this so the appendTransaction function can differentiate
                                // between a block type change and a text-align change
                                textAlignSentinel: true,
                            }),
                        )
                        .every((response) => response);
                },
            setTextAlign:
                (alignment) =>
                ({ tr, dispatch }) => {
                    if (!this.options.alignments.includes(alignment)) return false;

                    const supportedTypeNames = getSupportedNodeTypeNames(this.editor);
                    const alignableSelectedNodeRanges = getAlignableSelectedNodeRanges(
                        this.editor.state,
                        supportedTypeNames,
                    );

                    if (!alignableSelectedNodeRanges.length) return false;

                    // The command is being run as a "can" check, and there are alignable nodes, so return true
                    if (!dispatch) return true;

                    alignableSelectedNodeRanges.forEach((nodeRange) => {
                        tr.setNodeMarkup(nodeRange.from, undefined, {
                            ...nodeRange.node.attrs,
                            textAlign: alignment,
                            textAlignSentinel: true,
                        });
                    });

                    return true;
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            'Mod-\\': () => {
                return isCenteredTextAlignActive(this.editor)
                    ? this.editor.commands.unsetTextAlign()
                    : this.editor.commands.setTextAlign(TiptapAlignments.center);
            },
            /* eslint-enable @typescript-eslint/naming-convention */
        };
    },

    addProseMirrorPlugins() {
        const supportedTypeNames = getSupportedNodeTypeNames(this.editor);

        return [
            new Plugin({
                key: new PluginKey('removeTextAlign'),
                // Ensure that the text-align attribute is maintained when switching between block types
                // that support it (e.g. paragraph -> heading), and removed when switching to a block type
                // that doesn't (e.g. paragraph -> bullet_list).
                appendTransaction: appendTransaction(supportedTypeNames),
            }),
        ];
    },
}).configure({
    types: ALIGNMENT_SUPPORTED_NODE_TYPES,
});
