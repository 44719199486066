// Lib
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

// Utils
import { propIn } from '../../../../common/utils/immutableHelper';

// Selector
import { getElementLocalData } from '../../local/elementLocalDataSelector';

const getLocalEditorState = propIn(['editorState']);

// TODO-TIPTAP: This appears to be draft-specific and should be removed/updated
const mapStateToProps = createSelector(getElementLocalData, (elementLocalData) => ({
    localEditorState: getLocalEditorState(elementLocalData),
}));

export default connect(mapStateToProps);
