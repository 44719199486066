import { Extension } from '@tiptap/core';

// Utils
import { isTiptapEditorEmpty } from '../utils/isTiptapEditorEmpty';
import { Editor } from '@tiptap/react';

const WARNING_MESSAGE = `Tiptap.EmptyDelete: The Milanote "EmptyDelete" extension was triggered without a configured handler.

    When using the EmptyDelete extension, it should be configured with an onEmptyDelete handler. E.g:
    
    EmptyDelete.configure({
        onEmptyDelete: () => {
            console.log('Handling onEmptyDelete');
        },
    });
`;

export interface EmptyDeleteOptions {
    /**
     * The function that will get invoked if the editor is empty
     * when the "delete" or "backspace" key is pressed.
     *
     * It should return `true` if it's handled, `false` otherwise.
     */
    onEmptyDelete: (editor: Editor) => boolean;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        emptyDelete: {
            /**
             * Invokes
             * @example editor.commands.checkIfEmptyDelete()
             */
            checkIfEmptyDelete: () => ReturnType;
        };
    }
}

export const EmptyDelete = Extension.create<EmptyDeleteOptions>({
    name: 'emptyDelete',

    addOptions() {
        return {
            onEmptyDelete: (editor: Editor) => {
                console.warn(WARNING_MESSAGE);
                return false;
            },
        };
    },

    addCommands() {
        return {
            checkIfEmptyDelete:
                () =>
                ({ commands, chain, state, editor }) => {
                    const { onEmptyDelete } = this.options;

                    if (!isTiptapEditorEmpty(editor)) return false;

                    return onEmptyDelete(editor);
                },
        };
    },

    addKeyboardShortcuts() {
        const checkIfEmptyDelete = () => this.editor.commands.checkIfEmptyDelete();

        return Object.fromEntries(
            [
                'Backspace',
                'Shift-Backspace',
                'Alt-Backspace',
                'Control-Backspace',
                'Cmd-Backspace',
                'Delete',
                'Shift-Delete',
                'Alt-Delete',
                'Control-Delete',
                'Cmd-Delete',
            ].map((shortcut) => [shortcut, checkIfEmptyDelete]),
        );
    },
});
