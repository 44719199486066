import DefaultLink, { LinkOptions } from '@tiptap/extension-link';
import { markInputRule } from '@tiptap/core';
import { togglePopup } from '../../../client/components/popupPanel/popupActions';
import { PopupIds } from '../../../client/components/popupPanel/popupConstants';

interface MilanoteLinkOptions extends LinkOptions {
    dispatch: Function;
    isEditable: boolean;
}

const toggleHyperlinkPopup = togglePopup(PopupIds.HYPERLINK);

/*
 * Take a user-entered URL and try to make it something clickable.
 */
export function createUsableUrl(text: string): string {
    try {
        // If the URL is invalid, this will throw an error
        return new URL(text).toString();
    } catch (e) {
        try {
            // Try adding a protocol to the URL to fix it - this will cover most cases
            return new URL(`https://${text}`).toString();
        } catch (e) {
            // If the URL is still invalid, just use the original text
            // It'll probably be a broken link, but we'll leave that to the user to fix
            return text;
        }
    }
}

// Capture markdown link syntax - ie, [link text](link url)
// allowing for anything but a "]" in the text, and anything but a ")" in the url
const inputRegex = /\[([^\]]+)\]\(([^\)]+)\)$/;

export const Link = DefaultLink.extend<MilanoteLinkOptions>({
    addOptions() {
        return {
            ...(this.parent?.() as LinkOptions),
            // this only applies while the control is being edited
            // TODO-TIPTAP: this still opens the link when the control is selected but not edited
            openOnClick: false,
            dispatch: () => {},
            isEditable: false,
        };
    },

    addInputRules() {
        return [
            markInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes(match) {
                    // match contains [whole match, link text, link url]
                    // The return object is the attrs of the resulting node, but tiptap will also
                    // read the match array and use the last element in it as the link text.
                    // So, we pop the url from the match array rather than just reading it:
                    const url = match.pop()?.trim() || '';
                    const href = createUsableUrl(url);
                    return { href };
                },
            }),
        ];
    },

    /**
     * Opens the hyperlink popup when the user presses Cmd+K.
     */
    addKeyboardShortcuts() {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Mod-k': ({ editor }) => {
                const { isEditable, dispatch } = this.options;

                if (!isEditable) return false;
                if (!dispatch) return false;

                dispatch(toggleHyperlinkPopup);

                return true;
            },
        };
    },
});
