import { Extension } from '@tiptap/core';
import { LinkOptions } from '@tiptap/extension-link';
import { UniversalCardConverterCommand } from './universalCardTypes';
import { CommandProps } from '@tiptap/react';

// Utils
import { attemptLinkConversion } from './link';
import { attemptTaskListConversion } from './task';
import { attemptColorSwatchConversion } from './swatch';

// Actions
import { DEFAULT_TIPTAP_EXTENSION_PRIORITY } from '../../tiptapTypes';

interface UniversalCardOptions extends LinkOptions {
    dispatch: Function;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        universalCard: {
            switchToLinkElement: () => ReturnType;
            switchToSwatchElement: () => ReturnType;
            switchToTaskListElement: () => ReturnType;
        };
    }
}

/**
 * This extension is used to switch to different element types if
 * the user adds specific content.
 * Examples:
 * - If the user types a URL, switch to a "Link"
 * - If the user types a colour hex code, switch to a "Swatch"
 * - If the user types a '[] ', switch to a "TaskList"
 */
export const UniversalCard = Extension.create<UniversalCardOptions>({
    name: 'universalCard',

    priority: DEFAULT_TIPTAP_EXTENSION_PRIORITY + 10,

    addOptions() {
        return {
            ...this.parent?.(),
            dispatch: () => {},
        };
    },

    addCommands() {
        const attemptConversion = (converter: UniversalCardConverterCommand) => () => (props: CommandProps) => {
            const { commands } = props;

            const action = converter(props);
            if (!action) return false;

            commands.cancelDebouncedUpdate();

            this.options.dispatch(action);

            return true;
        };

        return {
            switchToLinkElement: attemptConversion(attemptLinkConversion),
            switchToSwatchElement: attemptConversion(attemptColorSwatchConversion),
            switchToTaskListElement: attemptConversion(attemptTaskListConversion),
        };
    },

    addKeyboardShortcuts() {
        return {
            /* eslint-disable @typescript-eslint/naming-convention */
            Enter: () =>
                this.editor.commands.first([
                    this.editor.commands.switchToLinkElement,
                    this.editor.commands.switchToSwatchElement,
                    this.editor.commands.switchToTaskListElement,
                ]),
            Space: () => this.editor.commands.switchToTaskListElement(),
            /* eslint-enable @typescript-eslint/naming-convention */
        };
    },
});
