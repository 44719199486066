// Lib
import React, { ReactElement } from 'react';

// Hooks
import {
    useActiveTiptapEditorCallback,
    ActiveTiptapEditorSelector,
    useTiptapActiveEditorSelector,
} from '../../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Components
import TextColorButton from './TextColorButton';

const isTextColorButtonActive =
    (color: string): ActiveTiptapEditorSelector =>
    (editor) =>
        editor?.isActive('textStyle', { color });

interface TiptapTextColorButtonProps {
    color: string;
    customTextColor?: string | null;
    customBackgroundColor?: string | null;
}

const TiptapTextColorButton = (props: TiptapTextColorButtonProps): ReactElement => {
    const { color, customTextColor, customBackgroundColor } = props;

    const active = useTiptapActiveEditorSelector(isTextColorButtonActive(color));

    const onTextColorClick = useActiveTiptapEditorCallback((activeEditor) => {
        activeEditor?.chain().focus().setColor(color).run();
    });

    return (
        <TextColorButton
            active={active || false}
            color={color}
            className={`text-${color}`}
            onSelectTextColor={onTextColorClick}
            customTextColor={customTextColor}
            customBackgroundColor={customBackgroundColor}
        />
    );
};

export default TiptapTextColorButton;
