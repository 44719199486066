// Lib
import React from 'react';
import { Route, Redirect, IndexRedirect, IndexRoute } from '../../node_module_clones/react-router';
import loadable from 'react-loadable';

import AppInitContainer from './AppInitContainer';
import CurrentBoardIdSynchroniser from './CurrentBoardIdSynchroniser';

import RegisterSuccessRedirect from '../auth/RegisterSuccessRedirect';
import LoginSuccessRedirect from '../auth/LoginSuccessRedirect';
import OnboardingURLRedirect from '../workspace/onboarding/OnboardingUrlRedirect';
import OnboardingClearRedirect from '../workspace/onboarding/OnboardingClearRedirect';
import ElementModal from '../element/modal/ElementModal';
import SelectGoogleContactsModal from '../compose/selectGoogleContacts/LoadableSelectGoogleContactsModal';
import OnboardingIntroMobile from '../user/account/onboarding/mobile/LoadableOnboardingIntroMobile';
import ComposeModalRoot from '../compose/LoadableComposeModalRoot';
import ComposeEmailModalRoot from '../compose/composeEmail/LoadableComposeEmailModalRoot';

import {
    LoadableAccountDetails,
    LoadableAccountInterface,
    LoadableAccountModalRoot,
    LoadableAccountNotifications,
    LoadableAccountPlan,
    LoadableAccountPlanResubscribeSettings,
    LoadableAccountPlanUnsubscribeSettings,
    LoadableAccountPlanImmediateDowngradeSettings,
    LoadableAccountPreferences,
    LoadableToolbarOptions,
    LoadableAccountSettingsDeleteAccount,
    LoadablePrivacyPreferences,
    LoadableLocalisationSettings,
    LoadableAccountPlanContainer,
    LoadableAccountWorkspaceSettings,
} from '../user/account/accountModal/LoadableAccountModalComponents';

import {
    LoadableCheckoutProductsModal,
    LoadableCheckoutReferModal,
    LoadableCheckoutProductPricingModal,
    LoadableCheckoutSuccessModal,
    LoadableCheckoutProductTeamsModal,
    LoadableCheckoutPaymentModal,
} from '../user/account/checkout/loadableCheckoutComponents';

import CheckoutModal from '../user/account/checkout/CheckoutModal';

import ModalLoader from '../components/modal/ModalLoader';
import Login from '../auth/modals/login/Login';
import AppLogin from '../auth/modals/login/LoadableLogin';
import Register from '../auth/modals/register/LoadableRegister';
import AppRegister from '../auth/modals/register/LoadableAppRegister';
import PublishPreviewInit from '../workspace/preview/PublishPreviewInit';
import EmailVerificationRequiredModal from '../user/account/emailVerification/LoadableEmailVerificationRequiredModal';
import { LoadableRouteShortcutsModal } from '../workspace/shortcuts/modal/LoadableShortcutsModal';
import PageLoader from '../components/loaders/PageLoader';
import MobilePageEmptyLoader from '../mobile/structural/MobilePageEmptyLoader';

// Constants
import { PUBLISH_PREVIEW_PATHNAME } from '../workspace/preview/previewConstants';
import { ELEMENT_MODAL_SUB_PATH } from '../../common/utils/urlConstants';
import { ExperimentId } from '../../common/experiments/experimentsConstants';

const EmptyLoader = () => null;

const AppShellStyled = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "app-shell" */
            './AppShellStyled'
        ),
    loading: EmptyLoader,
});

const InstallPage = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "install-page" */
            '../marketing/InstallPage'
        ),
    loading: EmptyLoader,
});

const RegisterTerms = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "register-terms" */
            '../auth/modals/registerTerms/RegisterTerms'
        ),
    loading: EmptyLoader,
});

const RegisterConfirm = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "register-confirm" */
            '../auth/modals/registerConfirm/RegisterConfirm'
        ),
    loading: EmptyLoader,
});

const ForgottenPasswordModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "forgotten-password" */
            '../auth/modals/forgottenPassword/ForgottenPasswordModal'
        ),
    loading: EmptyLoader,
});

const ChangePasswordModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "change-password" */
            '../auth/modals/changePassword/ChangePasswordModal'
        ),
    loading: EmptyLoader,
});

const EmailUnsubscribeFullscreenModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "workspace" */
            '../user/account/emailUnsubscribeModal/EmailUnsubscribeFullscreenModal'
        ),
    loading: EmptyLoader,
});

const EmailVerificationSuccessModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "email-verification-success" */
            '../user/account/emailVerification/EmailVerificationSuccessModal'
        ),
    loading: EmptyLoader,
});

const EmailVerificationFailureModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "email-verification-failure" */
            '../user/account/emailVerification/EmailVerificationFailureModal'
        ),
    loading: EmptyLoader,
});

const SubscriptionInviteClaimModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "subscription-invite-claim" */
            '../user/account/subscriptionInvite/SubscriptionInviteClaimModal'
        ),
    loading: EmptyLoader,
});

const ReferralRedirect = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "referral-redirect" */
            '../user/account/checkout/refer/ReferralRedirect'
        ),
    loading: EmptyLoader,
});

const WorkspaceInitContainer = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "workspace" */
            '../workspace/WorkspaceInitContainer'
        ),
    loading: PageLoader,
});

const BoardPreviewWorkspaceInitContainer = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "board-preview" */
            '../workspace/boardPreview/BoardPreviewWorkspaceInitContainer'
        ),
    loading: EmptyLoader,
});

const SegmentPickerModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "segment-picker" */
            '../user/account/onboarding/segmentPicker/SegmentPicker'
        ),
    loading: EmptyLoader,
});

const ReportContentModal = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "report-content" */
            '../workspace/contentReport/ReportContentModal'
        ),
    loading: ModalLoader,
});

// Admin dashboard

const AdminDashboard = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-dashboard" */
            '../admin/AdminDashboard'
        ),
    loading: EmptyLoader,
});

const AdminDashboardUserSearch = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-user-search" */
            '../admin/search/AdminDashboardUserSearch'
        ),
    loading: EmptyLoader,
});

const AdminDashboardUserSummary = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-user-summary" */
            '../admin/user/AdminDashboardUserSummary'
        ),
    loading: EmptyLoader,
});

const AdminDashboardIPAddresses = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-ip-address" */
            '../admin/ipAddress/AdminDashboardIPAddresses'
        ),
    loading: EmptyLoader,
});

const AdminDashboardIconManager = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-icon-manager" */
            '../admin/icons/AdminDashboardIconManager'
        ),
    loading: EmptyLoader,
});

const AdminDashboardSubscriptions = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-subscriptions" */
            '../admin/subscriptions/AdminDashboardSubscriptions'
        ),
    loading: EmptyLoader,
});

const AdminDashboardSubscriptionDetail = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-subscription-detail" */
            '../admin/subscriptions/AdminDashboardSubscriptionDetail'
        ),
    loading: EmptyLoader,
});

const AdminDashboardAiModerationReports = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-ai-moderation" */
            '../admin/aiModerationReports/AdminAiModerationReports'
        ),
    loading: EmptyLoader,
});

const AdminDashboardElement = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-element" */
            '../admin/element/AdminDashboardElement'
        ),
    loading: EmptyLoader,
});

const AdminDashboardContentReports = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-content-reports" */
            '../admin/contentReports/AdminDashboardContentReports'
        ),
    loading: EmptyLoader,
});

const AdminDashboardInfluencerReferrals = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-content-reports" */
            '../admin/influencerReferrals/AdminDashboardInfluencerReferrals'
        ),
    loading: EmptyLoader,
});

const AdminDashboardMarketingCampaigns = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-marketing-campaigns" */
            '../admin/marketingCampaigns/AdminDashboardMarketingCampaigns'
        ),
    loading: EmptyLoader,
});

const AdminDashboardProducts = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-products" */
            '../admin/products/AdminDashboardSubscriptionProducts'
        ),
    loading: EmptyLoader,
});

const AdminDashboardSubscriptionAnalytics = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-subscriptions-analytics" */
            '../admin/subscriptions/AdminDashboardSubscriptionAnalytics'
        ),
    loading: EmptyLoader,
});

const AdminDashboardActivityDigest = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-activity-digest" */
            '../admin/activityDigest/AdminDashboardActivityDigest'
        ),
    loading: EmptyLoader,
});

const AdminDashboardExperiments = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "admin-experiments" */
            '../admin/experiments/AdminDashboardExperiments'
        ),
    loading: EmptyLoader,
});

const ClearAppLocalCache = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "app-refresh" */
            '../workspace/localCache/ClearAppLocalCache'
        ),
    loading: EmptyLoader,
});

const OnboardingModalRoot = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "account-onboarding" */
            '../user/account/onboarding/OnboardingModalRoot'
        ),
    loading: EmptyLoader,
});

const ElementModalPreview = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "element-modal-preview" */
            '../element/modal/ElementModalPreview'
        ),
    loading: EmptyLoader,
});

const BoardPreviewWorkspace = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "board-preview-workspace" */
            '../workspace/boardPreview/BoardPreviewWorkspace'
        ),
    loading: EmptyLoader,
});

const MobileSettingsPage = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "mobile-settings-page" */
            '../mobile/settingsPage/MobileSettingsPage'
        ),
    loading: MobilePageEmptyLoader,
});

const MobileSettingsColorSchemePage = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "mobile-settings-color-scheme-page" */
            '../mobile/settingsPage/colorScheme/MobileSettingsColorSchemePage'
        ),
    loading: MobilePageEmptyLoader,
});

const MobileQuickNotesPage = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "mobile-quick-notes-page" */
            '../mobile/quickNotesPage/MobileQuickNotesPage'
        ),
    loading: MobilePageEmptyLoader,
});

const MobileNotificationsPage = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "mobile-notifications-page" */
            '../mobile/notificationsPage/MobileNotificationsPage'
        ),
    loading: MobilePageEmptyLoader,
});

const MobileSearchPage = loadable({
    loader: () =>
        import(
            /* webpackChunkName: "mobile-search-page" */
            '../mobile/searchPage/MobileSearchPage'
        ),
    loading: MobilePageEmptyLoader,
});

/**
 * route.js
 *  - Builds the routes for the application.
 */
export default (
    <Route component={AppInitContainer}>
        <Route path="/shell" component={AppShellStyled} />
        <Route path="/install-desktop-app" component={InstallPage} />
        <Redirect from="/install-windows-app" to="/install-desktop-app" />

        {/* UNAUTHENTICATED ROUTES */}
        {/* auth routes */}
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/register-terms" component={RegisterTerms} />
        <Route path="/register-confirm" component={RegisterConfirm} />
        <Route path="/forgotten-password" component={ForgottenPasswordModal} />
        <Route path="/change-password" component={ChangePasswordModal} />
        <Route path="/register-success" component={RegisterSuccessRedirect} />
        <Route path="/login-success" component={LoginSuccessRedirect} />

        {/* email unsubscribe */}
        <Route path="/unsubscribe" component={EmailUnsubscribeFullscreenModal} />
        <Redirect from="/account/unsubscribe" to="/unsubscribe" />

        {/* email verification */}
        <Route path="/email-verified" component={EmailVerificationSuccessModal} />
        <Route path="/email-verification-failed" component={EmailVerificationFailureModal} />

        {/* referral redirect to register */}
        <Route path="/refer/(:referrer)" component={ReferralRedirect} />

        {/* referral redirect to register */}
        <Route path="/tutorial" component={OnboardingURLRedirect} />

        <Redirect from="/compose" to="/compose/email" />

        {/* Clear local redux cache */}
        <Route path="/app-refresh" component={ClearAppLocalCache} />

        <Route exact path="/preview" component={BoardPreviewWorkspaceInitContainer}>
            <Route path="e/:elementId" component={ElementModalPreview} />
            <Route path=":currentBoardId" component={BoardPreviewWorkspace} />
        </Route>

        <Route path="/admin" component={AdminDashboard}>
            <IndexRedirect to="/admin/users/" />
            <Route path="ip-addresses" component={AdminDashboardIPAddresses} />
            <Route path="subscription/analytics/:planId" component={AdminDashboardSubscriptionDetail} />
            <Route path="subscription" component={AdminDashboardSubscriptions}>
                <IndexRedirect to="/admin/subscription/products" />
                <Route path="products" component={AdminDashboardProducts} />
                <Route path="analytics" component={AdminDashboardSubscriptionAnalytics} />
            </Route>
            <Route path="icons" component={AdminDashboardIconManager} />
            <Route path="content-reports(/:reportId)" component={AdminDashboardContentReports} />
            <Route path="users" sidebar={AdminDashboardUserSearch}>
                <Route path=":userId(/:activeTab)" component={AdminDashboardUserSummary} />
            </Route>
            <Route path="elements/:elementId" component={AdminDashboardElement} />
            <Route path="activity-digest" component={AdminDashboardActivityDigest} />
            <Route path="experiments" component={AdminDashboardExperiments} />
            <Route path="ai-moderation-reports" component={AdminDashboardAiModerationReports} />
            <Route path="influencer-referrals" component={AdminDashboardInfluencerReferrals} />
            <Route path="marketing-campaigns" component={AdminDashboardMarketingCampaigns} />
        </Route>

        <Route path="/" component={WorkspaceInitContainer} manuallyRemoveLoader>
            <Route path="/app-login" component={AppLogin} overlay registerPath="app-register" />
            <Route path="/app-register" component={AppRegister} overlay loginPath="app-login" />

            <Route path="/email-verification-required" component={EmailVerificationRequiredModal} />

            {/* subscription invites */}
            <Route path="/subscription-invite" component={SubscriptionInviteClaimModal} />
            <Redirect from="/subscription-invite/success" to="/subscription-invite#state=success" />
            <Redirect from="/subscription-invite/failure" to="/subscription-invite#state=failure" />

            {/* Checkout V2 */}
            <Route path="/checkout" component={CheckoutModal}>
                <Route path="products" component={LoadableCheckoutProductsModal} />
                <Route path="products/(:productSlug)" component={LoadableCheckoutProductPricingModal} />
                <Route path="members" component={LoadableCheckoutProductTeamsModal} />
                <Route path="refer" component={LoadableCheckoutReferModal} />
                <Route path="success" component={LoadableCheckoutSuccessModal} />
                <Route path="payment" component={LoadableCheckoutPaymentModal} />
            </Route>

            <Route path="/onboarding">
                <Route path="" component={OnboardingModalRoot}>
                    <Route
                        path="mobile"
                        component={OnboardingIntroMobile}
                        disableClose
                        className="wide grey-background"
                    />
                </Route>
                <Route path="segment" component={SegmentPickerModal} disableClose className="wide grey-background" />
                <Route path="onboarding-clear" component={OnboardingClearRedirect} />
            </Route>

            <Route path="/account" component={LoadableAccountModalRoot}>
                <IndexRedirect to="/account/settings" />
                <Route path="settings" name="Account settings">
                    <IndexRoute component={LoadableAccountDetails} />
                    <Route path="delete" component={LoadableAccountSettingsDeleteAccount} />
                </Route>
                <Route path="notifications" component={LoadableAccountNotifications} name="Emails & notifications" />
                <Route path="plan" proOnly webOnly name="Payment & billing" component={LoadableAccountPlanContainer}>
                    <IndexRoute component={LoadableAccountPlan} />
                    <Route path="unsubscribe" component={LoadableAccountPlanUnsubscribeSettings} />
                    <Route path="resubscribe" component={LoadableAccountPlanResubscribeSettings} />
                    <Route path="immediate-downgrade" component={LoadableAccountPlanImmediateDowngradeSettings} />
                </Route>
                <Route path="interface" component={LoadableAccountInterface} name="Appearance" />
                <Route
                    path="workspaces"
                    name="Workspaces"
                    component={LoadableAccountWorkspaceSettings}
                    featureFlag={ExperimentId.userWorkspaces}
                />
                <Route path="preferences" component={LoadableAccountPreferences} name="Preferences" />
                <Route path="localization" component={LoadableLocalisationSettings} name="Localization" />
                <Route path="toolbar" component={LoadableToolbarOptions} name="Toolbar options" />
                <Route path="privacy" component={LoadablePrivacyPreferences} name="Privacy" />
                <Redirect from="*" to="/account/settings" />
            </Route>

            <Route path={`/${ELEMENT_MODAL_SUB_PATH}/:elementId`} component={ElementModal} noTransition />

            <Route path="/compose" component={ComposeModalRoot}>
                <Route path="email" component={ComposeEmailModalRoot} />
                <Route path="select-google-contacts" component={SelectGoogleContactsModal} />
            </Route>

            <Route path="/shortcuts" component={LoadableRouteShortcutsModal} />
            <Route path="/report-content(/:boardId)" component={ReportContentModal} />

            <Route path={`/${PUBLISH_PREVIEW_PATHNAME}`} component={PublishPreviewInit}>
                <Route path={`${ELEMENT_MODAL_SUB_PATH}/:elementId`} component={ElementModal} noTransition />
                <Route path=":currentBoardId(/:slug)" component={CurrentBoardIdSynchroniser} notModal />
            </Route>

            <Route path="/mobile">
                <Route path="quick-notes" component={MobileQuickNotesPage} />
                <Route path="search" component={MobileSearchPage} />
                <Route path="notifications" component={MobileNotificationsPage} />
                <Route path="settings">
                    <IndexRoute component={MobileSettingsPage} />
                    <Route path="color-scheme" component={MobileSettingsColorSchemePage} />
                </Route>
            </Route>

            <Route path="/:currentBoardId(/:slug)" component={CurrentBoardIdSynchroniser} notModal />
        </Route>
    </Route>
);
