import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { createSelector } from 'reselect';

import PopupPanelMouseManager from './PopupPanelMouseManager';
import PopupErrorBoundary from './errorBoundary/PopupErrorBoundary';

// Selectors
import { activePopupSelector } from './popupOpenSelector';
import { getPlatformDetailsSelector } from '../../platform/platformSelector';

// Utils
import { isPlatformPhoneOrMobileMode } from '../../platform/utils/platformDetailsUtils';

import { closePopup as closePopupAction } from './popupActions';

import { ARROW_DIR } from './popupConstants';

// Styles
import './TabbedPopupPanel.scss';
import './PopupPanel.scss';
import './PopupPanel.tooltip.scss';

const popupSelector = createSelector(
    activePopupSelector,
    (state, ownProps) => ownProps.popupId,
    (state, ownProps) => ownProps.forceVisible,
    getPlatformDetailsSelector,
    (popupState, popupId, forceVisible, platformDetails) => ({
        visible: forceVisible || popupState.has(popupId),
        isMobile: isPlatformPhoneOrMobileMode(platformDetails),
    }),
);

const mapDispatchToProps = (dispatch, ownProps) => ({
    closePopup: () => dispatch(closePopupAction(ownProps.popupId, false)),
});

const enhance = connect(popupSelector, mapDispatchToProps);

const PopupPanel = (props) => {
    const { visible, className, arrowDir, children, padded, isMobile, stayOpen } = props;
    if (!visible) return null;

    const arrowClass = isMobile ? null : `arrow-${arrowDir || ARROW_DIR.top}`;

    return (
        <div className={classNames('PopupPanel', className, arrowClass, { padded })}>
            <PopupPanelMouseManager {...props} stayOpen={isMobile || stayOpen}>
                <PopupErrorBoundary {...props}>
                    <div className="popup-content">{children}</div>
                </PopupErrorBoundary>
            </PopupPanelMouseManager>
        </div>
    );
};

PopupPanel.propTypes = {
    visible: PropTypes.bool,
    padded: PropTypes.bool,
    isMobile: PropTypes.bool,
    stayOpen: PropTypes.bool,
    className: PropTypes.string,
    arrowDir: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default enhance(PopupPanel);
