// Lib
import { DependencyList, useCallback } from 'react';
import { createDispatchHook, createSelectorHook, Selector } from 'react-redux';
import { EditorInstanceObject, TiptapEditorStoreActions, TiptapEditorStoreState } from './tiptapEditorStoreTypes';

// Context
import { TiptapEditorStoreContext } from './TiptapEditorStoreProvider';

// Actions
import { getActiveTiptapEditorThunk } from './tiptapEditorStoreActions';
import { Editor } from '@tiptap/react';

// FIXME-Tiptap thunk typings
export const useTiptapEditorDispatch = createDispatchHook<TiptapEditorStoreState, TiptapEditorStoreActions>(
    TiptapEditorStoreContext,
);

const tiptapSelectorHook = createSelectorHook<TiptapEditorStoreState, TiptapEditorStoreActions>(
    TiptapEditorStoreContext,
);

export type ActiveTiptapEditorSelector = Selector<Editor | undefined, any>;

/**
 * This hook allows consumers to select values from the active tiptap editor, without needing
 * to worry about finding the instance within the store's structure.
 *
 * The use of "activeEditor.instance" is so that we can ensure a re-render on active editor changes.
 * The activeEditor is a stable reference, so we need to wrap it in an object to ensure the reference changes.
 *
 * This hook allows selectors to ignore this detail.
 */
export const useTiptapActiveEditorSelector = (selector: ActiveTiptapEditorSelector) =>
    tiptapSelectorHook((state) => selector(state.activeEditor?.instance));

/**
 * This hook allows the consumer to get the active Tiptap editor instance directly,
 * however it is marked as "dangerous" because it could cause performance issues if it's used
 * in components that are regularly rendered.
 *
 * This is because the active editor instance can change frequently, so if many components use
 * this, it could cause many unnecessary re-renders.
 */
export const dangerouslyUseTiptapActiveEditor = () => tiptapSelectorHook((state) => state.activeEditor)?.instance;

type ActiveTiptapEditorCallback = (editor?: Editor, ...args: any[]) => unknown;
type UseActiveTiptapEditorCallback = (
    func: ActiveTiptapEditorCallback,
    dependencies?: DependencyList,
) => (...args: any[]) => unknown;

/**
 * Fires a thunk to get the active tiptap editor from the tiptap editor store,
 * then provides the editor to the callback function.
 */
export const useActiveTiptapEditorCallback: UseActiveTiptapEditorCallback = (func, dependencies) => {
    const dispatch = useTiptapEditorDispatch();

    const returnFunc = (...args: unknown[]) => {
        // @ts-ignore Tiptap thunk typings - FIXME
        const activeEditor = dispatch(getActiveTiptapEditorThunk()) as EditorInstanceObject | null;
        return func(activeEditor?.instance, ...args);
    };

    return dependencies ? useCallback(returnFunc, dependencies) : returnFunc;
};
