import registerFile from '../../../common/files/index';

import presentationalComponent from './File';
import containerComponent from './FileContainer';
import modalComponent from './modal/FileModal';

import { noSelectedElementsInIconViewMode } from '../../workspace/toolbar/config/toolbarToolConfigUtils';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

const onlyInCardView = ({ selectedElements }) => noSelectedElementsInIconViewMode(selectedElements);

const FILE_SELECTED_TOOLS = [
    TOOLBAR_TOOLS.COLOR,
    TOOLBAR_TOOLS.DISPLAY_MODE,
    TOOLBAR_TOOLS.MEDIA_LOOP,
    TOOLBAR_TOOLS.MEDIA_AUTOPLAY,
    {
        ...TOOLBAR_TOOLS.CAPTION,
        name: 'Description',
        availabilityPredicate: onlyInCardView,
    },
    {
        ...TOOLBAR_TOOLS.REACTION,
        availabilityPredicate: onlyInCardView,
    },
    ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT,
];

export default () =>
    registerFile({
        containerComponent,
        presentationalComponent,
        modalComponent,
        tools: {
            selected: FILE_SELECTED_TOOLS,
            editing_TITLE: FILE_SELECTED_TOOLS, // use regular selection tools when editing title
            editing_CAPTION: TOOL_SETS.TEXT_EDITING_CAPTION,
        },
    });
