import registerAlias from '../../../common/alias/index';
import component from './Alias';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerAlias({
        containerComponent: component,
        presentationalComponent: component,
        showTool: false,
        tools: {
            selected: [TOOLBAR_TOOLS.COLOR, TOOLBAR_TOOLS.ICON, ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT],
        },
    });
