// Lib
import React, { ReactElement } from 'react';

// Components
import createEditorTypeToggledComponent from './createEditorTypeToggledComponent';
import StandardRichTextToolContainer from '../richContentTools/StandardRichTextToolContainer';
import TiptapToggleMarkTool from './TiptapToggleMarkTool';

// Types
import { ToolbarToolProps } from '../../../config/toolDefinitions/toolbarToolTypes';

const TiptapBoldTool = (props: ToolbarToolProps): ReactElement => <TiptapToggleMarkTool {...props} mark="bold" />;

export default createEditorTypeToggledComponent(TiptapBoldTool, StandardRichTextToolContainer);
