// Lib
import React, { ReactElement } from 'react';

// Utils
import createEditorTypeToggledComponent from './createEditorTypeToggledComponent';

// Components
import TiptapToggleListTool from './TiptapToggleListTool';
import StandardRichTextToolContainer from '../richContentTools/StandardRichTextToolContainer';

// Types
import { ToolbarToolProps } from '../../../config/toolDefinitions/toolbarToolTypes';
import { TiptapNodeType } from '../../../../../../common/tiptap/tiptapTypes';

const TiptapOrderedListTool = (props: ToolbarToolProps): ReactElement => (
    <TiptapToggleListTool {...props} listType={TiptapNodeType.orderedList} />
);

export default createEditorTypeToggledComponent(TiptapOrderedListTool, StandardRichTextToolContainer);
