import { Reducer } from 'redux';
import { TiptapEditorStoreActions, TiptapEditorStoreState } from '../tiptapEditorStoreTypes';

const activeTiptapEditorReducer: Reducer<TiptapEditorStoreState['activeEditor'], TiptapEditorStoreActions> = (
    state = null,
    action,
) => {
    switch (action.type) {
        case 'SET_ACTIVE_TIPTAP_EDITOR':
            // Because the action.activeEditor is a class instance, its reference is stable.
            // Thus, we need to ensure that a new reference is created when the activeEditor is updated
            return action.activeEditor ? { instance: action.activeEditor } : null;
        default:
            return state;
    }
};

export default activeTiptapEditorReducer;
