// Lib
import { mapInlineStyleToMark } from '@tiptap/draftjs-to-tiptap';

// Types
import { MapStyleToMarkFn } from './draftToTiptapConverterTypes';

const DRAFT_TEXT_COLOR_PREFIX = 'TEXT-';
const DRAFT_BACKGROUND_COLOR_PREFIX = 'BG-';

const convertColoredTextToTiptapMark: MapStyleToMarkFn = (context) => {
    const { range } = context;

    return {
        type: 'textStyle',
        attrs: {
            color: range.style.replace(DRAFT_TEXT_COLOR_PREFIX, ''),
        },
    };
};

const convertHighlightedTextToTiptapMark: MapStyleToMarkFn = (context) => {
    const { range } = context;

    return {
        type: 'highlight',
        attrs: {
            color: range.style.replace(DRAFT_BACKGROUND_COLOR_PREFIX, '').replace('-', ''),
        },
    };
};

/**
 * Converts Draft.js inline style to Tiptap marks.
 * Adds custom Milanote-specific handling for text and background color, otherwise
 * delegates to the default draftjs-to-tiptap implementation.
 */
export const convertDraftInlineStyleToTiptapMark: MapStyleToMarkFn = (context) => {
    const { range } = context;

    if (range.style.startsWith(DRAFT_TEXT_COLOR_PREFIX)) {
        return convertColoredTextToTiptapMark(context);
    }

    if (range.style.startsWith(DRAFT_BACKGROUND_COLOR_PREFIX)) {
        return convertHighlightedTextToTiptapMark(context);
    }

    return mapInlineStyleToMark(context);
};

export default convertDraftInlineStyleToTiptapMark;
