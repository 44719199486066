// Lib
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import initialiseElementTypes from '../element/initialiseElementTypes';

// Utils
import getClientConfig from '../utils/getClientConfig';
import {
    isPlatformModernMobileApp,
    isPlatformLegacyMobileApp,
    isPlatformIframe,
} from '../platform/utils/platformDetailsUtils';
import { addCapacitorSafeAreaValues } from '../mobile/utils/addCapacitorSafeAreaValues';

// Selectors
import { getIsLocalCacheHydrated } from '../offline/cache/localCacheSelector';
import { getPlatformDetailsSelector } from '../platform/platformSelector';

// Hooks
import usePlatformBodyClassManager from '../platform/components/usePlatformBodyClassManager';
import useAttachDeepLinking from '../capacitorHooks/useAttachDeepLinking';

// Components
import { DebugManager } from '../debug/DebugManager';
import PwaDetection from '../pwaApp/PwaDetection';
import GridSizeManager from '../utils/grid/GridSizeManager';
import ThemeManager from '../user/account/accountModal/interface/themeSettings/ThemeManager';
import InterfaceSettingsManager from '../user/account/accountModal/interface/InterfaceSettingsManager';
import HeadingStyleManager from '../user/account/accountModal/interface/HeadingStyleManager';
import IconPreloader from './initialisation/IconPreloader';
import OneTrustInit from './oneTrust/OneTrustInit';
import PlatformSupportManager from '../platform/components/PlatformSupportManager';

// Styles
import './AppInit.scss';

// Constants
import { APP_DID_REHYDRATE_FROM_LOCAL_CACHE } from '../offline/appServerConnection/appServerReconnectionStatusConstants';

initialiseElementTypes();

const AppInitContainer = ({ children, ...props }) => {
    const isLocalCacheHydrated = useSelector(getIsLocalCacheHydrated);
    const dispatch = useDispatch();
    const dispatchAppConnectionStatusActive = () => dispatch({ type: APP_DID_REHYDRATE_FROM_LOCAL_CACHE });

    const platformDetails = useSelector(getPlatformDetailsSelector);

    useEffect(() => {
        if (isLocalCacheHydrated) dispatchAppConnectionStatusActive();
    }, []);

    useEffect(() => addCapacitorSafeAreaValues(), []);

    const loadOneTrust =
        !isPlatformLegacyMobileApp(platformDetails) &&
        !isPlatformModernMobileApp(platformDetails) &&
        !getClientConfig().disableThirdPartyResources;

    usePlatformBodyClassManager();

    // This is for deep linking on Capacitor mobile apps.
    // Deep linking detection needs to exist in the app container to handle links even when logged out.
    useAttachDeepLinking();

    return (
        <div className={classNames('App', { 'in-iframe': isPlatformIframe(platformDetails) })}>
            {children}
            <DebugManager />
            <PlatformSupportManager />
            <PwaDetection />
            <GridSizeManager />
            <HeadingStyleManager />
            <ThemeManager />
            <InterfaceSettingsManager />
            <IconPreloader />
            {loadOneTrust && <OneTrustInit />}
        </div>
    );
};

AppInitContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default AppInitContainer;
