import { RefObject, useEffect, useState } from 'react';
import { MAX_SHEET_HEIGHT, MAX_SUFFIX } from './useModalSheetDragState';
import { getDefaultSnapPointDetails } from '../utils/snapPointUtils';
import { runAfterNextTransitionEnds } from '../../../../utils/dom/domEventUtil';

/**
 * When sheet is opened, update height to the default snap point, and update the sheetInitialised value to show when it's done
 * @param isOpen
 * @param sheetRef
 * @param sheetOverlayRef
 * @param defaultSnapPoint
 * @param addSnapPoint
 * @param updateActiveSnapPoint
 * @param closeSheet
 */
const useModalSheetInitialisation = (
    isOpen: boolean,
    sheetRef: RefObject<HTMLDivElement>,
    sheetOverlayRef: RefObject<HTMLDivElement>,
    defaultSnapPoint: number | string | undefined,
    addSnapPoint: (snapPoint: number) => void,
    updateActiveSnapPoint: (snapPoint: number, goToPoint: boolean) => void,
    closeSheet: () => void,
): boolean => {
    const [sheetInitialised, setSheetInitialised] = useState<boolean>(false);

    /**
     * Open the sheet to the specified default snap point, update active snap point, and set sheet as initialised
     * @param point
     */
    const openToDefaultSnapPoint = (point: number) => {
        addSnapPoint(point);
        updateActiveSnapPoint(point, true);
        setSheetInitialised(true);
    };

    /**
     * Open the sheet to the content height, then add the height as a snap point
     * @param maxInitialSnapPoint
     */
    const openToContentHeight = (maxInitialSnapPoint: number) => {
        if (!sheetRef.current) return;

        // Just for this initial one, transition the maxHeight instead of height,
        // then add height as a snap point after transition ends

        sheetRef.current.style.maxHeight = `${maxInitialSnapPoint * 100}%`;
        runAfterNextTransitionEnds(sheetRef.current, () => {
            if (!sheetRef.current) return;

            // After the transition to full content height or max has finished, add the height as a snap point
            const contentHeightAsDecimal = sheetRef.current.clientHeight / window.innerHeight;
            const initialSnapPoint = Math.min(maxInitialSnapPoint, contentHeightAsDecimal);

            addSnapPoint(initialSnapPoint);
            setSheetInitialised(true);

            // Set height and max height to the correct values
            // This is necessary when maxInitialSnapPoint is true, since we need to change the maxHeight,
            // and when that changes the height will be affected
            updateActiveSnapPoint(initialSnapPoint, true);
            sheetRef.current.style.maxHeight = `${MAX_SHEET_HEIGHT * 100}%`;
        });
    };

    /**
     * Set the initial sheet height based on the value of defaultSnapPoint
     */
    const setInitialSheetHeight = () => {
        if (!sheetRef.current) return;

        const { point, suffix } = getDefaultSnapPointDetails(defaultSnapPoint);
        const defaultSnapIsMax = suffix && point && suffix === MAX_SUFFIX;

        // --- Set the specified default snap point ---
        if (point && !defaultSnapIsMax) {
            requestAnimationFrame(() => {
                openToDefaultSnapPoint(point);
            });
            return;
        }

        // --- Set the initial snap point based on the content height ---
        const maxInitialSnapPoint = defaultSnapIsMax ? point : MAX_SHEET_HEIGHT;

        sheetRef.current.style.height = 'auto';
        sheetRef.current.style.maxHeight = `0px`;

        // raf ensures all style changes are applied before starting the transition
        requestAnimationFrame(() => {
            openToContentHeight(maxInitialSnapPoint);
        });
    };

    /**
     * Close the sheet when going back in the browser history.
     * e.g. when the user presses the browser or native back button
     */
    const closeSheetOnBack = () => {
        // Add a blank history state to allow the user to go back
        window.history.pushState({}, '');
        window.addEventListener('popstate', closeSheet, { once: true });
    };

    // Set the initial sheet height
    useEffect(() => {
        if (isOpen) {
            sheetOverlayRef.current?.classList.add('overlay-visible');
            setInitialSheetHeight();
            closeSheetOnBack();
        } else {
            setSheetInitialised(false);
            updateActiveSnapPoint(0, false);
        }
    }, [isOpen]);

    return sheetInitialised;
};

export default useModalSheetInitialisation;
