// Lib
import React, { ReactElement } from 'react';

// Hooks
import {
    ActiveTiptapEditorSelector,
    useActiveTiptapEditorCallback,
    useTiptapActiveEditorSelector,
} from '../../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Components
import TextColorButton from './TextColorButton';

const isClearTextColorButtonActive: ActiveTiptapEditorSelector = (editor) =>
    !editor?.isActive('textStyle') && !editor?.isActive('highlight');

interface TiptapTextColorButtonProps {
    customTextColor?: string | null;
    customBackgroundColor?: string | null;
}

const TiptapClearTextColorButton = (props: TiptapTextColorButtonProps): ReactElement => {
    const { customTextColor, customBackgroundColor } = props;

    const active = useTiptapActiveEditorSelector(isClearTextColorButtonActive);

    const onButtonClick = useActiveTiptapEditorCallback((activeEditor) => {
        activeEditor?.chain().focus().unsetColor().unsetHighlight().run();
    });

    return (
        <TextColorButton
            active={active || false}
            color={null}
            className={`text-default`}
            onSelectTextColor={onButtonClick}
            customTextColor={customTextColor}
            customBackgroundColor={customBackgroundColor}
        />
    );
};

export default TiptapClearTextColorButton;
