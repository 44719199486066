// Lib
import React from 'react';
import { Editor } from '@tiptap/react';

/**
 * For some reason toggling the editable state of the editor needs to be triggered via the API
 * rather than via the hooks / React component - so this hook achieves that result.
 */
const useTiptapTriggerEditable = (editor: Editor | null, isEditable: boolean) => {
    React.useEffect(() => {
        if (!editor) return;

        editor.setEditable(isEditable);
    }, [editor, isEditable]);
};

export default useTiptapTriggerEditable;
