// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';

// Utils
import { prop } from '../../../../../common/utils/immutableHelper';
import { getDisplayName, getEmail } from '../../../../../common/users/userHelper';
import { stopDefaultAndPropagation } from '../../../../utils/domUtil';
import { convertMentionToUser } from './mentionUtils';
import { canEditPermissions } from '../../../../../common/permissions/permissionUtil';

// Selectors
import { getIsCurrentBoardRoot } from '../../../../element/board/boardSelector';
import { currentBoardUserPermissionsSelector } from '../../../../utils/permissions/elementPermissionsSelector';

// Actions
import { addUserAsEditor } from '../../../../workspace/header/currentBoardHeader/sharing/popup/editing/boardEditorPanelActions';

// Components
import { SimpleUserAvatar } from '../../../userAvatar/UserAvatar';
import HighlightedText from '../../../HighlightedText';
import StyledButton from '../../../buttons/StyledButton';
import Spinner from '../../../loaders/Spinner';

// Constants
import { USER_AVATAR_SIZES } from '../../../../../common/users/userConstants';
import { TIMES } from '../../../../../common/utils/timeUtil';

// Styles
import './MentionSuggestionEntry.scss';

const hasInvitePermissionsSelector = (state) =>
    canEditPermissions(currentBoardUserPermissionsSelector(state)) && !getIsCurrentBoardRoot(state);

const mapDispatchToProps = (dispatch) => ({
    onAddUserAsEditor: ({ user }) => dispatch(addUserAsEditor({ user })),
});

const mapStateToProps = createStructuredSelector({
    hasInvitePermissions: hasInvitePermissionsSelector,
});

// TODO-TIPTAP-MENTIONS: Move this component out of the draftjs directory
export const MentionSuggestionEntry = (props) => {
    const {
        children,
        id,
        searchValue,
        className,
        mention,
        role,
        hasInvitePermissions,
        onMouseEnter,
        onMouseDown,
        onMouseUp,
        onMentionSelect,
        onAddUserAsEditor,
        entryRef,
    } = props;

    const [isInviting, setIsInviting] = React.useState(false);
    const [error, setError] = React.useState(null);

    const user = convertMentionToUser(mention);
    const disabled = prop('disabled', user);

    const onInviteClick = React.useCallback(async () => {
        setIsInviting(true);
        try {
            await onAddUserAsEditor({ user: mention });
            onMentionSelect(mention);
        } catch (err) {
            setIsInviting(false);
            setError(err);
            setTimeout(() => setError(null), TIMES.SECOND);
        }
    }, []);

    return (
        <div
            id={id}
            ref={entryRef}
            className={classNames('MentionSuggestionEntry', className, { disabled })}
            role={role}
            aria-selected={props['aria-selected']}
            onMouseEnter={!disabled ? onMouseEnter : null}
            onMouseDown={!disabled ? onMouseDown : stopDefaultAndPropagation}
            onMouseUp={!disabled ? onMouseUp : null}
        >
            <SimpleUserAvatar user={user} size={USER_AVATAR_SIZES.REGULAR_SMALL} />
            <div className="user-details">
                <HighlightedText highlightText={searchValue} text={getDisplayName(user)} className="name" />
                <HighlightedText highlightText={searchValue} text={getEmail(user)} className="email" />
            </div>
            {disabled && hasInvitePermissions && !isInviting && !error && (
                <StyledButton
                    className="invite-button secondary thin"
                    onMouseDown={stopDefaultAndPropagation}
                    onClick={onInviteClick}
                >
                    Invite
                </StyledButton>
            )}
            {isInviting && <Spinner show />}
            {error && <span className="error failed-message">Failed</span>}
            {children}
        </div>
    );
};

MentionSuggestionEntry.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    entryRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    className: PropTypes.string,
    id: PropTypes.string,
    searchValue: PropTypes.string,
    role: PropTypes.string,
    'aria-selected': PropTypes.string,
    mention: PropTypes.object,
    onMouseEnter: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,

    onAddUserAsEditor: PropTypes.func,
    onMentionSelect: PropTypes.func,
    hasInvitePermissions: PropTypes.bool,
};

// FIXME - The connect here will break the forwardRef if it's needed! Might be why the arrows don't work any more
export default connect(mapStateToProps, mapDispatchToProps)(MentionSuggestionEntry);
