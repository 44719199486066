// Lib
import { isString } from 'lodash/fp';

// Utils
import { prop, propIn, objectSize } from '../../utils/immutableHelper';
import { isAlias, isClone } from './elementTypeUtils';
import { TIMES } from '../../utils/timeUtil';

export const PERMISSION_CONTENT_PROPERTIES = [
    'publicEditEnabled',
    'publishedFeedbackEnabled',
    'fileDownloadsEnabled',
    'published',
    'publishedPasswordProtected',
    'welcomeMessage',
    'embedGenerated',
];

// ID
// Using explicit methods instead of prop as this is very "hot" code so we want it as performant as possible
export const getElementId = (element) =>
    (element && element.get && (element.get('id') || element.get('_id'))) || (element && (element._id || element.id));

// Aliases
export const getLinkedElementId = propIn(['content', 'linkTo']);
export const getIsLinkAlias = propIn(['content', 'linkAlias']);
export const getAliasPermissionId = propIn(['content', 'permissionId']);

/**
 * Gets the ID of the actual element represented by this element (if it's a clone or alias it will
 * use the ID of the linked element).
 */
export const getPhysicalId = (element) =>
    isAlias(element) || isClone(element) ? getLinkedElementId(element) : getElementId(element);

// Base properties
export const getElementType = (elementType) => (isString(elementType) ? elementType : prop('elementType', elementType));
export const getAcl = prop('acl');

// Meta
export const getCreatorId = propIn(['meta', 'creator']);
export const getModifiedBy = propIn(['meta', 'modifiedBy']);
export const getModifiedTime = propIn(['meta', 'modifiedTime']);
export const getAclModifiedTime = propIn(['meta', 'aclModifiedTime']);
export const getSignificantModified = propIn(['meta', 'significantModified']);
export const getCreatedTime = propIn(['meta', 'createdTime']);
export const getTempId = propIn(['meta', 'tempId']);
export const getPlatform = propIn(['meta', 'platform']);
export const getMetaVersionId = propIn(['meta', 'versionId']);

// Positioning
// Using explicit methods instead of prop as this is very "hot" code so we want it as performant as possible
export const getLocationParentId = (element) =>
    element && (element.location?.parentId || (element.getIn && element.getIn(['location', 'parentId'])));

export const getElementLocation = prop('location');
export const getRootBoardProperty = propIn(['location', 'rootBoard']);
export const getLocationSection = propIn(['location', 'section']);
export const getLocationPosition = propIn(['location', 'position']);
export const getRestoreLocation = propIn(['location', 'position', 'restoreLocation']);
export const getRestoreLocationParentId = propIn(['location', 'position', 'restoreLocation', 'parentId']);
export const getTrashedDate = propIn(['location', 'position', 'addedDate']);

export const getScoreWithoutFallback = propIn(['location', 'position', 'score']);
export const getScore = (element) => getScoreWithoutFallback(element) || 0;
export const getPositionIndex = propIn(['location', 'position', 'index']);

export const getOrderProperty = (element) => propIn(['location', 'position', 'order'], element) || 0;

export const getPosition = (dimension) => (element) => propIn(['location', 'position', dimension], element) || 0;
export const getYPosition = getPosition('y');
export const getXPosition = getPosition('x');

export const getDeletedDate = propIn(['location', 'position', 'deletedDate']);

// Content
export const getContent = propIn(['content']);
export const getInboxState = propIn(['content', 'inbox']);
export const getRootType = propIn(['content', 'rootType']);
export const isElementLocked = propIn(['content', 'locked']);
export const getTitle = propIn(['content', 'title']);
export const getShowTitle = propIn(['content', 'showTitle']);
export const getColor = propIn(['content', 'color']);
export const getSecondaryColor = propIn(['content', 'secondaryColor']);
export const getColorSpace = propIn(['content', 'color', 'space']);
export const getColorValue = propIn(['content', 'color', 'value']);
export const getShowColorValue = propIn(['content', 'showColorValue']);
export const getIcon = propIn(['content', 'icon']);
export const getIconId = propIn(['content', 'icon', 'id']);
export const getIconType = propIn(['content', 'icon', 'type']);
export const getWidth = propIn(['content', 'width']);
export const getHeight = propIn(['content', 'height']);
export const getMinHeight = propIn(['content', 'minHeight']);
export const getMaxHeight = propIn(['content', 'maxHeight']);
export const getAutoHeight = propIn(['content', 'autoHeight']);
export const getLinkTitle = (element) => getTitle(element) || propIn(['content', 'link', 'title'])(element);
export const getShowCaption = propIn(['content', 'showCaption']);
export const getCaption = propIn(['content', 'caption']);
export const getCaptionModified = propIn(['content', 'captionModified']);
export const getTextContent = propIn(['content', 'textContent']);
export const getImageKeywords = propIn(['content', 'keywords']);
export const getAttachmentId = propIn(['content', 'attachmentId']);
export const getDisplayMode = propIn(['content', 'displayMode']);
export const getInboxAutomaticOpenTimestamp = propIn(['content', 'inboxAutomaticOpenTimestamp']);

// Tiptap/Draft conversion properties
const getTiptapConversionPostponeTime = propIn(['content', 'tiptapConversionPostponeTime']);
export const getIsTiptapConversionRecentlyPostponed = (element) => {
    const postponedSince = getTiptapConversionPostponeTime(element) || 0;
    const delay = Date.now() - postponedSince;
    return delay < TIMES.WEEK;
};

// Workspace properties
export const getWorkspaceType = propIn(['content', 'type']);

// Board coordinates
export const DEFAULT_CANVAS_ORIGIN = { x: 0, y: 0 };
export const getCanvasOriginCoordinates = (element) =>
    propIn(['content', 'canvasOrigin'], element) || DEFAULT_CANVAS_ORIGIN;
export const getPreviousCanvasOrigin = propIn(['content', 'previousCanvasOrigin']);

// Links
export const getLinkProp = propIn(['content', 'link']);
export const getLinkUrl = propIn(['content', 'link', 'url']);
export const getLinkType = propIn(['content', 'linkType']);
export const getLinkFavicon = propIn(['content', 'link', 'favicon']);
export const getIgnoreAutoType = (el) => !!propIn(['content', 'ignoreAutoType'], el);

// Images
export const getImageProp = propIn(['content', 'image']);
export const getImageRegular = propIn(['content', 'image', 'regular']);
export const getImageHuge = propIn(['content', 'image', 'huge']);
export const getImageLarge = propIn(['content', 'image', 'large']);
export const getImageOriginal = propIn(['content', 'image', 'original']);
export const getImageThumbnail = propIn(['content', 'image', 'thumb']);
export const getImageError = propIn(['content', 'image', 'error']);
export const getImageWidth = propIn(['content', 'image', 'width']);
export const getImageHeight = propIn(['content', 'image', 'height']);
export const getShowBackground = propIn(['content', 'showBackground']);
export const getIsDataUri = propIn(['content', 'image', 'isDataUri']);
export const getImageColors = propIn(['content', 'image', 'colors']);
export const getImagePrimaryColor = propIn(['content', 'image', 'primaryColor']);
export const getIsTransparentImage = (data) => !!propIn(['content', 'image', 'transparent'], data);
export const getIsImageUploaded = (data) => !getTempId(data) || objectSize(getImageProp(data)) > 0;
export const getShowFullAspectRatio = (el) => !!propIn(['content', 'showFullAspectRatio'], el);

// Edited images
export const getImageOriginalProp = propIn(['content', 'imageOriginal']);

// Media
export const getShowMedia = (element) => propIn(['content', 'showMedia'], element) !== false;
export const getMediaPlayerLoop = propIn(['content', 'mediaPlayerLoop']);
export const getMediaPlayerAutoplay = propIn(['content', 'mediaPlayerAutoplay']);

export const getMedia = propIn(['content', 'media']);
export const getMediaType = propIn(['content', 'mediaType']);
export const getMediaWidth = propIn(['content', 'media', 'width']);
export const getMediaHeight = propIn(['content', 'media', 'height']);
export const getMediaOriginalWidth = propIn(['content', 'media', 'originalWidth']);
export const getMediaOriginalHeight = propIn(['content', 'media', 'originalHeight']);
export const getPreviewReady = propIn(['content', 'previewReady']);
export const getMediaAudioOnly = propIn(['content', 'audioOnly']);

// Board permissions
export const getIsPublicEditEnabled = (element) => !!propIn(['content', 'publicEditEnabled'], element);
export const getIsPublicEditEnabledTimestamp = propIn(['content', 'publicEditEnabledTimestamp']);
// NOTE: This doesn't use !! because we want to default to true if it's unset
export const getIsPublishedFeedbackEnabled = propIn(['content', 'publishedFeedbackEnabled']);
export const getIsPublishedMediaDownloadDisabled = propIn(['content', 'publishedMediaDownloadDisabled']);

export const getIsPublishedPasswordProtected = (element) =>
    !!propIn(['content', 'publishedPasswordProtected'], element);

export const getIsPublished = (element) => !!propIn(['content', 'published'], element);
export const getPublishedTimestamp = (element) => !!propIn(['content', 'publishedTimestamp'], element);
export const getWelcomeMessageProp = propIn(['content', 'welcomeMessage']);
export const getIsWelcomeMessageEnabled = (element) => !!propIn(['content', 'welcomeMessage', 'enabled'], element);
export const getWelcomeMessageText = propIn(['content', 'welcomeMessage', 'textContent']);
export const getWelcomeMessageModifier = propIn(['content', 'welcomeMessage', 'modifier']);
export const getIsEmbedGenerated = (element) => !!propIn(['content', 'embedGenerated'], element);

// Board background
export const getBackground = propIn(['content', 'background']);
// Note: This should only be used on the root board.. Other boards will just not have an image
export const getHideBackgroundImage = propIn(['content', 'background', 'hideImage']);
export const getShowGrid = propIn(['content', 'background', 'showGrid']);
export const getBackgroundColor = propIn(['content', 'background', 'color']);

// Board auto color
export const getAutoColorIndex = propIn(['content', 'autoColorIndex']);
export const getDefaultColorPalette = propIn(['content', 'defaultColorPalette']);

// Lines
export const getLineStart = propIn(['content', 'start']);
export const getLineStartConnectedElementId = propIn(['content', 'start', 'elementId']);
export const getIsLineStartSnapped = (element) => !!propIn(['content', 'start', 'snapped'], element);
export const getIsLineStartFixed = (element) => !!propIn(['content', 'start', 'fixed'], element);
export const getLineStartX = (element) => propIn(['content', 'start', 'x'], element);
export const getLineStartY = (element) => propIn(['content', 'start', 'y'], element);
export const getLineEnd = propIn(['content', 'end']);
export const getLineEndConnectedElementId = propIn(['content', 'end', 'elementId']);
export const getIsLineEndSnapped = (element) => !!propIn(['content', 'end', 'snapped'], element);
export const getIsLineEndFixed = (element) => !!propIn(['content', 'end', 'fixed'], element);
export const getLineEndX = (element) => propIn(['content', 'end', 'x'], element);
export const getLineEndY = (element) => propIn(['content', 'end', 'y'], element);
export const getIsLineSnapped = (element) => getIsLineStartSnapped(element) || getIsLineEndSnapped(element);

export const getLineControl = propIn(['content', 'control']);
export const getLineStartStyle = propIn(['content', 'startStyle']);
export const getLineEndStyle = propIn(['content', 'endStyle']);
export const getLineStyle = propIn(['content', 'lineStyle']);
export const getLineWeight = propIn(['content', 'lineWeight']);
export const getLineLabel = propIn(['content', 'lineLabel']);
export const getIsLineLabelEnabled = (element) => !!propIn(['content', 'lineLabelEnabled'], element);

// File
export const getFileProp = propIn(['content', 'file']);
export const getFilename = propIn(['content', 'file', 'filename']);
export const getFileSize = propIn(['content', 'file', 'size']);
export const getFileUrl = propIn(['content', 'file', 'url']);
export const getFileMimeType = propIn(['content', 'file', 'mime']);
export const getFileType = propIn(['content', 'file', 'type']);
export const getFileUploadedTimestamp = propIn(['content', 'file', 'uploadedTimestamp']);

// Comments
export const getThreadId = propIn(['content', 'threadId']);
export const getDuplicatedFromThreadId = propIn(['content', 'duplicatedFromThreadId']);
export const getIsCollapsed = propIn(['content', 'collapsed']);

// Alias
export const getOriginalTitle = propIn(['content', 'originalTitle']);
export const getOriginalColor = propIn(['content', 'originalColor']);
export const getOriginalSecondaryColor = propIn(['content', 'originalSecondaryColor']);
export const getOriginalIcon = propIn(['content', 'originalIcon']);
export const getOriginalImage = propIn(['content', 'originalImage']);
export const getOriginalImageOriginal = propIn(['content', 'originalImage', 'original']);
export const getOriginalImageRegular = propIn(['content', 'originalImage', 'regular']);
export const getOriginalImageLarge = propIn(['content', 'originalImage', 'large']);
export const getOriginalImageHuge = propIn(['content', 'originalImage', 'huge']);

// Task
export const getIsTaskComplete = (element) => !!propIn(['content', 'isComplete'], element);
export const getShowAssignments = (element) => !!propIn(['content', 'showAssignments'], element);
export const getAssignments = propIn(['content', 'assignments']);
export const getShowDueDate = (element) => !!propIn(['content', 'showDueDate'], element);
export const getHasDueDateTime = (element) => !!propIn(['content', 'hasDueDateTime'], element);
export const getDueDate = propIn(['content', 'dueDate']);
export const getDueReminder = propIn(['content', 'dueReminder']);
export const getDueReminderTimestamp = propIn(['content', 'dueReminderTimestamp']);

// Drawing
export const getDrawingData = propIn(['content', 'drawing']);
export const getDrawingSvg = propIn(['content', 'drawing', 'svg']);
export const getDrawingWidth = propIn(['content', 'drawing', 'width']);
export const getDrawingHeight = propIn(['content', 'drawing', 'height']);
export const isTransparencyEnabled = (el) => !!propIn(['content', 'transparent', 'enabled'], el);
export const getDrawingMode = propIn(['content', 'drawingMode']);
export const getHasDrawing = (element) => !!propIn(['content', 'hasDrawing'], element);

// Reactions
export const getReactions = propIn(['content', 'reactions']);

// Clone
export const getHasClones = (el) => !!propIn(['content', 'hasClones'], el);
export const getClonedElementType = propIn(['content', 'clonedElementType']);

// Table
export const getTableContent = propIn(['content', 'tableContent']);
export const getTableContentData = propIn(['content', 'tableContent', 'data']);
export const getTableContentDataAtCell = ({ row, col }) => propIn(['content', 'tableContent', 'data', row, col]);
export const getTableContentCellValue = ({ row, col }) =>
    propIn(['content', 'tableContent', 'data', row, col, 'value']);
export const getTableContentCellType = ({ row, col }) => propIn(['content', 'tableContent', 'data', row, col, 'type']);
export const getTableContentCellTextStyle = ({ row, col }) =>
    propIn(['content', 'tableContent', 'data', row, col, 'textStyle']);
export const getTableContentCellTextAlignment = ({ row, col }) =>
    propIn(['content', 'tableContent', 'data', row, col, 'textAlignment']);
export const getTableContentCellVerticalAlignment = ({ row, col }) =>
    propIn(['content', 'tableContent', 'data', row, col, 'verticalAlignment']);

export const getTableContentColWidthsGU = propIn(['content', 'tableContent', 'colWidthsGU']);
