import DefaultListKeymap from '@tiptap/extension-list-keymap';

/**
 * Overrides the extension to add the Alt+Delete and Alt+Backspace keyboard shortcuts.
 */
export const ListKeymap = DefaultListKeymap.extend({
    /**
     * Currently the default ListKeymap extension does not provide shortcuts
     * for Alt-Delete and Alt-Backspace
     * The default behaviour of these operations is to remove the paragraph from
     * its own list item, but instead attach it to the previous list item, thus
     * appearing to be a soft-line break, rather than joining the paragraphs.
     */
    addKeyboardShortcuts() {
        const defaultShortcuts = this.parent?.() ?? {};

        return {
            ...defaultShortcuts,
            'Alt-Delete': defaultShortcuts.Delete,
            'Alt-Backspace': defaultShortcuts.Backspace,
        };
    },
});
