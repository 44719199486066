// Lib
import { useMemo } from 'react';
import { Extensions } from '@tiptap/core';
import { CommandProps } from '@tiptap/core/src/types';
import { Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import TextColor from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import { defer } from 'lodash';

// Custom extensions
import { Heading } from '../../../../common/tiptap/extensions/Heading';
import { Paragraph } from '../../../../common/tiptap/extensions/Paragraph';
import { EmptyDelete } from '../../../../common/tiptap/extensions/EmptyDelete';
import { KeyboardShortcuts } from '../../../../common/tiptap/extensions/KeyboardShortcuts';
import { SmallText } from '../../../../common/tiptap/extensions/SmallText';
import { Link } from '../../../../common/tiptap/extensions/Link';
import { Strike } from '../../../../common/tiptap/extensions/Strike';
import { Mention } from '../../../../common/tiptap/extensions/mention/Mention';
import { TextStyle } from '../../../../common/tiptap/extensions/TextStyle';
import { TextHighlight } from '../../../../common/tiptap/extensions/TextHighlight';
import { TaskList } from '../../../../common/tiptap/extensions/TaskList';
import { TaskItem } from '../../../../common/tiptap/extensions/TaskItem';
import { BulletList } from '../../../../common/tiptap/extensions/list/BulletList';
import { OrderedList } from '../../../../common/tiptap/extensions/list/OrderedList';
import { TextAlign } from '../../../../common/tiptap/extensions/TextAlign';
import { Blockquote } from '../../../../common/tiptap/extensions/Blockquote';
import { CodeBlock } from '../../../../common/tiptap/extensions/CodeBlock';
import { TabHandler } from '../../../../common/tiptap/extensions/TabHandler';
import { InlineStyleClearer } from '../../../../common/tiptap/extensions/InlineStyleClearer';
import { UniversalCard } from '../../../../common/tiptap/extensions/universalCard/UniversalCard';
import { Italic } from '../../../../common/tiptap/extensions/Italic';
import { Bold } from '../../../../common/tiptap/extensions/Bold';
import { DebouncedSaveCommands } from '../../../../common/tiptap/extensions/DebouncedSave';
import { ListKeymap } from '../../../../common/tiptap/extensions/list/ListKeymap';
import { ListCommands } from '../../../../common/tiptap/extensions/list/ListCommands';

// Types
import { DispatchCreateNewElementFunction } from '../../actions/elementCreateActionTypes';
import { MentionsSuggestionsManager } from '../../../../common/tiptap/extensions/mention/suggestionsManager';

// Constants
import { ELEMENT_CREATION_SOURCES } from '../../../../common/elements/elementConstants';

// NOTE: These "SIMPLE_EXTENSIONS" should be moved to a more common location if multiple
//  editors use them
const SIMPLE_EXTENSIONS = [
    // The standard extensions for Tiptap
    //  Supports: Lists, quotes, code, inline styles, headings, history
    //  See: https://tiptap.dev/docs/editor/extensions/functionality/starterkit
    StarterKit.configure({
        // We're overriding the default extensions with customised versions below
        strike: false,
        heading: false,
        paragraph: false,
        bulletList: false,
        orderedList: false,
        blockquote: false,
        codeBlock: false,
        bold: false,
        italic: false,
    }),
    Bold,
    Italic,
    Paragraph,
    Underline,
    Heading,
    BulletList,
    OrderedList,
    ListCommands,
    Blockquote,
    // Improves the editing functionality in lists.
    //  See: https://tiptap.dev/docs/editor/extensions/functionality/listkeymap
    ListKeymap,
    // Allows text to be centred
    //  See: https://tiptap.dev/docs/editor/extensions/functionality/textalign
    TextAlign,
    // Show the placeholder text when the editor is empty
    Placeholder.configure({
        placeholder: 'Start typing...',
        showOnlyWhenEditable: false,
    }),
    SmallText,
    // This extension is required for the TextColor extension to work
    TextStyle,
    TextColor,
    Strike,
    TextHighlight,
    TaskList,
    CodeBlock,
    // Adds or removes tabs on lines
    TabHandler,
    // Clears inline styles when the user presses Enter
    InlineStyleClearer,
    DebouncedSaveCommands,
];

/**
 * Retrieves the Tiptap extensions for the Card's main editor, with the EmptyDelete extension
 *  configured to move the element to trash when triggered.
 */
const useCardTiptapEditorExtensions = (
    moveElementsToTrash: () => void,
    saveContent: (textContent: object, transactionId?: string) => void,
    suggestion: MentionsSuggestionsManager,
    createNewCard?: DispatchCreateNewElementFunction,
    isEditable?: boolean,
    dispatch?: Function,
): Extensions =>
    useMemo(() => {
        /**
         * When Cmd+Enter is pressed, create a new card.
         */
        const onModEnter = ({ editor }: CommandProps) => {
            // Save the content first, before creating a new element, so that the undo stack is correct
            const currentContent = editor.getJSON();
            saveContent(currentContent);

            // Need to defer this to prevent the app keyboard shortcut handlers from creating a new element as well.
            // The first new element would get created by this function, then selected, then the keyboardShortcuts
            // handlers would be triggered and another new element would be created.
            defer(() => {
                createNewCard?.({ creationSource: ELEMENT_CREATION_SOURCES.CMD_ENTER });
            });

            return !!createNewCard;
        };

        // Only add extensions if their handlers are provided
        const customExtensions = [
            createNewCard &&
                KeyboardShortcuts.configure({
                    keyboardShortcuts: {
                        // Create a new card on mod+enter
                        'Mod-Return': onModEnter,
                        'Mod-Enter': onModEnter,
                    },
                }),
            // Will trigger the onEmptyDelete function if the card is empty, when the
            //  delete or backspace key is pressed.
            moveElementsToTrash &&
                EmptyDelete.configure({
                    onEmptyDelete: (editor: Editor) => {
                        // Save the empty content first, before deleting the element, so that the
                        //  undo stack is correct
                        const emptyContent = editor.getJSON();
                        saveContent(emptyContent);

                        moveElementsToTrash?.();

                        return !!moveElementsToTrash;
                    },
                }),
            suggestion && Mention.configure({ suggestion }),
            TaskItem.configure({
                nested: true,
                onReadOnlyChecked: (node, checked) => !!isEditable,
            }),
            // Allows hyperlinks
            //  See: https://tiptap.dev/docs/editor/extensions/marks/link#page-title
            Link.configure({
                dispatch,
                isEditable,
            }),
            isEditable &&
                UniversalCard.configure({
                    dispatch,
                }),
        ].filter(Boolean);

        return [...SIMPLE_EXTENSIONS, ...customExtensions] as Extensions;
    }, [moveElementsToTrash, saveContent, suggestion, createNewCard, isEditable, dispatch]);

export default useCardTiptapEditorExtensions;
