import { MentionAttrs } from './Mention';

// TODO-TIPTAP-MENTIONS: Remove this once the draft mention format is dealt with
type DraftMentionData = {
    id: string;
    name: string;
    _name: {
        givenName: string;
        familyName: string;
        displayName: string;
    };
    email: string;
    image: string;
    disabled: boolean;
    shareCount: number;
    sharingCurrentBoard: boolean;
    score: number;
};

// TODO-TIPTAP-MENTIONS: Should tiptap just use the draft mention format? Or the mention components should
// be converted to work in the tiptap format and the draft mention format should be removed.
export function draftMentionToTiptapMention(draftMention: DraftMentionData): MentionAttrs {
    const { _name, name, ...mentionData } = draftMention;
    return {
        ...mentionData,
        label: name || _name.displayName,
        ..._name,
    };
}
