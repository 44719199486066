import { Extension } from '@tiptap/core';
import { DEFAULT_TIPTAP_EXTENSION_PRIORITY } from '../tiptapTypes';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        inlineStyleCleaner: {
            clearInlineStyles: () => ReturnType;
        };
    }
}

/**
 * Extension to clear all inline styles when the Enter key is pressed.
 */
export const InlineStyleClearer = Extension.create({
    // Run before other extensions
    priority: DEFAULT_TIPTAP_EXTENSION_PRIORITY + 10,

    name: 'inlineStyleCleaner',

    addCommands() {
        return {
            clearInlineStyles:
                () =>
                ({ tr, dispatch }) => {
                    const { selection } = tr;
                    const { empty } = selection;

                    // This will only work if the selection is empty at the moment,
                    //  due to the other Enter handlers
                    if (!empty) return false;
                    if (!dispatch) return true;

                    // Clear the marks that will be applied to the active text
                    tr.setStoredMarks([]);

                    return true;
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Enter: () => {
                this.editor.commands.clearInlineStyles();
                // Let other commands run
                return false;
            },
        };
    },
});
