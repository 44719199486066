import registerBoard from '../../../common/boards/index';
import presentationalComponent from './Board';
import containerComponent from './BoardContainer';

import platformSingleton from '../../platform/platformSingleton';
import { isPlatformApple } from '../../platform/utils/platformDetailsUtils';

import { TOOLBAR_TOOLS, TOOL_SETS } from '../../workspace/toolbar/config/toolbarToolConfig';

export default () =>
    registerBoard({
        containerComponent,
        presentationalComponent,
        tooltip: isPlatformApple(platformSingleton) ? '⌃⌘B' : 'Ctrl+Alt+B',
        tools: {
            selected: [TOOLBAR_TOOLS.COLOR, TOOLBAR_TOOLS.ICON, ...TOOL_SETS.MULTI_SELECT_ARRANGEMENT],
        },
    });
