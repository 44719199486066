// Tiptap/Draft conversion properties
// TODO-TIPTAP - Remove these when we remove draft
import { ImMap, prop } from '../../utils/immutableHelper';
import { TiptapContent } from '../tiptapTypes';
import { RawDraftContentState } from 'draft-js';
import { ElementEditor } from '../../elements/elementEditorConstants';
import { ImMNElement } from '../../elements/elementModelTypes';
import { getTextContent } from '../../elements/utils/elementPropertyUtils';

const getDraftBlocks = prop('blocks');
const getTiptapContent = prop('content');
export const getEditorType = (
    textContent:
        | ImMap<TiptapContent>
        | ImMap<RawDraftContentState>
        | TiptapContent
        | RawDraftContentState
        | string
        | null,
) => {
    if (getDraftBlocks(textContent)) return ElementEditor.DraftJs;
    if (getTiptapContent(textContent)) return ElementEditor.Tiptap;
    return ElementEditor.None;
};

export const getMainEditorType = (element: ImMNElement) => {
    const textContent = getTextContent(element);
    return getEditorType(textContent);
};
