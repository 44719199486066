import { isNumber, isString } from 'lodash';
import { MAX_SHEET_HEIGHT, MAX_SUFFIX } from '../hooks/useModalSheetDragState';

const MAX_DEFAULT_REGEX = /(\d|.)+max$/;

/**
 * Initialise the snap points array.
 * Add 0 as a snap point to dismiss the sheet, and remove any invalid snap points
 * @param snapPoints
 * @param dismissible
 */
export const initialiseSnapPoints = (snapPoints: number[], dismissible: boolean): number[] => {
    const points = snapPoints.filter((snapPoint) => snapPoint <= MAX_SHEET_HEIGHT && snapPoint > 0).sort();

    return dismissible ? [0, ...points] : points;
};

export const getDefaultSnapPointDetails = (
    snapPoint: number | string | undefined,
): { point?: number; suffix?: string } => {
    if (!snapPoint) return {};

    // Check if it has the max suffix
    if (isString(snapPoint) && snapPoint.match(MAX_DEFAULT_REGEX)) {
        return { point: Number(snapPoint.split(MAX_SUFFIX)[0]), suffix: MAX_SUFFIX };
    }

    if (isNumber(snapPoint)) {
        return { point: snapPoint };
    }

    return {};
};

export const setSheetHeight = (sheetRef: React.RefObject<HTMLDivElement>, height: number): void => {
    if (!sheetRef.current) return;

    sheetRef.current.style.height = `${height}px`;
};

export const getSheetHeightFromTopValue = (top: number): number => window.innerHeight - top;
export const getSnapPointSheetHeight = (snapPoint: number): number => snapPoint * window.innerHeight;
export const getSnapPointTopValue = (snapPoint: number): number =>
    window.innerHeight - getSnapPointSheetHeight(snapPoint);

export const getNewActiveSnapPoint = (
    velocity: number,
    currentY: number,
    sheetTouchOffset: number,
    snapPointsState: number[],
): number => {
    // If user is dragging over a certain velocity, adjust the intended position to be further in that direction
    // Multiplier is just an arbitrary number based on what feels good. Velocity in px/ms
    const velocityAdjustment = 150 * velocity;
    const intendedSheetTop = currentY - sheetTouchOffset - velocityAdjustment;
    const snapDistances = snapPointsState.map((snapPoint: number) =>
        Math.abs(intendedSheetTop - getSnapPointTopValue(snapPoint)),
    );
    const smallestDistance = Math.min(...snapDistances);

    // go to the closest snap point
    const index = snapDistances.indexOf(smallestDistance);
    return snapPointsState[index];
};
