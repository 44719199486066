// Lib
import { useCallback } from 'react';
import { Editor } from '@tiptap/react';

// Actions
import { setActiveTiptapEditor } from './store/tiptapEditorStoreActions';

// Hooks
import useElementTiptapEditor, {
    UseTiptapEditorProps,
    UseTiptapEditorReturn,
} from '../../../common/tiptap/useElementTiptapEditor';
import { useTiptapEditorDispatch } from './store/tiptapEditorStoreHooks';

/**
 * Dispatches an editor update to the Tiptap editor redux store.
 */
const useDispatchTiptapActiveEditorStoreUpdate = () => {
    const dispatch = useTiptapEditorDispatch();

    return useCallback(
        (editor: Editor | null) => {
            dispatch(setActiveTiptapEditor(editor));
        },
        [dispatch],
    );
};

/**
 * Configures the Tiptap editor for use in the client.
 */
export const useClientElementTiptapEditor = (
    elementTiptapEditorArgs: Omit<UseTiptapEditorProps, 'updateActiveEditorStore'>,
): UseTiptapEditorReturn => {
    const updateActiveEditorStore = useDispatchTiptapActiveEditorStoreUpdate();
    return useElementTiptapEditor({
        ...elementTiptapEditorArgs,
        updateActiveEditorStore,
    });
};

export default useClientElementTiptapEditor;
