// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Immutable from 'immutable';

// Utils
import { getEditorState } from '../../../../../../components/editor/store/reducers/editorStoreSelector';
import editorStoreConnect from '../../../../../../components/editor/store/components/editorStoreConnect';
import editorGetCurrentBlock from '../../../../../../components/editor/customRichUtils/editorState/editorGetCurrentBlock';
import { editorStyleChange } from '../../../../../../components/editor/store/editorActions';
import editorGetCurrentInlineStyle from '../../../../../../components/editor/customRichUtils/editorState/editorGetCurrentInlineStyle';
import { getAllCellsBetween } from '../../../../../../element/table/utils/tableCellSelectionUtils';
import {
    getElementId,
    getTableContentCellTextStyle,
} from '../../../../../../../common/elements/utils/elementPropertyUtils';
import { setCustomColorThunk } from './textColorActions';
import { getCurrentCellSelections } from '../../../../../../element/table/tableSelector';
import { getElementDefaultColors } from '../../editorTools/textStylePopup/textStylePopupUtils';

// Components
import DraftTextColorButton from './DraftTextColorButton';
import DraftTextStyleEntry from './DraftTextStyleEntry';
import TextStylePopupContent from '../../editorTools/textStylePopup/TextStylePopupContent';

// Constants
import { STYLE_COMMANDS } from '../../../../../../components/editor/richText/richTextConstants';
import {
    TEXT_BACKGROUND_PRESETS,
    TEXT_COLOR_PRESETS,
} from '../../../../../../components/editor/plugins/textColor/textColorConstants';
import { TABLE_GRID_EDITOR_KEY } from '../../../../../../../common/table/tableConstants';

// Styles
import HotTableManager from '../../../../../../element/table/manager/HotTableManager';

const TextStyleRichTextToolPopupContentComponent = ({ showBlockStyleTools, ...rest }) => {
    const { defaultTextColor, defaultBackgroundColor } = getElementDefaultColors(rest.element) || {};

    return (
        <TextStylePopupContent
            showBlockStyleTools={showBlockStyleTools}
            blockStyleComponents={
                <>
                    <DraftTextStyleEntry
                        {...rest}
                        name="Large heading"
                        shortcut={['command', 'shift', '1']}
                        entryBlockType={STYLE_COMMANDS.LARGE_HEADING}
                    />
                    <DraftTextStyleEntry
                        {...rest}
                        name="Normal heading"
                        shortcut={['command', 'shift', '2']}
                        entryBlockType={STYLE_COMMANDS.HEADING}
                    />
                    <DraftTextStyleEntry
                        {...rest}
                        name="Normal text"
                        shortcut={['command', 'shift', '0']}
                        otherBlockTypes={[
                            STYLE_COMMANDS.ORDERED_LIST,
                            STYLE_COMMANDS.LIST,
                            STYLE_COMMANDS.CHECKLIST,
                            null,
                        ]}
                        entryBlockType={STYLE_COMMANDS.UNSTYLED}
                    />
                    <DraftTextStyleEntry
                        {...rest}
                        name="Small text"
                        shortcut={['command', 'shift', '9']}
                        entryBlockType={STYLE_COMMANDS.SMALL_TEXT}
                    />
                    <DraftTextStyleEntry
                        {...rest}
                        name="Code block"
                        shortcut={['command', '>']}
                        entryBlockType={STYLE_COMMANDS.CODE_BLOCK}
                    />
                    <DraftTextStyleEntry
                        {...rest}
                        name='"Quote block"'
                        shortcut={['command', '"']}
                        entryBlockType={STYLE_COMMANDS.BLOCKQUOTE}
                    />
                </>
            }
            textColorComponents={[
                <DraftTextColorButton
                    key="default"
                    {...rest}
                    preset={null}
                    customTextColor={defaultTextColor}
                    customBackgroundColor={defaultBackgroundColor}
                />,
                ...Object.values(TEXT_COLOR_PRESETS).map((preset) => (
                    <DraftTextColorButton
                        key={preset}
                        {...rest}
                        preset={preset}
                        customBackgroundColor={defaultBackgroundColor}
                    />
                )),
            ]}
            textHighlightComponents={Object.values(TEXT_BACKGROUND_PRESETS).map((preset) => (
                <DraftTextColorButton key={preset} {...rest} preset={preset} />
            ))}
        />
    );
};

TextStyleRichTextToolPopupContentComponent.propTypes = {
    blockType: PropTypes.string,
    onTextStyleClick: PropTypes.func,
    showBlockStyleTools: PropTypes.bool,
};

/*************************************************
 * Create enhancer for when an editor is focused
 */

const mapEditorStateToProps = (state) => {
    const editorState = getEditorState(state);

    let blockType = editorState ? editorGetCurrentBlock(editorState).getType() : null;

    return {
        blockType,
        currentStyle: editorGetCurrentInlineStyle(editorState),
    };
};

const mapEditorDispatchToProps = (dispatch) => ({
    onTextStyleClick: (styleCommand) => dispatch(editorStyleChange({ styleCommand })),
    onTextColorClick: (colorStyle) =>
        dispatch(
            setCustomColorThunk({
                colorStyle,
                addToUndoStack: true,
                showPseudoSelection: false,
            }),
        ),
});

const enhanceWithEditorState = editorStoreConnect(mapEditorStateToProps, mapEditorDispatchToProps);
const EditorTextStyleRichTextToolPopupContentComponent = enhanceWithEditorState(
    TextStyleRichTextToolPopupContentComponent,
);

/*****************************************************
 * Create enhancer for when an table grid is focused
 */

const mapTableGridStateToProps = (state, ownProps) => {
    const { element } = ownProps;

    const currentCellSelections = getCurrentCellSelections(state, ownProps);

    const selectedCellsCoords = getAllCellsBetween(currentCellSelections);
    const firstSelectedCellCoords = selectedCellsCoords.shift();

    if (!firstSelectedCellCoords) return Immutable.OrderedSet();

    const firstSelectedCellTextStyles = Immutable.OrderedSet(
        getTableContentCellTextStyle(firstSelectedCellCoords)(element),
    );

    const currentStyle = selectedCellsCoords.reduce(
        (acc, cellCoords) => acc.intersect(getTableContentCellTextStyle(cellCoords)(element)),
        firstSelectedCellTextStyles,
    );

    return { currentStyle };
};

const mapTableGridDispatchToProps = (dispatch, ownProps) => ({
    onTextColorClick: (colorStyle) => {
        const { element } = ownProps;
        const hotTableInstance = HotTableManager.getHotTableComponent(getElementId(element));

        hotTableInstance.tableOperationsRef.current.applyTextStyle(colorStyle);
    },
});

const enhanceWithTableGridState = connect(mapTableGridStateToProps, mapTableGridDispatchToProps);
const TableGridTextStyleRichTextToolPopupContentComponent = enhanceWithTableGridState(
    TextStyleRichTextToolPopupContentComponent,
);

/***************************************************************
 * Choose which enhancer to use depending on current editor key
 */

const TextStyleRichTextToolPopupContent = (props) => {
    const { editorKey, item } = props;
    const showBlockStyleTools = item?.toolSettings?.showBlockStyleTools || props.showBlockStyleTools;

    if (editorKey === TABLE_GRID_EDITOR_KEY) {
        return (
            <TableGridTextStyleRichTextToolPopupContentComponent {...props} showBlockStyleTools={showBlockStyleTools} />
        );
    }

    return <EditorTextStyleRichTextToolPopupContentComponent {...props} showBlockStyleTools={showBlockStyleTools} />;
};

TextStyleRichTextToolPopupContent.propTypes = {
    showBlockStyleTools: PropTypes.bool,
    editorKey: PropTypes.string,
    item: PropTypes.object,
};

export default TextStyleRichTextToolPopupContent;
