import { EditorState } from '@tiptap/pm/state';
import { TiptapContent } from '../../tiptapTypes';
import { ElementType } from '../../../elements/elementTypes';
import { UniversalCardConverterCommand } from './universalCardTypes';

import { isValidColorFormat } from '../../../colors/colorSpaceUtil';
import { parseColorObject } from '../../../colors/colorObjectUtil';
import { getColorName } from '../../../colors/colorNameUtil';
import { updateCurrentlyEditingElementType } from '../../../../client/element/card/universalCardActions';
import { generateJSON } from '@tiptap/core';

import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';

export const SIMPLE_CONTENT_EXTENSIONS = [Document, Paragraph, Text];

const convertSimpleStringToTiptapContent = (textContent = ''): TiptapContent =>
    generateJSON(textContent, SIMPLE_CONTENT_EXTENSIONS) as TiptapContent;

/**
 * Determines if the current editor text is only colour text.
 */
export const isOnlyColorText = (state: EditorState): boolean => {
    if (state.doc.content.childCount !== 1) return false;
    return !!isValidColorFormat(state.doc.textContent);
};

export const attemptColorSwatchConversion: UniversalCardConverterCommand = ({ state }) => {
    if (!isOnlyColorText(state)) return null;

    const color = parseColorObject(state.doc.textContent);
    if (!color) return null;

    const colorName = getColorName(color) || '';
    const tiptapCaption = convertSimpleStringToTiptapContent(colorName);

    // TODO Ensure the editor component doesn't force an update event now that we're
    //  changing the component type

    return updateCurrentlyEditingElementType({
        elementType: ElementType.COLOR_SWATCH_TYPE,
        data: {
            color,
            caption: tiptapCaption,
        },
    });
};
