import { EditorState } from '@tiptap/pm/state';
import { ElementType } from '../../../elements/elementTypes';
import { UniversalCardConverterCommand } from './universalCardTypes';
import { updateCurrentlyEditingElementType } from '../../../../client/element/card/universalCardActions';

const TASK_LIST_REGEX = /^\[[ |x]?\]\s*$/;

/**
 * Determines if the entire editor is only task list text
 */
export const isOnlyTaskListText = (state: EditorState): boolean => {
    if (state.doc.content.childCount !== 1) return false;
    return TASK_LIST_REGEX.test(state.doc.textContent);
};

export const attemptTaskListConversion: UniversalCardConverterCommand = ({ state }) => {
    if (!isOnlyTaskListText(state)) return null;

    const isComplete = state.doc.textContent.includes('x');

    return updateCurrentlyEditingElementType({
        elementType: ElementType.TASK_TYPE,
        data: { content: { isComplete } },
    });
};
