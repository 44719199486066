import { getSnapPointSheetHeight, initialiseSnapPoints, setSheetHeight } from '../utils/snapPointUtils';
import { RefObject, useEffect, useState } from 'react';

type ModalSheetSnapPointState = {
    snapPointsState: number[];
    goToSnapPoint: (snapPoint: number) => void;
    addSnapPoint: (snapPoint: number) => void;
    updateActiveSnapPoint: (snapPoint: number, goToPoint: boolean) => void;
};

/**
 * Take care of snapPoint and activeSnapPoint state, and expose functions to update it
 * @param snapPoints
 * @param sheetRef
 * @param activeSnapPoint
 * @param setActiveSnapPoint
 * @param dismissible
 */
const useModalSheetSnapPointState = (
    snapPoints: number[],
    sheetRef: RefObject<HTMLDivElement>,
    activeSnapPoint: number,
    setActiveSnapPoint: (snapPoint: number) => void,
    dismissible: boolean,
): ModalSheetSnapPointState => {
    const [snapPointsState, setSnapPointsState] = useState<number[]>(initialiseSnapPoints(snapPoints, dismissible));

    const goToSnapPoint = (snapPoint: number) => {
        setSheetHeight(sheetRef, getSnapPointSheetHeight(snapPoint));
    };

    const addSnapPoint = (snapPoint: number) => {
        if (snapPointsState?.includes(snapPoint)) return;

        setSnapPointsState([...snapPointsState, snapPoint].sort());
    };

    /**
     * Update the active snap point and go to it
     * @param snapPoint
     * @param goToPoint
     */
    const updateActiveSnapPoint = (snapPoint: number, goToPoint: boolean) => {
        setActiveSnapPoint(snapPoint);
        if (goToPoint) goToSnapPoint(snapPoint);
    };

    useEffect(() => {
        goToSnapPoint(activeSnapPoint);
    }, [activeSnapPoint]);

    return {
        snapPointsState,
        goToSnapPoint,
        addSnapPoint,
        updateActiveSnapPoint,
    };
};

export default useModalSheetSnapPointState;
