import React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { createSelector, createStructuredSelector } from 'reselect';

// Utils
import { editedOrSelectedElementsFromPropsSelector } from '../selector/toolbarSelector';

// Types
import { ToolbarItemConfig } from '../toolbarTypes';
import { ImMNElement } from '../../../../common/elements/elementModelTypes';
import { ImMNUser } from '../../../../common/users/userModelTypes';

const EmptyTool = () => <div />;

const activeElementSelector = createSelector(
    editedOrSelectedElementsFromPropsSelector,
    (elements): ImMNElement => elements?.first(),
);

const mapStateToProps = createStructuredSelector({
    // enrich the selectedElements passed as captured state from the tool list with the current state of those elements
    // this is necessary to ensure that the toolbar tools are always in sync with the current state of the elements
    selectedElements: editedOrSelectedElementsFromPropsSelector,
    element: activeElementSelector,
});

const connector = connect(mapStateToProps);

export type ToolbarItemsListRequiredProps = {
    selectedElementIds: Immutable.List<string>;
    currentUser: ImMNUser;
    currentUserId: string;
    currentBoardId: string;
    gridSize: number;
    gridSizeName: string;
    permissions: number;
    editorKey?: string;
    hasEditorSelection: boolean;
};

export type ToolbarItemsListProps = ConnectedProps<typeof connector> &
    ToolbarItemsListRequiredProps & {
        items: ToolbarItemConfig[];
        toolListId: string;
        getComponent: (item: ToolbarItemConfig) => React.ComponentType<any> | undefined;
    };

/**
 * Renders a list of toolbar tools/items, based on the provided configuration.
 */
const ToolbarItemsList = ({ items, getComponent, ...rest }: ToolbarItemsListProps): React.ReactElement => {
    return (
        <>
            {items.map((item) => {
                const Component = getComponent(item) || EmptyTool;

                return (
                    <Component {...rest} {...item} id={undefined} key={item.uid || item.id} item={item}>
                        {/* ensure any passed "children" prop is nulled out */}
                        {undefined}
                    </Component>
                );
            })}
        </>
    );
};

const memoisedToolbarItemsList = React.memo(
    ToolbarItemsList,
    (prevProps, nextProps) =>
        prevProps.toolListId === nextProps.toolListId &&
        prevProps.currentUser === nextProps.currentUser &&
        prevProps.selectedElementIds === nextProps.selectedElementIds &&
        prevProps.element === nextProps.element &&
        prevProps.editorKey === nextProps.editorKey &&
        prevProps.hasEditorSelection === nextProps.hasEditorSelection &&
        prevProps.currentBoardId === nextProps.currentBoardId &&
        prevProps.gridSize === nextProps.gridSize,
);

export default connector(memoisedToolbarItemsList);
