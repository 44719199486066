import DefaultHeading from '@tiptap/extension-heading';

/**
 * Overrides the extension to use the Milanote Cmd+Shift+<level> keyboard shortcuts and restricts
 * the heading levels to 1 and 2 by default.
 */
export const Heading = DefaultHeading.extend({
    addKeyboardShortcuts() {
        return this.options.levels.reduce(
            (items, level) => ({
                ...items,
                ...{
                    [`Mod-Shift-${level}`]: () => this.editor.commands.toggleHeading({ level }),
                },
            }),
            {},
        );
    },
}).configure({
    levels: [1, 2],
});
