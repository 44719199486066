// Lib
import React, { ReactElement } from 'react';

// Utils
import { isListTypeClosestActive } from '../../../../../../common/tiptap/extensions/list/tiptapListUtils';

// Hooks
import {
    useActiveTiptapEditorCallback,
    useTiptapActiveEditorSelector,
} from '../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Components
import RichTextTool from '../richContentTools/RichTextTool';

// Types
import { ToolbarToolProps } from '../../../config/toolDefinitions/toolbarToolTypes';
import { TiptapNodeType } from '../../../../../../common/tiptap/tiptapTypes';

type TiptapToggleListToolProps = ToolbarToolProps & {
    listType: TiptapNodeType;
};

const TiptapToggleListTool = (props: TiptapToggleListToolProps): ReactElement => {
    const { listType } = props;

    const isActive = useTiptapActiveEditorSelector(isListTypeClosestActive(listType));

    const toggleList = useActiveTiptapEditorCallback(
        (activeEditor, event: PointerEvent) => {
            if (!activeEditor) return;

            event.preventDefault();

            activeEditor.chain().focus().toggleList(listType, TiptapNodeType.listItem).run();
        },
        [listType],
    );

    return (
        <RichTextTool
            isActive={isActive}
            onPointerDown={toggleList}
            iconName={props.iconName || 'toolbar-numlist'}
            {...props}
        />
    );
};

export default TiptapToggleListTool;
