import DefaultParagraph from '@tiptap/extension-paragraph';

export const Paragraph = DefaultParagraph.extend({
    addKeyboardShortcuts() {
        return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Mod-Shift-0': () => this.editor.commands.setParagraph(),
        };
    },
});
