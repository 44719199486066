// Lib
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { negate } from 'lodash/fp';

// Utils
import { getElementStyle } from '../../utils/grid/gridUtils';
import { length } from '../../../common/utils/immutableHelper';
import { canBeAColumnChild } from '../../../common/columns/columnUtils';
import {
    getBackgroundColor,
    getColor,
    getElementId,
    getIsCollapsed,
    getWidth,
} from '../../../common/elements/utils/elementPropertyUtils';
import { elementClassNames, getDomElementId, getElementCardWidthGU } from '../utils/elementUtil';
import { isColumn } from '../../../common/elements/utils/elementTypeUtils';
import { getShowQuickLineTool } from '../quickLine/quickLineUtil';
import { getMainEditorKey } from '../utils/elementEditorUtils';
import { getBackgroundColorClasses, getColorCssValue } from '../utils/elementColorStyleUtils';

// List drag and drop
import makeCanDropFn from '../dnd/canDrop/makeCanDropFn';
import preventSnappedDragDrop from '../dnd/canDrop/preventSnappedDragDrop';
import preventAnyElementPredicate from '../dnd/canDrop/preventAnyElementPredicate';
import preventAllElementPredicate from '../dnd/canDrop/preventAllElementPredicate';

// Components
import EditableTitle from '../../components/editableTitle/EditableTitle';
import ColorBar from '../../components/colors/ColorBar';
import ElementUserTag from '../../user/userActivity/ElementUserTag';
import ContainerElementChildCount from '../containerElement/ContainerElementChildCount';
import ColumnCollapsedActivityIndicator from './ColumnCollapsedActivityIndicator';
import ColumnBottomDropArea from './ColumnBottomDropArea';
import QuickLineCreationTool from '../quickLine/QuickLineCreationTool';
import AttachedCommentsContainer from '../attachedComment/AttachedCommentsContainer';
import ElementResizeHandle from '../resizing/ElementResizeHandle';
import ColumnCollapseButton from './ColumnCollapseButton';
import ColumnOrchestratedList from './ColumnOrchestratedList';

// Constants
import { COLUMN_COLORS } from '../../../common/colors/colorConstants';
import { ELEMENT_DEFAULT_WIDTH } from '../../../common/elements/elementConstants';

// Style
import './Column.scss';

const columnCanDrop = makeCanDropFn([
    preventSnappedDragDrop,
    preventAnyElementPredicate([isColumn]),
    preventAllElementPredicate([negate(canBeAColumnChild)]),
]);

const Column = (props) => {
    const {
        elementId,
        currentBoardId,
        element,
        gridSize,
        isSelected,
        inList,
        elementEvents,
        childElementIds,
        hasChildrenMatchingElementFilter,
        isSingleSelected,
        isEditable,
        isPresentational,
        isPresentationModeEnabled,
        permissions,
        isLocked,
        dispatchUpdateElement,
        getContextZoomScale,
        getContextZoomTranslationPx,
    } = props;

    const colorName = getColor(element);

    const backgroundColor = getBackgroundColor(element);
    const colorCss = getColorCssValue(backgroundColor, COLUMN_COLORS);

    const isCollapsed = getIsCollapsed(element) && !hasChildrenMatchingElementFilter;
    const columnClassNames = classNames('Column drag-handle contrast-shadow', {
        'column-collapsed': isCollapsed,
        'background-color-target': isSelected,
        'background-color-contrast-target': isSelected,
        ...getBackgroundColorClasses(backgroundColor, true, COLUMN_COLORS),
    });
    const classes = elementClassNames(columnClassNames, props);

    const widthGU = getElementCardWidthGU(props);

    // We don't want to resize child elements during column resizing,
    // otherwise it will be very janky & jittery
    const savedCardWidth = getWidth(element) || ELEMENT_DEFAULT_WIDTH;
    const listElementProps = useMemo(() => ({ parentWidthGU: savedCardWidth }), [savedCardWidth]);

    const editorKey = getMainEditorKey(props);

    const elementStyle = {
        ...getElementStyle(widthGU, gridSize, inList),
        '--ws-element-background-primary': colorCss,
    };

    return (
        <div
            id={getDomElementId(getElementId(element))}
            data-allow-double-click-creates
            className={classes}
            style={elementStyle}
            {...elementEvents}
        >
            <ColumnCollapseButton
                show
                isEditable={isEditable}
                element={element}
                elementId={elementId}
                dispatchUpdateElement={dispatchUpdateElement}
            />
            <ColorBar colorName={colorName} isSelected={isSelected} />
            <QuickLineCreationTool
                show={getShowQuickLineTool(props)}
                elementId={elementId}
                element={element}
                currentBoardId={currentBoardId}
                gridSize={gridSize}
                getContextZoomScale={getContextZoomScale}
                getContextZoomTranslationPx={getContextZoomTranslationPx}
            />
            <ElementUserTag {...props} />

            <div className="title-container">
                <EditableTitle
                    {...props}
                    elementId={getElementId(element)}
                    initialValue="New Column"
                    selectFirst
                    selectAllOnEdit
                    isSelected={isSingleSelected}
                    editorKey={editorKey}
                />
            </div>

            <ContainerElementChildCount {...props} show={!isPresentationModeEnabled}>
                {isCollapsed && <ColumnCollapsedActivityIndicator childElementIds={childElementIds} />}
            </ContainerElementChildCount>

            <AttachedCommentsContainer
                elementId={elementId}
                element={element}
                gridSize={gridSize}
                isPresentational={isPresentational}
                currentBoardId={currentBoardId}
                permissions={permissions}
            />
            {!isCollapsed && (
                <ColumnOrchestratedList {...props} listElementProps={listElementProps} listCanDropFn={columnCanDrop} />
            )}
            <ColumnBottomDropArea
                {...props}
                isCollapsed={isCollapsed}
                listId={getElementId(element)}
                listCanDropFn={columnCanDrop}
                listSize={length(childElementIds)}
            />
            <ElementResizeHandle {...props} elementId={elementId} showHandle={!isLocked && isEditable && !inList} />
        </div>
    );
};

Column.propTypes = {
    elementId: PropTypes.string,
    currentBoardId: PropTypes.string,
    isPresentational: PropTypes.bool,
    element: PropTypes.object.isRequired,
    gridSize: PropTypes.number,
    isSelected: PropTypes.bool,
    isSingleSelected: PropTypes.bool,
    isEditable: PropTypes.bool,
    isLocked: PropTypes.bool,
    inList: PropTypes.string,
    className: PropTypes.string,
    elementEvents: PropTypes.object,
    childElementIds: PropTypes.array,
    hasChildrenMatchingElementFilter: PropTypes.bool,
    permissions: PropTypes.number,
    tempSize: PropTypes.object,
    dispatchUpdateElement: PropTypes.func,
    getContextZoomScale: PropTypes.func,
    getContextZoomTranslationPx: PropTypes.func,
    isPresentationModeEnabled: PropTypes.bool,
    setIsBatchedRenderInProgress: PropTypes.func,
};

export default Column;
