import { DraftConverter, mapBlockToNode } from '@tiptap/draftjs-to-tiptap';
import { RawDraftContentBlock, RawDraftContentState } from 'draft-js';
import { flow } from 'lodash';
import { isEmpty } from 'lodash/fp';

import { ElementEditor } from '../../../elements/elementEditorConstants';
import { ImMNElement, MNElement, MNElementContent } from '../../../elements/elementModelTypes';
import { TextAlignment } from '../../../table/CellTypeConstants';
import { asObject } from '../../../utils/immutableHelper';
import { ALIGNMENT_SUPPORTED_NODE_TYPES, TiptapContent, TiptapContentNode, TiptapNodeType } from '../../tiptapTypes';
import convertChecklistBlockToTiptapNode from './convertChecklistBlockToTiptapNode';
import convertDraftInlineStyleToTiptapMark from './convertDraftInlineStyleToTiptapMark';
import { convertEntityToMark } from './convertEntityToMark';
import { DraftConverterSpec, MapBlockToNodeFn } from './draftToTiptapConverterTypes';
import { promoteMarksToNodes } from './promoteMarksToNodes';
import { recursivelyStripEmptyMarks } from './recursivelyStripEmptyMarks';
import { rewriteTree } from './rewriteTree';

const convertSmallTextBlockToNode: MapBlockToNodeFn = (context) => {
    const { doc, converter, block, entityMap } = context;

    return converter.addChild(
        converter.createNode('smallText'),
        converter.splitTextByEntityRangesAndInlineStyleRanges({
            doc,
            block,
            entityMap,
        }),
    );
};

const addLeftAlignToParagraphs = (node: TiptapContentNode): TiptapContentNode => {
    if (node.type !== 'paragraph') return node;
    return {
        ...node,

        attrs: {
            textAlign: TextAlignment.LEFT,
            ...node.attrs,
        },
    };
};

const addStartToOrderedLists = (node: TiptapContentNode): TiptapContentNode => {
    if (node.type !== 'orderedList') return node;
    return {
        ...node,

        attrs: {
            start: 1,
            ...node.attrs,
        },
    };
};

const convertBlockToNode: MapBlockToNodeFn = (context) => {
    // add any blocks that need special handling here
    switch (context.block.type) {
        case 'small-text':
            return convertSmallTextBlockToNode(context);
        case 'checklist':
            return convertChecklistBlockToTiptapNode(context);

        // for lists, mapBlockToNode will convert the entire list to a single node
        // so we need to do custom logic in a postprocessing step
        case 'unordered-list-item':
        case 'ordered-list-item': {
            const node = mapBlockToNode(context);
            return rewriteTree(node, flow(addStartToOrderedLists, addLeftAlignToParagraphs));
        }

        default:
            // use tiptap's default converter
            return mapBlockToNode(context);
    }
};

const getAlignment = (node: TiptapContentNode, block: RawDraftContentBlock): TextAlignment | null => {
    // TODO-TIPTAP - see if we could instead use the Tiptap schema to determine supported types
    if (!ALIGNMENT_SUPPORTED_NODE_TYPES.includes((node.type as TiptapNodeType) || '')) return null;

    if (block.data?.['text-align-center']) return TextAlignment.CENTER;
    if (block.data?.['text-align-right']) return TextAlignment.RIGHT;
    return TextAlignment.LEFT;
};

const addGlobalNodeAttrs = (node: TiptapContentNode, block: RawDraftContentBlock) => {
    let newAttrs: Record<string, any> = {
        textAlign: getAlignment(node, block),
    };

    newAttrs = Object.fromEntries(Object.entries(newAttrs).filter(([_, v]) => v !== null));

    if (!isEmpty(newAttrs)) {
        return {
            ...node.attrs,
            ...newAttrs,
        };
    }

    return node.attrs;
};

const converter = new DraftConverter({
    mapBlockToNode(context) {
        const node = convertBlockToNode(context);
        if (!node) return node;

        const attrs = addGlobalNodeAttrs(node, context.block);
        if (attrs) {
            node.attrs = attrs;
        }

        return node;
    },
    mapEntityToMark(context) {
        // The mapEntityToMark function exported by draftjs-to-tiptap is hardcoded to only handle
        // links, and strips the data from all other mark types, making it impossible to convert
        // them. This does roughly the same thing, but keeps the data for non-link entities.
        const entity = context.entityMap[context.range.key];
        const mark = convertEntityToMark(entity);

        return converter.addMark(mark);
    },
    mapInlineStyleToMark(context) {
        return convertDraftInlineStyleToTiptapMark(context);
    },
} as DraftConverterSpec);

const postprocessContent = flow(promoteMarksToNodes, recursivelyStripEmptyMarks);

/**
 * Converts an element's textContent (exported so tests don't have to set up a whole MNElement)
 */
export function convertContentToTiptap(textContent: RawDraftContentState): TiptapContentNode {
    const baseConversion = converter.convert(textContent);

    return postprocessContent(baseConversion);
}

/**
 * Function for converting a element containing DraftJS content
 */
export function convertToTiptap(element: ImMNElement): MNElementContent {
    const { content } = asObject<MNElement>(element);

    return {
        editor: ElementEditor.Tiptap,
        textContent: convertContentToTiptap(content.textContent as RawDraftContentState) as TiptapContent,
    };
}
