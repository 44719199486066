// Lib
import React, { ReactElement } from 'react';

// Hooks
import {
    ActiveTiptapEditorSelector,
    useActiveTiptapEditorCallback,
    useTiptapActiveEditorSelector,
} from '../../../../../../components/tiptapEditor/store/tiptapEditorStoreHooks';

// Components
import TextColorButton from './TextColorButton';

const isTextColorButtonActive =
    (color: string): ActiveTiptapEditorSelector =>
    (editor) =>
        editor?.isActive('highlight', { color });

interface TiptapTextHighlightButtonProps {
    color: string;
    customTextColor?: string | null;
    customBackgroundColor?: string | null;
}

const TiptapTextHighlightButton = (props: TiptapTextHighlightButtonProps): ReactElement => {
    const { color, customTextColor, customBackgroundColor } = props;

    const active = useTiptapActiveEditorSelector(isTextColorButtonActive(color));

    const onTextColorClick = useActiveTiptapEditorCallback((activeEditor) => {
        activeEditor?.chain().focus().setHighlight({ color }).run();
    });

    return (
        <TextColorButton
            active={active || false}
            color={color}
            className={`bg-${color}`}
            onSelectTextColor={onTextColorClick}
            customTextColor={customTextColor}
            customBackgroundColor={customBackgroundColor}
        />
    );
};

export default TiptapTextHighlightButton;
