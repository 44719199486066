// Types
import { ActionCreator } from 'redux';
import {
    EditorInstanceObject,
    SetTiptapActiveEditorAction,
    TiptapEditorStoreActions,
    TiptapEditorStoreState,
} from './tiptapEditorStoreTypes';
import { Editor } from '@tiptap/react';
import { ThunkAction } from 'redux-thunk';

// Selectors
import { getActiveTiptapEditor } from './tiptapEditorSelectors';

export const setActiveTiptapEditor: ActionCreator<SetTiptapActiveEditorAction, [Editor | null]> = (activeEditor) => ({
    type: 'SET_ACTIVE_TIPTAP_EDITOR',
    activeEditor,
});

export const getActiveTiptapEditorThunk =
    (): ThunkAction<EditorInstanceObject | null, TiptapEditorStoreState, {}, TiptapEditorStoreActions> =>
    (_, getTiptapEditorState) =>
        getActiveTiptapEditor(getTiptapEditorState());
