/**
 * These are operations that are similar to those found in Tiptap's commands,
 * however most of those are performed on the editor's selection, whereas
 * these are performed on specific ranges.
 */
import { NodeRange } from '@tiptap/pm/model';
import { EditorState } from '@tiptap/pm/state';
import { liftTarget } from '@tiptap/pm/transform';
import { TiptapDispatch } from '../tiptapTypes';

/**
 * This function is similar to the clearNodes command in Tiptap, however it
 * operates on a specific range rather than the editor's selection.
 *
 * It also ensures that the "mapping" only occurs for operations that are performed
 * within this function, rather than the entire transaction.
 */
export const clearNodesInRange = (state: EditorState, dispatch: TiptapDispatch, range: NodeRange) => {
    if (!dispatch) return true;

    const { $from, $to } = range;

    const initialStepsCount = state.tr.steps.length;

    state.tr.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
        if (node.type.isText) return false;

        const { doc } = state.tr;

        // Only map positions that have changed while performing this operation
        const mapping = state.tr.mapping.slice(initialStepsCount);

        const $mappedFrom = doc.resolve(mapping.map(pos));
        const $mappedTo = doc.resolve(mapping.map(pos + node.nodeSize));

        const nodeBlockRange = $mappedFrom.blockRange($mappedTo);

        if (!nodeBlockRange) return false;

        const targetLiftDepth = liftTarget(nodeBlockRange);

        if (node.type.isTextblock) {
            const { defaultType } = $mappedFrom.parent.contentMatchAt($mappedFrom.index());
            state.tr.setNodeMarkup(nodeBlockRange.start, defaultType);
        }

        if (targetLiftDepth || targetLiftDepth === 0) {
            state.tr.lift(nodeBlockRange, targetLiftDepth);
        }
    });

    return true;
};
