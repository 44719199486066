import DefaultCodeBlock from '@tiptap/extension-code-block';

export const CodeBlock = DefaultCodeBlock.extend({
    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),
            /* eslint-disable @typescript-eslint/naming-convention */
            'Mod-.': () => this.editor.commands.toggleCodeBlock(),
            'Mod->': () => this.editor.commands.toggleCodeBlock(),
        };
    },
});
