// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { last } from 'lodash/fp';

// Utils
import { getTooltipPosition, getArrowPosition, getShouldDisplayArrow } from './tooltipUtil';
import { getArea } from '../../../common/maths/geometry/rect/rectDimensionUtils';
import { getFloatingPanelMountPoint } from './floatingPanelUtils';
import { reverseTranslate } from '../../../common/maths/geometry/rect';

// Actions
import { hideTooltip } from './tooltipActions';

// Components
import FloatingPanelArrow from './FloatingPanelArrow';
import FloatingPanelErrorBoundary from './FloatingPanelErrorBoundary';

// Constants
import { HIDDEN_POSITION } from './tooltipConstants';

// Styles
import './FloatingPanel.scss';

const mapDispatchToProps = (dispatch) => ({
    dispatchHideTooltip: () => dispatch(hideTooltip()),
});

@connect(null, mapDispatchToProps)
class FloatingPanel extends React.Component {
    constructor(props) {
        super(props);

        this.isRendered = false;

        this.hideTooltipDelay = null;
        this.showTooltipDelay = null;
        this.state = {
            hide: false,
        };

        this.tip = React.createRef();
        this.arrow = React.createRef();
    }

    componentWillMount() {
        document.addEventListener('click', this.hideTooltip, true);

        const { delay = 0, duration, dispatchHideTooltip } = this.props;

        if (delay) {
            this.setState({ hide: true });
            this.showTooltipDelay = setTimeout(() => this.showTooltip(), delay);
        }

        if (duration) {
            const totalDur = duration + delay;
            this.hideTooltipDelay = setTimeout(() => dispatchHideTooltip(), totalDur);
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.getTooltipPosition();
    }

    componentDidUpdate(prevProps) {
        // if tooltip is not set too constantly update, but the selector has changed, update position
        if (!this.props.pollPosition && prevProps.selector !== this.props.selector) {
            this.getTooltipPosition();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        clearTimeout(this.hideTooltipDelay);
        clearTimeout(this.showTooltipDelay);
        document.removeEventListener('click', this.hideTooltip, true);
    }

    getTooltipPosition = () => {
        if (!this.mounted) return;

        // If the tooltip wasn't rendered, then we can't position it correctly, so force a render and retry
        if (!this.isRendered) {
            const portalMountPoint = getFloatingPanelMountPoint();

            requestAnimationFrame(this.getTooltipPosition);

            // If there's nowhere to mount to, just try again in another frame
            if (!portalMountPoint) return;

            // We now have the mount point, so force a render.
            // The tooltip position function is already scheduled above, so the next time this function runs,
            // it should successfully reach the code below and position the tooltip correctly.
            return this.forceUpdate();
        }

        // If polling position, schedule another check of the position
        if (this.props.pollPosition) requestAnimationFrame(this.getTooltipPosition);

        const { selector, insets = { top: 0, left: 0, right: 0, bottom: 0 } } = this.props;
        const targetElement = last(document.querySelectorAll(selector));

        const { targetRect = targetElement?.getBoundingClientRect(), relativeParentSelector } = this.props;

        if (!targetElement && !targetRect) {
            console.warn(`FloatingPanel couldn't find an element with selector ${selector}`);
        }

        // If `relativeParentSelector` prop provided, calculate the tooltip position based on the relative parent
        // instead of the whole window
        const relativeParentRect =
            relativeParentSelector && document.querySelector(relativeParentSelector)?.getBoundingClientRect();
        const newTargetRect = relativeParentRect ? reverseTranslate(relativeParentRect, targetRect) : targetRect;

        // If the target takes up no space on the screen, then don't show it
        if (!newTargetRect || getArea(newTargetRect) === 0) {
            this.tip.current.style.top = `${HIDDEN_POSITION.top}px`;
            this.tip.current.style.left = `${HIDDEN_POSITION.left}px`;
            return;
        }

        const tipRect = this.tip.current.getBoundingClientRect();
        const arrowRect = this.arrow.current && this.arrow.current.getBoundingClientRect();

        const position = getTooltipPosition(newTargetRect, tipRect, this.props);

        this.tip.current.style.top = `${position.top}px`;
        this.tip.current.style.left = `${position.left}px`;

        const tipAvailableHeight = window.innerHeight - insets.top - insets.bottom;
        const tipAvailableWidth = window.innerWidth - insets.left - insets.right;
        this.tip.current.style.setProperty('--popup-available-height', `${tipAvailableHeight}px`);
        this.tip.current.style.setProperty('--popup-available-width', `${tipAvailableWidth}px`);

        if (!getShouldDisplayArrow(this.props) && this.arrow.current) {
            this.arrow.current.style.visiblity = 'hidden';
            return;
        }

        const arrowPosition = getArrowPosition(newTargetRect, position, arrowRect, this.props);

        this.arrow.current.style.top = `${arrowPosition.top}px`;
        this.arrow.current.style.left = `${arrowPosition.left}px`;
        this.arrow.current.style.transform = `rotate(${arrowPosition.rotate}deg)`;

        if (this.props.arrowStyle && this.props.arrowStyle.transform) {
            this.arrow.current.style.transform = `${this.arrow.current.style.transform} ${this.props.arrowStyle.transform}`;
        }

        this.arrow.current.style.visiblity = 'visible';
        this.tip.current.classList.add(arrowPosition.side);
    };

    showTooltip = () => {
        this.setState({ hide: false });
    };

    hideTooltip = () => {
        this.props.dispatchHideTooltip();
    };

    render() {
        const {
            children,
            className,
            style,
            arrowColor = 'var(--arrow-color)',
            arrowStrokeColor = 'var(--arrow-stroke-color)',
            arrowWidth = 9,
            arrowHeight = 7,
            arrowStrokeWidth = 1,
            responsive,
            onMouseDown,
            onClick,
            mountPointSelector,
        } = this.props;

        const tipStyle = {
            ...HIDDEN_POSITION,
            ...style,
        };

        const arrowStyle = {
            ...this.props.arrowStyle,
            position: 'absolute',
            width: arrowWidth,
            height: arrowHeight,
        };

        const floatingPanel = (
            <div
                ref={this.tip}
                className={classNames('FloatingPanel', className, { hide: this.state.hide, responsive })}
                onMouseDown={onMouseDown}
                onClick={onClick}
                style={tipStyle}
            >
                <FloatingPanelArrow
                    ref={this.arrow}
                    width={arrowWidth}
                    height={arrowHeight}
                    color={arrowColor}
                    strokeColor={arrowStrokeColor}
                    strokeWidth={arrowStrokeWidth}
                    style={arrowStyle}
                />
                <div className="content">
                    <FloatingPanelErrorBoundary {...this.props}>{children}</FloatingPanelErrorBoundary>
                </div>
            </div>
        );

        const portalMountPoint = getFloatingPanelMountPoint(mountPointSelector);

        if (!portalMountPoint) return null;

        this.isRendered = true;

        return createPortal(floatingPanel, portalMountPoint);
    }
}

FloatingPanel.propTypes = {
    responsive: PropTypes.bool,
    selector: PropTypes.string,
    mountPointSelector: PropTypes.string,
    relativeParentSelector: PropTypes.string,
    delay: PropTypes.number,
    offset: PropTypes.number,
    duration: PropTypes.number,
    className: PropTypes.string,
    arrowColor: PropTypes.string,
    arrowStrokeColor: PropTypes.string,
    arrowWidth: PropTypes.number,
    arrowHeight: PropTypes.number,
    arrowStrokeWidth: PropTypes.number,
    arrowStyle: PropTypes.object,
    targetRect: PropTypes.object,
    style: PropTypes.object,
    insets: PropTypes.object,

    position: PropTypes.string,
    alignment: PropTypes.string,

    pollPosition: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    dispatchHideTooltip: PropTypes.func,
    onMouseDown: PropTypes.func,
    onClick: PropTypes.func,
};

export default FloatingPanel;
