import React, { RefObject, useState } from 'react';
import {
    getNewActiveSnapPoint,
    getSheetHeightFromTopValue,
    getSnapPointTopValue,
    setSheetHeight,
} from '../utils/snapPointUtils';
import { MAX_SHEET_HEIGHT } from './useModalSheetDragState';

const DRAGGING_CLASS = 'dragging';

export type ModalSheetHandlers = {
    handleSheetContentScroll: () => void;
    handleSheetTouchStart: (event: React.TouchEvent) => void;
    handleSheetTouchMove: (event: React.TouchEvent) => void;
    handleSheetTouchEnd: () => void;
    handleSheetTouchCancel: () => void;
};

/**
 * Handle the touch events on the sheet.
 * @param activeSnapPoint
 * @param sheetRef
 * @param sheetContentRef
 * @param sheetInitialised
 * @param snapPointsState
 * @param goToSnapPoint
 * @param updateActiveSnapPoint
 * @param closeSheet
 */
const useModalSheetHandlers = (
    activeSnapPoint: number,
    sheetRef: RefObject<HTMLDivElement>,
    sheetContentRef: RefObject<HTMLDivElement>,
    sheetInitialised: boolean,
    snapPointsState: number[],
    goToSnapPoint: (snapPoint: number) => void,
    updateActiveSnapPoint: (snapPoint: number, goToPoint: boolean) => void,
    closeSheet: () => void,
): ModalSheetHandlers => {
    const [sheetTouchOffset, setSheetTouchOffset] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    const [touchInScrollableArea, setTouchInScrollableArea] = useState(false);
    const [currentY, setCurrentY] = useState(0);
    const [velocity, setVelocity] = useState(0);
    const [prevMoveTimestamp, setPrevMoveTimestamp] = useState(0);
    const [scrollTimeoutInProgress, setScrollTimeoutInProgress] = useState(false);

    const highestSnapPoint = Math.max(...snapPointsState);
    const highestSnapPosition = getSnapPointTopValue(highestSnapPoint);

    const resetToDragStart = () => {
        setIsDragging(false); // reset in case the scroll timeout already ended and it was set to true

        // Reset to original height
        goToSnapPoint(activeSnapPoint);
    };

    /**
     * Handle the scroll event on the scrollable content.
     * If the user is scrolling the content, set isScrolling to true so sheet can't be dragged.
     * Reset height and flags in case the sheet has already started dragging
     */
    const handleSheetContentScroll = () => {
        if (isScrolling || !touchInScrollableArea) return;

        setIsScrolling(true);

        // In case the scroll started after the timeout, reset flags and sheet height
        if (isDragging) resetToDragStart();
    };

    /**
     * Handle the touch start event on the sheet content.
     * Get all the starting measurements and set up the timeout to allow
     * the scroll handler to run before we start the drag
     * @param event
     */
    const handleSheetTouchStart = (event: React.TouchEvent) => {
        if (!sheetRef.current) return;

        // Prevent overscroll
        document.querySelector('html')?.classList.add('prevent-overscroll');

        // Reset flags
        setIsScrolling(false);
        setIsDragging(false);

        // Get the starting position of the sheet
        const startPosY = event.touches[0].clientY;
        setSheetTouchOffset(startPosY - sheetRef.current.getBoundingClientRect().top);
        setCurrentY(startPosY);
        setPrevMoveTimestamp(Date.now());

        // Set up the timeout to allow the scroll handler to run before we start the drag
        const scrollArea = sheetContentRef.current;
        if (!scrollArea) return;

        const scrollAreaScrollable = scrollArea.scrollHeight > scrollArea.clientHeight;
        setTouchInScrollableArea(event.target === scrollArea || scrollArea.contains(event.target as Node));
        if (!scrollAreaScrollable || !touchInScrollableArea) return;

        setScrollTimeoutInProgress(true);
        setTimeout(() => {
            setScrollTimeoutInProgress(false);
        }, 50);
    };

    /**
     * Try to update the isDragging value.
     * Check for scroll and initialisation conditions before setting dragging to true
     */
    const tryUpdateIsDraggingValue = () => {
        // Once isDragging is set to true, we don't want to set it to false until the touch ends (unless scroll fires later)
        if (isDragging) return;

        // Completed checks for scroll before setting dragging to true
        if (isScrolling || scrollTimeoutInProgress) return;

        // To set the initial content height we need to wait for the transition to finish, so don't allow the height to be changed before that time
        if (!sheetInitialised) return;

        setIsDragging(true);
        sheetRef.current?.classList.add(DRAGGING_CLASS);
    };

    /**
     * Handle the touch move event on the sheet content.
     * Check if drag is accepted and if so adjust the sheet height.
     * @param event
     */
    const handleSheetTouchMove = (event: React.TouchEvent) => {
        // --- Get new position and velocity ---
        const newY = event.touches[0].clientY;
        const timeSinceLastMove = Date.now() - prevMoveTimestamp;
        setVelocity((currentY - newY) / timeSinceLastMove);
        setPrevMoveTimestamp(Date.now());
        setCurrentY(newY);

        // --- Check if move accepted as drag ---
        tryUpdateIsDraggingValue();
        if (!isDragging) return;

        // --- Calculate new position ---
        const newSheetTop = newY - sheetTouchOffset;
        // if the sheet has gone past the top snap point, only use half the distance between finger and highest snap point
        const slowDragAdjustment = newSheetTop < highestSnapPosition ? (highestSnapPosition - newSheetTop) / 2 : 0;
        const intendedSheetTop = newSheetTop + slowDragAdjustment;
        const minTop = 1 - MAX_SHEET_HEIGHT * 100;
        const distanceFromTop = Math.max(intendedSheetTop, minTop);
        setSheetHeight(sheetRef, getSheetHeightFromTopValue(distanceFromTop));
    };

    /**
     * Handle the touch end event on the sheet content.
     * If the sheet is being dragged, go to the new snap point.
     */
    const handleSheetTouchEnd = () => {
        // remove dragging classes
        sheetRef.current?.classList.remove(DRAGGING_CLASS);
        document.querySelector('html')?.classList.remove('prevent-overscroll');

        if (!isDragging) return;

        const newActiveSnapPoint = getNewActiveSnapPoint(velocity, currentY, sheetTouchOffset, snapPointsState);

        // If the new snap point is at the bottom, close the sheet
        if (newActiveSnapPoint === 0) {
            closeSheet();
            return;
        }

        // Use animation frame to ensure transition styles are reapplied before moving
        requestAnimationFrame(() => {
            // Go to new snap point
            updateActiveSnapPoint(newActiveSnapPoint, true);
        });
    };

    /**
     * Handle the touch cancel event on the sheet content.
     * Reset the sheet to the drag start position.
     */
    const handleSheetTouchCancel = () => {
        resetToDragStart();
    };

    return {
        handleSheetContentScroll,
        handleSheetTouchStart,
        handleSheetTouchMove,
        handleSheetTouchEnd,
        handleSheetTouchCancel,
    };
};

export default useModalSheetHandlers;
