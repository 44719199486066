// Lib
import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

// Styles
import './TextStylePopupContent.scss';

interface TextStylePopupContentProps {
    showBlockStyleTools: boolean;
    blockStyleComponents: ReactNode;
    textColorComponents: ReactNode;
    textHighlightComponents: ReactNode;
}

const TextStylePopupContent = (props: TextStylePopupContentProps): ReactElement => {
    const { showBlockStyleTools, blockStyleComponents, textColorComponents, textHighlightComponents } = props;

    return (
        <div className={classNames('TextStylePopupContent', { 'color-only': !showBlockStyleTools })}>
            {showBlockStyleTools && <div className="block-styles">{blockStyleComponents}</div>}

            <div className="text-color-section color">
                <div className="text-color-section-title">Color</div>
                <div className="text-color-row">{textColorComponents}</div>
            </div>

            <div className="text-color-section background">
                <div className="text-color-section-title">Highlight</div>
                <div className="text-color-row">{textHighlightComponents}</div>
            </div>
        </div>
    );
};

export default TextStylePopupContent;
